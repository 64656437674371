import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomValidators } from '@modules/utility/utility';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css']
})
export class DatepickerComponent implements OnInit {

  @Input() formType = 'full';
  @Input() dateDefault: string;
  @Input() dateMin: string;
  @Input() dateMax: string = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().slice(0, 10);

  CustomValidators = new CustomValidators();
  dateSelect: FormControl;

  @Output() output: EventEmitter<Date> = new EventEmitter();

  constructor() {
    const validators = Array();

    if (this.dateMin) {
      validators.push(this.CustomValidators.dateMin(new Date(this.dateMin)));
    }

    if (this.dateMax) {
      validators.push(this.CustomValidators.dateMax(new Date(this.dateMax)));
    }

    this.dateSelect = new FormControl('', validators);
  }

  ngOnInit() {
    if (typeof this.dateDefault === 'undefined' || !this.dateDefault) {
      this.dateDefault = this.isoString();
    }

    this.dateSelect.setValue(new Date(this.dateDefault));
  }

  cleanDate(date: Date) {
    return new Date(date.toISOString().slice(0, 10));
  }

  utcDate(date: Date) {
    const utcDate = new Date(this.dateSelect.value);
    return new Date(utcDate.setTime(utcDate.getTime() + 2 * 60 * 60 * 1000));
  }

  isoString(date: Date = null) {
    if (!date) {
      return new Date().toISOString().slice(0, 10);
    } else {
      return date.toISOString().slice(0, 10);
    }
  }

  emit() {
    if (this.dateSelect.value && !this.dateSelect.errors) {
      // Hack to fix summertime issues
      const utcDate = this.utcDate(this.dateSelect.value);

      this.output.emit(this.cleanDate(utcDate));
    }
  }

}
