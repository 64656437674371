import { Component, OnInit, Input } from '@angular/core';

import { JvsorderService } from '@services/jvsorder.service';
import { Order } from '@classes/order';

import { Cart } from '@classes/cart';

@Component({
  selector: 'app-cart-done',
  templateUrl: './cart-done.component.html',
  styleUrls: ['./cart-done.component.css']
})
export class CartDoneComponent implements OnInit {

  @Input() cart: Cart;
  @Input() result: {'status': boolean, 'data': Order};

  constructor( private jvsorder: JvsorderService ) { }

  ngOnInit() {
    // this.jvsorder.getOrders(this.result.data.OrderNr).subscribe(res => console.log(res));
    // this.jvsorder.getOrder(this.result.data.OrderNr).subscribe(res => console.log(res));
  }

}
