import { Injectable } from '@angular/core';
import { JwtBody, JwtHead } from '@classes/jwt';

@Injectable()
export class JwtService {
  public jwtId = 'jwt_token';
  public token: string;
  public header: JwtHead;
  public body: JwtBody;
  public signature: string;
  public isValid = false;

  constructor() { }

  public init(): void {
    this.load();
    this.validate();
  }

  private decode() {
    let jwtArray: any = [];
    jwtArray = this.token.split('.');

    this.signature = jwtArray[2];
    this.header = JSON.parse(this.base64_decode(jwtArray[0]));
    this.body = JSON.parse(this.base64_decode(jwtArray[1]));
    // console.log(this.token);
    // console.log(this.header);
    // console.log(this.body);
  }

  private base64_decode(base64: string) {
    return atob(base64);
  }

  private validate() {
    if (this.body !== undefined) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  private load() {
    this.token = localStorage.getItem(this.jwtId);

    if (!this.token) {
      this.token = sessionStorage.getItem(this.jwtId);
    }

    if (this.token) {
      this.decode();
    }
  }

  public save(token: string, useSession: boolean = true) {
    if (useSession) {
      sessionStorage.setItem(this.jwtId, token);
    } else {
      localStorage.setItem(this.jwtId, token);
    }
    this.load();
  }

  public clear(): void {
    sessionStorage.removeItem(this.jwtId);
    localStorage.removeItem(this.jwtId);
  }
}
