import { Injectable } from '@angular/core';

import { DataService } from '@services/data.service';
import { Customer } from '@classes/customer';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JvscustomerService {

  public endpointCustomers = '/jeeves/customers/';
  public endpointCustomer = '/jeeves/customers/{FtgNr}';

  constructor(private api: DataService) { }

  getCustomer(FtgNr: string) {
    const endpoint = this.endpointCustomer.replace('{FtgNr}', FtgNr);

    return this.api.getData(endpoint, []) as Observable<Customer>;
  }

  getCustomers() {
    return this.api.getData(this.endpointCustomers, []) as Observable<Customer>;
  }
}
