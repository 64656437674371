import { Component, OnInit } from '@angular/core';
import { JvsarticlefilterService } from '@services/jvsarticlefilter.service';
import { ProdTyp } from '@classes/filter';
@Component({
  selector: 'app-articles-filters-active',
  templateUrl: './articles-filters-active.component.html',
  styleUrls: ['./articles-filters-active.component.css']
})
export class ArticlesFiltersActiveComponent implements OnInit {

  activeFilters: ProdTyp[];
  filters: ProdTyp[];
  filterCount = 0;


  constructor(private jvsArtFilter: JvsarticlefilterService) { }

  ngOnInit() {
    this.jvsArtFilter.filterOutput$.subscribe(filters => {
      this.filters = filters;
    });

    this.jvsArtFilter.isActive$.subscribe(res => this.filterCount = res);
  }

  setActive(prodtypes: ProdTyp[]) {
    this.activeFilters = Array();

    if (prodtypes === null) {
      return;
    }

    prodtypes.forEach(prodtype => {
      if (prodtype) {
        prodtype.children.forEach(filter => {
          if (filter.State) {
            this.activeFilters.push(filter);
          }
        });
      }
    });
  }

  disable(group, option) {
    this.jvsArtFilter.setFilter(group, option);
  }

  reset() {
    this.jvsArtFilter.resetFilterForm();
  }


}
