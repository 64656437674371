<ng-container *ngIf="invoice">
    <mat-card fxLayout="row wrap" fxLayoutAlign="center flex-start">
  
      <div class="header" fxFlex="100%">
        <h3>INVOICE {{invoice.FaktNr}}</h3>
      </div>
  
      <div class="section" fxFlex="20%" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always">
              <input matInput placeholder="Invoice nb" [value]="invoice.FaktNr" readonly>
          </mat-form-field>
        </div>
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always">
              <input matInput placeholder="Order nb" [value]="invoice.OrderNr" readonly>
          </mat-form-field>
  
        </div>
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always">
              <input matInput placeholder="Date" [value]="invoice.FaktDat | date:'shortDate'" readonly>
          </mat-form-field>
        </div>
        
        <div class="section-row" fxFlex="100%" fxLayout="column" fxLayoutAlign="center flex-start">
          <button mat-raised-button color="primary" (click)="openDialog(invoice.OrderNr)" fxHide.lt-md>Track delivery</button>
          <button mat-raised-button color="primary" routerLink="/order/track/{{invoice.OrderNr}}" fxHide fxShow.lt-md>Track delivery</button>
        </div>
      </div>
  
      <div class="section" fxFlex="20%" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always">
              <input matInput placeholder="Type" [value]="invoice.OrdTypBeskr" readonly>
          </mat-form-field>
        </div>
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always">
              <input matInput placeholder="Delivery Terms" [value]="invoice.LevVillkBeskr" readonly>
          </mat-form-field>
        </div>
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always">
              <input matInput placeholder="Payment Terms" [value]="invoice.BetVillBeskr" readonly>
          </mat-form-field>
        </div>
      </div>
  
      <div class="section" fxFlex="20%" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always">
              <input matInput placeholder="Currency" [value]="invoice.ValKod" readonly>
          </mat-form-field>
        </div>
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always">
              <input matInput placeholder="VAT" [value]="invoice.VbFaktMoms | currency:invoice.ValKod:'symbol-narrow'" readonly>
          </mat-form-field>
        </div>
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always">
              <input matInput placeholder="Amount" [value]="invoice.VbFaktTotUMoms | currency:invoice.ValKod:'symbol-narrow'" readonly>
          </mat-form-field>
        </div>
      </div>

      <div class="section" fxFlex="20%" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always">
              <input matInput placeholder="Status" [value]="invoice.FaktStBeskr" readonly>
          </mat-form-field>
        </div>
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always">
              <input matInput placeholder="Balance" [value]="invoice.VbFaktRest | currency:invoice.ValKod:'symbol-narrow'" readonly>
          </mat-form-field>
        </div>
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always" [class.overdue]="invoice.overdue == 1">
              <input matInput placeholder="Due date" [value]="(invoice.FaktFfDat | date:'shortDate')" readonly *ngIf="invoice.overdue != 1">
              <input matInput placeholder="Due date" [value]="(invoice.FaktFfDat | date:'shortDate') + ' ' + '('+ invoice.AntDgrSedanFfDat +')'" readonly *ngIf="invoice.overdue == 1">
          </mat-form-field>
        </div>
      </div>

      <div class="section" fxFlex="20%" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
        <div class="section-row" fxFlex="100%">
          <mat-form-field class="full-width" floatLabel="always">
              <textarea matInput rows="2" placeholder="Order Comment" [value]="invoice.Edit" readonly></textarea>
          </mat-form-field>
        </div>
      </div>
  
    </mat-card>
  </ng-container>