import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { CustomValidators } from '@modules/utility/utility';

@Component({
  selector: 'app-order-delivery',
  templateUrl: './order-delivery.component.html',
  styleUrls: ['./order-delivery.component.css']
})
export class OrderDeliveryComponent implements OnInit {
  @Input() date$: BehaviorSubject<Date> = null;

  dateMin: Date;
  dateMax: Date;

  CustomValidators = new CustomValidators();
  datePicker: Date;
  touchUi = false;

  dateSelect: FormControl;

  constructor() {
    this.dateMin = new Date(this.isoString());
    this.dateMax = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

    this.dateSelect = new FormControl('', [
      this.CustomValidators.dateMin(this.dateMin),
      this.CustomValidators.dateMax(this.dateMax)
    ]);
  }

  ngOnInit() {
    this.date$.subscribe(date => {
      if (date) {
        this.datePicker = date;
        this.dateSelect.setValue(date);
      } else {
        this.datePicker = null;
        this.dateSelect.setValue(this.dateMin);
      }
    });
  }

  isoString(date: Date = null) {
    if (date) {
      const result = date.toISOString().slice(0, 10);
      return result;
    }

    return this.isoString(new Date());
  }

  evaluate() {
    if (this.dateSelect.value && !this.dateSelect.errors) {
      this.date$.next(this.dateSelect.value);
    }
  }

  test(touch: boolean) {
    console.log('flex ran', touch);
    this.touchUi = touch;

    return '';
  }
}
