import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { JvscontactService } from '@services/jvscontact.service';
import { Customer, Contact } from '@classes/customer';

@Component({
  selector: 'app-contact-search',
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.css']
})
export class ContactSearchComponent implements OnInit {

  @Input() FtgNr: string;
  @Output() output: EventEmitter<Contact[]> = new EventEmitter<Contact[]>();

  search: any = '';
  results: Contact[];

  constructor(private jvscontact: JvscontactService) { }

  ngOnInit() {
  }

  getData() {

    this.jvscontact.searchContact(this.FtgNr, this.search).subscribe((contacts: Contact[]) => {
        this.results = contacts;
        this.emitResult();
      });
  }

  emitResult() {
    this.output.emit(this.results);
  }

}
