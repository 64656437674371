import { Component, OnInit } from '@angular/core';
import { CartService } from '@services/cart.service';

@Component({
  selector: 'app-cartoperations',
  templateUrl: './cartoperations.component.html',
  styleUrls: ['./cartoperations.component.css']
})
export class CartoperationsComponent implements OnInit {

  enableEmpty = false;

  constructor(public cart: CartService) { }

  ngOnInit() {
    this.cart.cartQty$.subscribe(qty => {
      if (qty > 0) {
        this.enableEmpty = true;
      }
    });
  }
}
