<ng-container *ngIf="formType == 'full'">
    <div style="padding:10px;">
        <mat-form-field>
            <input matInput [formControl]="dateSelect" [matDatepicker]="picker" [min]="dateMin" [max]="dateMax"
                placeholder="Delivery date" (dateChange)="emit()">

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-error *ngIf="dateSelect.getError('dateMin') && dateSelect.value">
            Earliest date is {{isoString(dateMin)}}
        </mat-error>

        <mat-error *ngIf="dateSelect.getError('dateMax') && dateSelect.value">
            Latest date is {{isoString(dateMax)}}
        </mat-error>
    </div>
</ng-container>

<ng-container *ngIf="formType == 'icon'">
    <div fxLayout="row nowrap" fxLayoutAlign="center center">
        <mat-form-field style="width:1px;visibility:hidden;">
            <input matInput [formControl]="dateSelect" [matDatepicker]="picker" [min]="dateMin" [max]="dateMax"
                placeholder="Delivery date" (dateChange)="emit()">

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>

        <button mat-mini-fab color="primary" (click)="picker.open()">
            <i  class="far fa-calendar-alt" aria-hidden="true" matTooltipPosition="below" matTooltip="Delivery date"></i>
        </button>
    </div>
</ng-container>