<mat-tab-group>

  <mat-tab label="Account">
    <ng-template matTabContent>
      <div fxLayout="row wrap" fxLayoutAlign="space-between flex-start" style="padding:10px;">
        
        <div fxFlex="40%" fxFlex.lt-md="100%">
          <app-user-details *ngIf="userDone" [user]="user"></app-user-details>
          <app-contact-list [input]="contact" [customer]="customer" [paginateEnabled]="false" [toolsEnabled]="false"></app-contact-list>
        </div>

        <div fxFlex="58%" fxFlex.lt-md="100%">
          <app-customer-details *ngIf="customerDone"  [customer]="customer"></app-customer-details>
        </div>

      </div>
    </ng-template>
  </mat-tab>

  <mat-tab label="Password">
    <div fxLayout="row wrap" fxLayoutAlign="space-between flex-start" style="padding:10px;">
      
      <div>
        <app-user-psw [user]="user"></app-user-psw>
      </div>
      
      <div></div>

    </div>
  </mat-tab>

</mat-tab-group>