<div class="container">
<table class="matrix-table">
  <thead>
    <tr>
      <th>Color \ Size</th>

      <th *ngFor="let size of matrix.sizes" data-toggle="tooltip" title="{{size.PVMVariant2}} | {{size.AssrtContent}}">
        {{size.PVMVariantDescr2}}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let color of matrix.colors" [class.selected]="colorActive == color.PVMVariant1">
      <td title="{{color.PVMVariant1}}">{{color.PVMVariantDescr}}</td>
      <td *ngFor="let cell of color.sizes">
        <ng-container *ngIf="cell">
          <input 
            type="number" 
            size="3" 
            [(ngModel)]="order[cell.ArtNr]" 
            placeholder="{{cell.ArtDisp}}" 
            style="width:3em" 
            min="0"
            [disabled]="cell.ArtDisp === 0" 
            (click)="setActive(cell)"/>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>

<button mat-raised-button color="primary" (click)="submitToCart()">Add to cart</button>
</div>
