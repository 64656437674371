<div fxLayout="row wrap">
    <div fxFlex="100%" class="row">

        <mat-form-field class="company-nb" floatLabel="always">
            <input matInput placeholder="First name" [(ngModel)]="user.firstname">
        </mat-form-field>

        <mat-form-field class="company-name" floatLabel="always">
            <input matInput placeholder="Surname" [(ngModel)]="user.lastname">
        </mat-form-field>

    </div>

    <div fxFlex="100%" class="row">

    <mat-form-field class="company" floatLabel="always">
        <input matInput placeholder="Username" [(ngModel)]="user.username">
    </mat-form-field>

    </div>

    <div fxFlex="100%" class="row" floatLabel="always">

        <mat-form-field class="company">
            <input matInput placeholder="Email" [(ngModel)]="user.mail">
        </mat-form-field>

    </div>

    <div fxFlex="100%" class="row">

    <mat-checkbox [checked]="user.valid" 
                    (change)="user.valid = $event.checked ? 1 : 0">Valid</mat-checkbox>

    <mat-checkbox [checked]="user.activated" 
                    (change)="user.activated = $event.checked ? 1 : 0">Activated</mat-checkbox>

    <button mat-raised-button color="primary" (click)="saveUser()">Save</button>

    </div>
</div>