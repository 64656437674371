import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CartService } from '@services/cart.service';
import { DataService } from '@services/data.service';

import { Cart } from '@classes/cart';
import { Customer } from '@classes/customer';
import { TermsComponent } from '@components/orders/terms/terms.component';
import { BehaviorSubject } from 'rxjs';



@Component({
  selector: 'app-carthead-table',
  templateUrl: './carthead-table.component.html',
  styleUrls: ['./carthead-table.component.css']
})
export class CartheadTableComponent implements OnInit {

  @Output() output: EventEmitter<boolean> = new EventEmitter(false);
  @Input() customer$: BehaviorSubject<Customer>;

  public customer: Customer;

  public isDone = false;
  public deliverySites: Customer[];

  public view: {customer: Customer, cart: Cart} = {customer: {} as Customer, cart: {} as Cart};
  public forms: {FtgNr: string, mail: string, comment: string, confirm: boolean } = {FtgNr: '', mail: '', comment: '', confirm: false};


  constructor(private api: DataService , private cart: CartService, private dialog: MatDialog) { }

  ngOnInit() {
    this.cart.cart$.subscribe(cart => {
      if (cart && cart.cartid) {
        this.processCart(cart);
      }
    });

    this.customer$.subscribe(customer => {
      if (customer) {
        this.customer = customer;
        this.processCustomer();
      }
    });
  }

  processCart(cart: Cart) {
    this.view.cart = cart;

    if (this.view.cart.meta) {
      this.forms.comment = this.view.cart.meta.comment;
    }
  }

  processCustomer() {
    this.deliverySites = this.customer.LevPlats;
    this.deliverySites.unshift(this.customer);

    this.view.customer = this.customer;
    this.forms.FtgNr = this.view.customer.FtgNr;
    this.forms.mail = this.view.customer.wEmailAddress;

    this.isDone = true;
  }

  processInput(target?: string) {
    const view = this.view;
    const forms = this.forms;

    if (target === 'levplats') {
      this.customer.LevPlats.forEach((LevPlats) => {
        if (LevPlats.FtgNr === forms.FtgNr) {
          view.customer = LevPlats;
          forms.mail = LevPlats.wEmailAddress;
        }
      });
    }

    this.updateCart();

  }

  updateCart() {
    const meta: {comment: string, levplats: string, wEmailAddress: string} = {comment: '', levplats: '', wEmailAddress: ''};

    if (this.forms.comment) {
      meta.comment = this.forms.comment;
    }

    if (this.forms.FtgNr) {
      if (this.forms.FtgNr === this.customer.FtgNr) {
        meta.levplats = '';
      } else {
        meta.levplats = this.forms.FtgNr;
      }
    }

    if (this.forms.mail) {
      meta.wEmailAddress = this.forms.mail;
    }


    this.cart.updateCart({meta});

  }

  confirmTerms() {
    this.output.emit(this.forms.confirm);
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(TermsComponent, {
      height: '90%',
      width: '70%',
    });

    const sub = dialogRef.componentInstance.output.subscribe(res => {
      if (res) {
        this.forms.confirm = true;
        this.confirmTerms();
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

}
