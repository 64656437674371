import { Component } from '@angular/core';
import { User } from '@classes/user';
import { Customer, Contact } from '@classes/customer';
import { AppService } from '@services/app.service';
import { UserService } from '@services/user.service';
import { JwtService } from '@services/jwt.service';
import { JvscustomerService } from '@services/jvscustomer.service';
import { JvscontactService } from '@services/jvscontact.service';

@Component({
  selector: 'app-user-route',
  templateUrl: './user-route.component.html',
  styleUrls: ['./user-route.component.css']
})
export class UserRouteComponent {

  user: User;
  customer: Customer;
  contact: Contact[];

  userDone = false;
  customerDone = false;
  contactDone = false;


  constructor(
    private app: AppService,
    private userService: UserService,
    private jwtService: JwtService,
    private customerService: JvscustomerService,
    private contactService: JvscontactService) {
    this.app.start();
    this.getUser();
   }

  getUser() {

    const jwt = this.jwtService.body;

    this.userService.getUser(jwt.userid).subscribe( res => {
      this.user = res as User;
      this.userDone = true;
      this.getCustomer(this.user.jvscompany);
      this.getContact(this.user.jvscompany, this.user.jvscontact);
    });
  }

  getCustomer(jvscompany: string) {
    this.customerService.getCustomer(jvscompany).subscribe( res => {
      this.customer = res as Customer;
      this.customerDone = true;
    });
  }

  getContact(jvscompany: string, jvscontact: string | number) {
    this.contactService.getContact(jvscompany, jvscontact).subscribe( res => {
      this.contact = Array(res as Contact);
      this.contactDone = true;
      this.app.done();
    });
  }
}
