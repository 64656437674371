<button mat-raised-button color="primary" (click)="exportAsExcel()" style="margin-right:10px;">Save as Excel</button>
<button mat-raised-button color="primary" (click)="addExportList()" style="margin-right:10px;" *ngIf="listAddEnable">Add to export
    list</button>
<button mat-raised-button color="primary" (click)="colEdit = !colEdit">Edit columns</button>

<div class="coledit" fxLayout="column nowrap" *ngIf="colEdit" fxLayoutAlign="flex-start flex-start">
    <h3>Active Columns</h3>
    <mat-chip-list>
        <ng-container *ngFor="let col of tableColumnsDisplay; let i=index">
            <div>
                <span *ngIf="i != 0" class="arrow m-left" (click)="colOrder(tableColumnsDisplay, i, -1)">&#60;</span>
                <mat-chip color="accent" (click)="removeColumn(col)" selected>
                    {{col}}
                </mat-chip>
                <span *ngIf="i != tableColumnsDisplay.length - 1" class="arrow m-right"
                    (click)="colOrder(tableColumnsDisplay, i, 1)">&#62;</span>
            </div>
        </ng-container>
    </mat-chip-list>
    <h3>Avalible Columns</h3>
    <mat-chip-list>
        <mat-chip color="primary" *ngFor="let col of tableColHidden(tableColumnsDisplay)" (click)="appendColumn(col)"
            selected>
            {{col}}
        </mat-chip>
    </mat-chip-list>

    <button mat-raised-button color="primary" (click)="colDisplayDefault()">Reset to default</button>
</div>

<div #TABLE style="width:auto; height:auto">
    <table mat-table [dataSource]="articles" matSort>
        <ng-container matColumnDef="VaruGruppKod">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> VaruGruppKod </th>
            <td mat-cell *matCellDef="let element"> {{element.VaruGruppKod}}</td>
        </ng-container>

        <ng-container matColumnDef="ArtNrEAN">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ArtNrEAN </th>
            <td mat-cell *matCellDef="let element"> {{element.ArtNrEAN}}</td>
        </ng-container>

        <ng-container matColumnDef="ArtNr">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ArtNr </th>
            <td mat-cell *matCellDef="let element"> {{element.ArtNr}}</td>
        </ng-container>

        <ng-container matColumnDef="PVMVariant1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PVMVariant1 </th>
            <td mat-cell *matCellDef="let element"> {{element.PVMVariant1}}</td>
        </ng-container>

        <ng-container matColumnDef="PVMVariant2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PVMVariant2 </th>
            <td mat-cell *matCellDef="let element"> {{element.PVMVariant2}}</td>
        </ng-container>

        <ng-container matColumnDef="ArtBeskr">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ArtBeskr </th>
            <td mat-cell *matCellDef="let element"> {{element.ArtBeskr}}</td>
        </ng-container>

        <ng-container matColumnDef="PVMVariantDescr">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>PVMVariantDescr </th>
            <td mat-cell *matCellDef="let element"> {{element.PVMVariantDescr}}</td>
        </ng-container>

        <ng-container matColumnDef="PVMVariantDescr2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PVMVariantDescr2 </th>
            <td mat-cell *matCellDef="let element"> {{element.PVMVariantDescr2}}</td>
        </ng-container>

        <ng-container matColumnDef="ArtCirkaPris">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ArtCirkaPris </th>
            <td mat-cell *matCellDef="let element"> {{element.ArtCirkaPris}}</td>
        </ng-container>

        <ng-container matColumnDef="AssrtQty">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> AssrtQty </th>
            <td mat-cell *matCellDef="let element"> {{element.AssrtQty}}</td>
        </ng-container>

        <ng-container matColumnDef="AssrtContent">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> AssrtContent </th>
            <td mat-cell *matCellDef="let element"> {{element.AssrtContent}}</td>
        </ng-container>

        <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
            <td mat-cell *matCellDef="let element" [innerHtml]="element.Edit"></td>
        </ng-container>

        <ng-container matColumnDef="PvmMasterItem">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PvmMasterItem </th>
            <td mat-cell *matCellDef="let element"> {{element.PvmMasterItem}}</td>
        </ng-container>

        <ng-container matColumnDef="ArtStatNr">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ArtStatNr </th>
            <td mat-cell *matCellDef="let element"> {{element.ArtStatNr}}</td>
        </ng-container>

        <ng-container matColumnDef="ArtKat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ArtKat </th>
            <td mat-cell *matCellDef="let element"> {{element.ArtKat}}</td>
        </ng-container>

        <ng-container matColumnDef="Extra1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Extra1 </th>
            <td mat-cell *matCellDef="let element"> {{element.Extra1}}</td>
        </ng-container>

        <ng-container matColumnDef="ArtProdKonto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ArtProdKonto </th>
            <td mat-cell *matCellDef="let element"> {{element.ArtProdKonto}}</td>
        </ng-container>

        <ng-container matColumnDef="ArtListPris">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ArtListPris </th>
            <td mat-cell *matCellDef="let element"> {{element.ArtListPris}}</td>
        </ng-container>

        <ng-container matColumnDef="ArtDisp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ArtDisp </th>
            <td mat-cell *matCellDef="let element"> {{element.ArtDisp}}</td>
        </ng-container>

        <ng-container matColumnDef="isDisp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> isDisp </th>
            <td mat-cell *matCellDef="let element"> {{element.isDisp}}</td>
        </ng-container>

        <ng-container matColumnDef="imageUrl">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> imageUrl </th>
            <td mat-cell *matCellDef="let element"> {{env.arImg400}}{{element.imageUrl}}</td>
        </ng-container>

        <ng-container matColumnDef="Pris">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Pris </th>
            <td mat-cell *matCellDef="let element"> {{element.Pris}}</td>
        </ng-container>

        <ng-container matColumnDef="Vb_Pris">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Vb_Pris </th>
            <td mat-cell *matCellDef="let element"> {{element.Vb_Pris}}</td>
        </ng-container>

        <ng-container matColumnDef="Rabatt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Rabatt </th>
            <td mat-cell *matCellDef="let element"> {{element.Rabatt}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumnsDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumnsDisplay;"></tr>
    </table>
</div>
