import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from '@classes/customer';

@Component({
  selector: 'app-contact-search-dialog',
  templateUrl: './contact-search-dialog.component.html',
  styleUrls: ['./contact-search-dialog.component.css']
})
export class ContactSearchDialogComponent implements OnInit {

  @Output() output: EventEmitter<Contact> = new EventEmitter<Contact>();

  public search = false;

  constructor(@Inject(MAT_DIALOG_DATA) public contacts: Contact[]) { }

  ngOnInit() { }

  onSelect(select: Contact) {
    this.output.emit(select);
  }
}
