import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-diag',
  templateUrl: './diag.component.html',
  styleUrls: ['./diag.component.css']
})
export class DiagComponent implements OnInit {

  constructor( ) { }

  ngOnInit() {
  }
}
