import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '@services/user.service';
import { User } from '@classes/user';
import { AuthService } from '@services/auth.service';
import { MenuService } from '@services/menu.service';


@Component({
  selector: 'app-user-mobile',
  templateUrl: './user-mobile.component.html',
  styleUrls: ['./user-mobile.component.css']
})
export class UserMobileComponent implements OnInit {
  @Output() output: EventEmitter<boolean> = new EventEmitter(false);

  ddShow = false;
  activeuser: User;
  isDone = false;

  constructor(private user: UserService, private auth: AuthService, private menu: MenuService, private router: Router) { }

  ngOnInit() {
    this.user.user$.subscribe(user => {
      if (user.userid) {
        this.activeuser = user;
        this.isDone = true;
      }
    });
  }

  logout() {
    this.output.emit(true);
    this.menu.reset();
    this.auth.logout();
  }

  route(route: string) {
    this.output.emit(true);
    this.router.navigate([route]);
  }

}
