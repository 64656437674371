<div class="container">
  
  <div class="inputs">
    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <mat-form-field class="full-width" floatLabel="always">
        <input matInput type="text" placeholder="User name" formControlName="username" required autofocus>
      </mat-form-field>
  
      <mat-form-field class="full-width" floatLabel="always">
        <input matInput type="password" placeholder="Password"  formControlName="password" required>
      </mat-form-field>

      <mat-checkbox formControlName="sessionStorage">Keep me logged in</mat-checkbox>

      <button type="submit" mat-raised-button color="primary" [disabled]="!loginForm.valid">

        <div *ngIf="!isLoading" fxFlex="100%" class="spinner" fxLayout="column nowrap" fxLayoutAlign="center center">
          Login
        </div>

        <div *ngIf="isLoading" fxFlex="100%" class="spinner" fxLayout="column nowrap" fxLayoutAlign="center center">
          <mat-progress-spinner
            mode="indeterminate"
            color="primary"
            diameter="33">
          </mat-progress-spinner>
        </div>

      </button>
    </form>
  </div>
</div>