<!-- <div class="container" fxLayout="row wrap" fxLayoutAlign="center stretch" (swipeleft)="scroll(1)" (swiperight)="scroll(0)"></div> -->
<div class="container" fxLayout="row wrap" fxLayoutAlign="center stretch">

  <div class="controls left" fxLayout="column nowrap" fxLayoutAlign="center center">
    <i class="visibility fas fa-angle-left fa-6x" (click)="scroll(0)"></i>
  </div>

  <div class="controls right" fxLayout="column nowrap" fxLayoutAlign="center center">
    <i class="visibility fas fa-angle-right fa-6x" (click)="scroll(1)"></i>
  </div>

  <ng-container *ngFor="let article of view.articles; let i=index">
    <app-article-card-std
      *ngIf="i >= view.start && i < view.start+view.offset"
      
      fxFlex.xs="44%" fxFlex.sm="46%" fxFlex.md="22%" fxFlex.gt-md="23%"
      [product]="article"
      [settings]="settings">     
    </app-article-card-std>
  </ng-container>
</div>