import { NgModule } from '@angular/core';
import { GlobalModule } from '@modules/global/global.module';
import { GlobalNgmaterialModule } from '@modules/global/global-ngmaterial.module';
import { RouterModule } from '@angular/router';

import { ArticleMatrixXsComponent } from '@components/article/article-matrix-xs/article-matrix-xs.component';
import { ArticlesListCardsComponent } from '@components/articles/articles-list-cards/articles-list-cards.component';
import { ArticleMatrixComponent } from '@components/article/article-matrix/article-matrix.component';
import { ArticleImgComponent } from '@components/article/article-img/article-img.component';
import { ArticleDataComponent } from '@components/article/article-data/article-data.component';
import { ArticleImgListComponent } from '@components/article/article-img-list/article-img-list.component';
import { ArticleCardStdComponent } from '@components/article/article-card-std/article-card-std.component';
import { ArticlesListBannerComponent } from '@components/articles/articles-list-banner/articles-list-banner.component';

@NgModule({
  declarations: [
    ArticleMatrixXsComponent,
    ArticlesListCardsComponent,
    ArticleMatrixComponent,
    ArticleImgComponent,
    ArticleDataComponent,
    ArticleImgListComponent,
    ArticleCardStdComponent,
    ArticlesListBannerComponent
  ],
  imports: [
    GlobalNgmaterialModule,
    GlobalModule,
    RouterModule
  ],
  exports: [
    GlobalModule,
    GlobalNgmaterialModule,
    RouterModule,
    ArticleMatrixXsComponent,
    ArticlesListCardsComponent,
    ArticleMatrixComponent,
    ArticleImgComponent,
    ArticleDataComponent,
    ArticleImgListComponent,
    ArticleCardStdComponent,
    ArticlesListBannerComponent,
  ]
})
export class GlobalArticleModule { }
