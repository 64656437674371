import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DataService } from '@services/data.service';
import { Order } from '@classes/order';
import { DefaultDialogComponent } from '@components/dialog/default-dialog/default-dialog.component';

@Component({
  selector: 'app-orderhead',
  templateUrl: './orderhead.component.html',
  styleUrls: ['./orderhead.component.css']
})
export class OrderheadComponent implements OnInit {
  @Input() ordernb: number;

  public order: Order;
  public isDone = false;

  constructor(private api: DataService, private dialog: MatDialog) { }

  ngOnInit() {
    if (this.ordernb) {
      this.loadData(this.ordernb);
    }
  }

  loadData(ordernb: number) {
    const endpoint: string = '/jeeves/orders/' + ordernb;
    const headers: any = [];

    this.api.getData(endpoint, headers).subscribe(data => {
      this.order = data[0] as Order;
      this.processResponse();
    });
  }

  processResponse() {
    this.isDone = true;
  }

  openDialog(ordernr: string): void {

    const dialogRef = this.dialog.open(DefaultDialogComponent, {
      height: '85%',
      width: '85%',
      data: {component: 'pacsofttrack', data: ordernr}
    });
  }

}
