import { Component, OnInit } from '@angular/core';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-settings-ddicon',
  templateUrl: './settings-ddicon.component.html',
  styleUrls: ['./settings-ddicon.component.css']
})
export class SettingsDdiconComponent implements OnInit {

  ddShow = false;

  constructor() { }

  ngOnInit() { }

}
