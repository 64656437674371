import { Component, OnInit } from '@angular/core';
import { MessageService } from '@services/message.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-login-recover',
  templateUrl: './login-recover.component.html',
  styleUrls: ['./login-recover.component.css']
})
export class LoginRecoverComponent implements OnInit {

  public submitted = false;
  public isLoading = false;
  public username: string;



  constructor(private userService: UserService, private message: MessageService) { }

  ngOnInit() {
  }


  recover() {
    this.isLoading = true;
    this.userService.recoverPassword(this.username).subscribe(
      (data) => this.success(data),
      (error) => this.error(error)
    );
  }

  success(data: any) {
    this.submitted = true;
    this.isLoading = false;
  }

  error(data: any) {
    this.isLoading = false;

    if (data.status === 401) {
      this.message.send({
        type: 'snackbar'
        , source: 'Password Recovery'
        , message: data.message
        , duration: 4000
      });
    } else {
      this.message.send({
        type: 'snackbar'
        , source: 'Password Recovery'
        , message: data.message
        , duration: 4000
      });
    }

  }

}
