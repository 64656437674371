<mat-card fxLayout="row wrap">
  <div class="row">
    <h3>{{customer.ftgnamn}}</h3>
  </div>

  <div class="row">
    <h4>ERP</h4>
  </div>

  <div class="row">
    <mat-form-field class="small">
      <input matInput placeholder="Customer no." value="{{customer.FtgNr}}" readonly>
    </mat-form-field>

    <mat-form-field class="large">
      <input matInput placeholder="Contact" value="{{customer.SaljareNamn}}" readonly>
    </mat-form-field>
  </div>

  <div class="row section">
    <h4>Delivery address</h4>
  </div>

  <div class="row">
    <mat-form-field class="full">
      <input matInput placeholder="Address" value="{{customer.FtgPostadr5}}" readonly>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="small">
      <input matInput placeholder="Postal code" value="{{customer.FtgLevPostNr}}" readonly>
    </mat-form-field>

    <mat-form-field class="small">
      <input matInput placeholder="City" value="{{customer.FtgPostLevAdr3}}" readonly>
    </mat-form-field>
  </div>
  
  <div class="row section">
    <h4>Document address</h4>
  </div>
  
  <div class="row">
    <mat-form-field class="full">
      <input matInput placeholder="Address" value="{{customer.ftgpostadr2}}" readonly>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="small">
      <input matInput placeholder="Postal code" value="{{customer.ftgpostnr}}" readonly>
    </mat-form-field>

    <mat-form-field class="small">
      <input matInput placeholder="City" value="{{customer.ftgpostadr3}}" readonly>
    </mat-form-field>
  </div>
</mat-card>
