
<mat-tab-group>

    <mat-tab label="Invoice">
        
    <div class="container">
        <app-invoice-head [invoicenb]="invoicenb"></app-invoice-head>
    </div>

    <div class="container">
        <app-invoice-rows [invoicenb]="invoicenb"></app-invoice-rows>
    </div>

    </mat-tab>

</mat-tab-group>

