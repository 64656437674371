import { Component, ViewChild, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { JvscontactService } from '@services/jvscontact.service';
import { Contact, ContactFlat, Customer } from '@classes/customer';


@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.css']
})
export class ContactListComponent implements OnChanges {

  @Input() input: Contact[];
  @Input() customer: Customer;
  @Input() paginateEnabled = true;
  @Input() toolsEnabled = true;
  @Output() output: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  contacts: ContactFlat[];
  editMode: boolean[] = [];
  contactEdited: ContactFlat = {} as ContactFlat;

  public dataSource: MatTableDataSource<ContactFlat>;
  public displayedColumns: string[] = ['FtgKontaktNr', 'FtgPerson', 'ComBeskr', 'ComNr', 'new'];

  constructor(private jvsContact: JvscontactService) {
  }

  ngOnChanges() {
    if (this.input !== undefined) {
      this.contacts = this.jvsContact.flatList(this.input);
      this.initTable(this.contacts);
    }
  }

  initTable(contacts: ContactFlat[]) {

    if (!this.toolsEnabled) {
      this.displayedColumns.pop();
    }

    this.dataSource = new MatTableDataSource<ContactFlat>(contacts);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  toggleEditMode(i: number) {
    if (this.editMode[i]) {
      this.editMode[i] = false;

      this.initTable(this.jvsContact.flatList(this.input));

    } else {
      this.editMode[i] = true;
    }
  }

  saveUpdates(i: number, ii: number = null) {
    let contacts: Contact[];

    if (ii === null) {
      const unflat = [];
      unflat.push(this.contacts[i]);

      contacts = this.jvsContact.unflatList(unflat);
    } else {
      contacts = Array({... this.input[i]});
      delete contacts[0].ComData;
      contacts[0].ComData = Array(this.input[i].ComData[ii]);
    }

    contacts.forEach((contact: Contact) => {
      this.jvsContact.updateContact(this.customer.FtgNr, contact.FtgKontaktNr, contact).subscribe(res => {

        this.editMode[i] = false;
        this.output.emit(true);
      });
    });
  }

}
