import { BehaviorSubject } from 'rxjs';

export interface State {
    index: number | string;
    value: any;
}


export interface AppView {
    name: string;
    value: any;
    isCookie?: boolean;
    expires?: string;
}

export class App {

    sessionStorage = 'AppViews';
    view$: BehaviorSubject<{[key: string]: AppView}> = new BehaviorSubject({});
    view: {[key: string]: AppView};

    constructor() {
        const localStorage = this.getLocalStorage();

        if (localStorage) {
            this.view$.next(localStorage);
        }

        this.view$.subscribe(view => this.view = view);
    }

    hasProp(obj: object, prop: string): boolean {
        if (typeof obj[prop] !== 'undefined') {
            return true;
        } else {
            return false;
        }
    }


    getValue(param: string, defaultReturn: any = true): any {
        if (this.hasProp(this.view, param)) {
            return this.view[param];
        } else {
            return {
                name: param,
                value: defaultReturn
            };
        }
    }

    setValue(param: string, paramValue: any, save = false) {
        this.view[param] = {name: param, value: paramValue};

        this.view$.next(this.view);

        if (save) {
            this.updateLocalStorage();
        }
    }

    updateLocalStorage() {
        const data = JSON.stringify(this.view);

        if (data) {
            localStorage.setItem(this.sessionStorage, data);
        } else {
            console.warn('AppView localstorage fail');
        }
    }

    getLocalStorage() {
        return JSON.parse(localStorage.getItem(this.sessionStorage));
    }
}

