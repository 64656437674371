import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { environment } from '@env/environment';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  @Output() output: EventEmitter<boolean> = new EventEmitter<boolean>();

  public env = environment;
  isOpenAuto = false;
  showSearch = false;
  results: any[] = [];
  searchTerm: FormControl = new FormControl();
  searching = false;

  constructor(private api: DataService, private router: Router) {
    this.searchTerm.valueChanges.pipe(debounceTime(400))
      .subscribe(data => {
        if (data && data.length > 2) {
          this.search(data);
        }
      });
  }

  ngOnInit() {
  }

  search(search: any) {
    const endpoint: string = '/jeeves/article/search/' + encodeURI(search);
    const headers: any = [];

    this.searching = true;
    this.api.getData(endpoint, headers).subscribe(
      (data) => {
        if (!search || !data) {
          this.results = ['0 results..'];
        } else {
          this.results = data as any;
        }

        this.searching = false;
      },
      (error) => {
         this.searching = false;
        }
     );
  }

  route(artnr: string) {
    this.searchTerm.setValue('');
    this.output.emit(true);
    this.router.navigate(['/articles', artnr]);
  }

  reset() {
    this.results = [];
    this.searchTerm.reset();
  }

}
