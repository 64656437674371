import { Component, OnInit, Input } from '@angular/core';
import { Article } from '@classes/article';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-article-data',
  templateUrl: './article-data.component.html',
  styleUrls: ['./article-data.component.css']
})
export class ArticleDataComponent implements OnInit {
  @Input() article: Article;

  constructor(private app: AppService) { }

  viewPriceRetail = true;
  viewPriceCustomer = true;
  isCampaign = false;

  ngOnInit() {
    this.app.view.view$.subscribe(view => {
      this.viewPriceRetail = this.app.view.getValue('viewPriceRetail', true).value;
      this.viewPriceCustomer = this.app.view.getValue('viewPriceCustomer', true).value;
    });

    if (this.article.Pris < this.article.Vb_Pris && this.article.ArtCirkaPrisPrl < this.article.ArtCirkaPris) {
      this.isCampaign = true;
    }
  }

}
