<div class="container">
<div fxLayout="row" fxLayoutGap="5px" style="margin-bottom:15px;">
    <button mat-raised-button color="primary" (click)="customerDialog(null, true)"> Jeeves Customer</button>
    <ng-container *ngIf="filterCustomer">
      <mat-chip-list>
        <mat-chip (click)="filterCustomer = null">{{filterCustomer.FtgNr}} / {{filterCustomer.ftgnamn}}</mat-chip>
      </mat-chip-list>
    </ng-container>
</div>

<div *ngIf="usersDone">
  <mat-accordion>
      <mat-expansion-panel *ngFor="let user of filterUsers()" (opened)="loadUser(user)">

        <mat-expansion-panel-header [class.invalid]="!user.valid || !user.activated">
            {{user.firstname | uppercase }} {{user.lastname | uppercase }}
        </mat-expansion-panel-header>
      
        <ng-template matExpansionPanelContent>
          <mat-tab-group>
            <mat-tab label="User">

              <div class="container" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
                <div fxFlex="45%" fxFlex.lt-md="100%" class="section" fxLayout="row wrap" fxLayout="flex-start stretch">
    
                  <app-admin-user-data [user]="user" (output)="saveUser($event)"></app-admin-user-data>
    
                </div>
                <div fxFlex="45%" fxFlex.lt-md="100%" class="section" fxLayout="row wrap" fxLayout="flex-start stretch">
                    <app-admin-user-erp 
                      [user]="userSelected"
                      [customer]="customerSelected"
                      [contact]="contactSelected"
                      (outputUser)="saveUser($event)"
                      (outputCustomerSelect)="customerDialog(userSelected)"
                      (outputContactSelect)="contactDialog(userSelected)"></app-admin-user-erp>
                </div>
              </div>

            </mat-tab>
            <mat-tab label="API Key">
              <ng-template matTabContent>
                <app-admin-apikey [user]="user"></app-admin-apikey>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
          
        </ng-template>
        

    </mat-expansion-panel>
  </mat-accordion>
</div>
</div>
