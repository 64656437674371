import { Component, OnInit, Input } from '@angular/core';
import { Article } from '@classes/article';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'app-article-img-list',
  templateUrl: './article-img-list.component.html',
  styleUrls: ['./article-img-list.component.css']
})
export class ArticleImgListComponent implements OnInit {
  @Input() articles: Article[];
  @Input() state: BehaviorSubject<Article>;

  public env = environment;
  public active: Article = null;


  constructor() { }

  ngOnInit() {
    if (typeof this.state === 'undefined') {
      this.state = new BehaviorSubject(null);
    }

    this.state.subscribe(article => {
      if (article) {
        this.active = article;
      }
    });
  }

  setActive(article: Article) {
    this.state.next(article);
  }

}
