import { Component, OnInit, Input } from '@angular/core';
import { saveAs } from 'file-saver';
import { ReportService } from '@services/report.service';

@Component({
  selector: 'app-report-dl',
  templateUrl: './report-dl.component.html',
  styleUrls: ['./report-dl.component.css']
})
export class ReportDlComponent implements OnInit {
  @Input() input: any;

  spinner = false;

  constructor(private report: ReportService) { }

  ngOnInit() {
  }

  getReport() {
    this.toggleSpinner();

    this.report.getReport(this.input).subscribe(
      (data) => {
        this.toggleSpinner();
        this.saveReport(data);

      }, (error) => this.toggleSpinner());
  }

  saveReport(data) {
    const file = new Blob([data], { type: 'application/pdf' });
    saveAs(file, this.input.query + '.pdf');
  }

  toggleSpinner() {
    this.spinner = !this.spinner;
  }
}
