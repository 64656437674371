<div class="container" *ngIf="filters">
  <mat-accordion [multi]="true">
    <ng-container *ngFor="let filter of filters; let i=index">

      <!-- <mat-expansion-panel *ngIf="filter.enabled && filter.ProdTypAttrId != 1 && filter.ProdTypAttrId != 2" [expanded]="true"> -->
      <mat-expansion-panel 
        *ngIf="filter.enabled && filter.ProdTypAttrId != 1" 
        [expanded]="isActive(filter.ProdTypAttrId)" 
        (opened)="objectState('filterPanel', filter.ProdTypAttrId, true)"
        (closed)="objectState('filterPanel', filter.ProdTypAttrId, false)">

        <mat-expansion-panel-header>
          {{filter.ProdTypAttrDescr | uppercase}}
        </mat-expansion-panel-header>
  
        <mat-list>
          <ng-container *ngFor="let option of filter.children">
            <mat-list-item
            *ngIf="option.enabled"
            class="test">
              <mat-checkbox (change)="updateFilter(filter, option)" [(ngModel)]="option.State">{{option.ProdTypVrtDescr | titlecase}}</mat-checkbox>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </mat-expansion-panel>

    </ng-container>
  </mat-accordion>
</div>