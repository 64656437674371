<table mat-table [dataSource]="tableSource" matSort class="mat-elevation-z8">
    
  <ng-container matColumnDef="FtgNr">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer nb. </th>
    <td mat-cell *matCellDef="let element"> {{element.FtgNr}} </td>
  </ng-container>

  <ng-container matColumnDef="ftgnamn">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.ftgnamn}} </td>
  </ng-container>

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef> Select </th>
    <td mat-cell *matCellDef="let element"><button mat-raised-button color="primary" (click)="selectCustomer(element)">Select</button></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 15, 30]" showFirstLastButtons></mat-paginator>