<ng-container *ngIf="active.url">
  <div class="container" fxLayout="row" fxLayoutAlign="center center">
      <div class="controls-container" fxLayout="row" fxLayoutAlign="space-between center" fxHide.lt-sm>
        <div class="controls" fxLayoutAlign="center center">
          <i (click)="cycle(active.id,-1)" class="fas fa-angle-left fa-7x"></i>
        </div>
        <div class="controls" fxLayoutAlign="center center">
          <i (click)="cycle(active.id,1)" class="fas fa-angle-right fa-7x"></i>
        </div>
      </div>
    <img class="main-image" src="{{env.arImg500 + active.url}}" alt="{{active.title}}">
  </div>
</ng-container>
