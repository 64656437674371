import { Injectable } from '@angular/core';
import { DataService } from '@services/data.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContentItemStorageGet } from '../classes/content-core-types';
import { JvsarticleService } from '@app/services/jvsarticle.service';
import { ContentItem } from '../classes/content-core-classes';
import { Article } from '@app/classes/article';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  endpointItem = '/site/content/{CntParam}/{CntItemId}';
  contentItemsActive$: BehaviorSubject<ContentItem[]> = new BehaviorSubject(null);

  constructor(private api: DataService, private jvsarticle: JvsarticleService) { }

  jsonToContentItem(collections: any[]): ContentItem[] {
    const contentItems = Array();

    collections.forEach(collection => {
      const CntItemDatArray = JSON.parse(collection.CntItemDat) as ContentItem[];
      CntItemDatArray.forEach(item => {

        const newContent = new ContentItem();
        newContent.import(item.content);

        this.getArticleData(newContent);

        contentItems.push(newContent);

      });
    });

    return contentItems;
  }

  init() {
    this.getActive().subscribe(collections => {
      this.contentItemsActive$.next(this.jsonToContentItem(collections));
    });
  }

  getActive() {
    const endpoint = this.endpointItem.replace('{CntItemId}', '').replace('{CntParam}', 'active');
    const params = {};

    return this.api.getData(endpoint, params);
  }

  get(id: number | string = ''): Observable<ContentItemStorageGet[]> {
    const endpoint = this.endpointItem.replace('{CntItemId}', id.toString()).replace('{CntParam}', 'CntItemId');
    const params = {};

    return this.api.getData(endpoint, params);
  }

  post(payload: any) {
    if (typeof payload === 'undefined') {
      console.error('Content Service: putContent requires 1 parameters');
      return;
    }

    const endpoint = this.endpointItem.replace('{CntItemId}', '').replace('{CntParam}', 'CntItem');
    const header = [];

    return this.api.postData(endpoint, payload, header);
  }

  put(id: number, payload: any) {
    if (typeof id === 'undefined') {
      console.error('Content Service: putContent requires 2 parameters');
      return;
    }

    const endpoint = this.endpointItem.replace('{CntItemId}', id.toString()).replace('{CntParam}', 'CntItemId');
    const header: any = [];
    const body = payload;

    return this.api.putData(endpoint, body, header);
  }

  delete(id: number = null) {
    if (!id) {
      console.error('Content Service: deleteContent requires 1 parameters');
      return;
    }

    const endpoint = this.endpointItem.replace('{CntItemId}', id.toString()).replace('{CntParam}', 'CntItem');
    const header = [];

    return this.api.deleteData(endpoint, header);
  }

  getArticleData(contentItem: ContentItem) {

    if (typeof contentItem.content.queryArt !== 'undefined') {
      contentItem.content.articles = [];

      contentItem.content.queryArt.forEach(articleNumber => {
        if(articleNumber) {
          this.jvsarticle.getAr(articleNumber).subscribe(articles => {
            if (articles) {
              contentItem.content.articles.push(articles[0]);
            }
          });
        }
      });
    }

    if (typeof contentItem.content.queryParams !== 'undefined') {
      contentItem.content.articles = [];

      const params = { params: contentItem.content.queryParams };

      this.jvsarticle
      .getArProdTypReq(params)
      .subscribe((articleData: Article[]) => {
        if (articleData) {
          articleData.forEach(article => {
            contentItem.content.articles.push(article);
          });
        }
      });
    }
  }
}
