import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService as AuthGuard } from '@services/auth-guard.service';

import { DiagComponent } from '@components/diag/diag.component';
import { LoginRouteComponent } from '@components/login/login-route/login-route.component';
import { HomeRouteComponent } from '@components/site/home-route/home-route.component';
import { CartmainComponent } from '@components/cart/cartmain/cartmain.component';
import { CustomerRouteComponent } from '@components/customer/customer-route/customer-route.component';
import { OrderComponent } from '@components/orders/order/order.component';
import { InvoiceComponent } from '@components/invoices/invoice/invoice.component';
import { ArticleRouteComponent } from '@components/article/article-route/article-route.component';
import { ArticlesRouteComponent } from '@components/articles/articles-route/articles-route.component';
import { AdminRouteComponent } from '@components/admin/admin-route/admin-route.component';
import { CustomerSearchComponent } from '@components/customer/customer-search/customer-search.component';
import { UserRouteComponent } from '@components/user/user-route/user-route.component';
import { UserVerifyComponent } from '@components/user/user-verify/user-verify.component';
import { UserRecoverComponent } from '@components/user/user-recover/user-recover.component';
import { PacsofttrackComponent } from '@components/misc/pacsofttrack/pacsofttrack.component';
import { ExportRouteComponent } from '@components/export/export-route/export-route.component';



// Required routes layout for authGuard Service
const routes: Routes = [
  { path: '', component: HomeRouteComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginRouteComponent },
  { path: 'articles/prodtyp', component: ArticlesRouteComponent, canActivate: [AuthGuard] },
  { path: 'articles/:artnr', component: ArticleRouteComponent, canActivate: [AuthGuard] },
  { path: 'export', component: ExportRouteComponent, canActivate: [AuthGuard] },
  { path: 'order/track/:ordernb', component: PacsofttrackComponent, canActivate: [AuthGuard]},
  { path: 'order/:ordernb', component: OrderComponent, canActivate: [AuthGuard]},
  { path: 'invoice/:invoicenb', component: InvoiceComponent, canActivate: [AuthGuard]},
  { path: 'customer', component: CustomerRouteComponent, canActivate: [AuthGuard] },
  { path: 'customer/search', component: CustomerSearchComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserRouteComponent, canActivate: [AuthGuard] },
  { path: 'user/verify/:hash', component: UserVerifyComponent },
  { path: 'user/recover/:hash', component: UserRecoverComponent },
  { path: 'admin', component: AdminRouteComponent, canActivate: [AuthGuard] },
  { path: 'checkout', component: CartmainComponent, canActivate: [AuthGuard] },
  { path: 'diag/:param', component: DiagComponent, canActivate: [AuthGuard] },

  {
    path: 'extenda',
    loadChildren: () =>
      import('@modules/extenda/extenda.module').then(mod => mod.ExtendaModule)
      ,
      canActivate: [AuthGuard]
  },
  {
    path: 'admin/prodcat',
    loadChildren: () =>
      import('@modules/prodcat/prodcat.module').then(mod => mod.ProdcatModule)
      ,
      canActivate: [AuthGuard]
  },
  {
    path: 'admin/content',
    loadChildren: () =>
      import('@modules/content-admin/content-admin.module').then(mod => mod.ContentAdminModule)
      ,
      canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '' },
];



@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
