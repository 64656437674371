import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-user-verify',
  templateUrl: './user-verify.component.html',
  styleUrls: ['./user-verify.component.css']
})
export class UserVerifyComponent implements OnInit {
  public compState = false;
  public hash: string;

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute, private userService: UserService ) { }

  ngOnInit() {
    this.auth.isAuth.subscribe(isAuth => {
      if (isAuth) {
        this.compState = false;
        this.router.navigate(['dashboard']);
      }
    });

    this.route.params.subscribe(param => {
      if (param.hash) {
        this.userService.verifyHash(param.hash).subscribe(
          (result) => this.compState = true,
          (error) => this.router.navigate(['login'])
        );
      }
    });
  }
}
