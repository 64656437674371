<h3>Invoice history</h3>
<mat-card *ngFor="let invoice of viewSource" fxHide fxShow.lt-sm>
    <mat-card-subtitle>
      <span class="card-subt"><a routerLink="/invoice/{{invoice.FaktNr}}">{{invoice.FaktNr}}</a></span>
    </mat-card-subtitle>
    <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Order no:</div>
          <div class="card-content">{{invoice.OrderNr}}</div>
      </div>
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Type:</div>
          <div class="card-content">{{invoice.OrdTypBeskr}}</div>
      </div>
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Invoice date:</div>
          <div class="card-content">{{invoice.FaktDat | date:'shortDate'}}</div>
      </div>
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Due date:</div>
          <div class="card-content">{{invoice.FaktFfDat | date:'shortDate'}}</div>
      </div>
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Amount:</div>
          <div class="card-content">{{invoice.VbFaktTotUMoms | currency:'SEK':'symbol-narrow'}}</div>
      </div>
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Status:</div>
          <div class="card-content">{{invoice.FaktStBeskr}}</div>
      </div>
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Balance:</div>
          <div class="card-content">{{invoice.VbFaktRest | currency:'SEK':'symbol-narrow'}}</div>
      </div>
      
    </div>
</mat-card>

<div class="container mat-elevation-z8" fxHide.lt-sm>
  <table mat-table [dataSource]="dataSource" matSort>

    
    <ng-container matColumnDef="FaktNr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice no. </th>
      <td mat-cell *matCellDef="let element"> <a routerLink="/invoice/{{element.FaktNr}}">{{element.FaktNr}}</a></td>
    </ng-container>

    <ng-container matColumnDef="OrderNr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Order no. </th>
      <td mat-cell *matCellDef="let element"> {{element.OrderNr}} </td>
    </ng-container>

    <ng-container matColumnDef="OrdTypBeskr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell *matCellDef="let element"> {{element.OrdTypBeskr}} </td>
    </ng-container>

    <ng-container matColumnDef="FaktDat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice date </th>
      <td mat-cell *matCellDef="let element"> {{element.FaktDat | date:'shortDate'}} </td>
    </ng-container>

    <ng-container matColumnDef="FaktFfDat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Due date </th>
      <td [class.overdue]="element.overdue == 1" mat-cell *matCellDef="let element"> {{element.FaktFfDat | date:'shortDate'}} </td>
    </ng-container>

    <ng-container matColumnDef="FaktTotMMoms">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
      <td mat-cell *matCellDef="let element"> {{element.FaktTotMMoms | currency:'SEK':'symbol-narrow'}} </td>
    </ng-container>

    <ng-container matColumnDef="FaktStBeskr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell *matCellDef="let element"> {{element.FaktStBeskr}} </td>
    </ng-container>

    <ng-container matColumnDef="VbFaktRest">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Balance </th>
      <td [class.overdue]="element.overdue == 1" mat-cell *matCellDef="let element"> {{element.VbFaktRest | currency:'SEK':'symbol-narrow'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>