<ng-container *ngFor="let item of matrix.colors">
<div class="container" fxLayout="row wrap" *ngIf="item.PVMVariant1 == activeColor.PVMVariant1">
  <span *ngIf="item.PVMVariant1" fxFlex="1 0 100%" style="font-weight:400">{{item.PVMVariant1}} - {{item.PVMVariantDescr}}</span>
  <span *ngIf="!item.PVMVariant1" fxFlex="1 0 100%" style="font-weight:400">{{item.ArtBeskr}}</span>
  <mat-divider [inset]="true" style="margin-bottom:10px;"></mat-divider>

  <ng-container *ngFor="let cell of item.sizes">
    <div class="item" fxLayout="column nowrap" *ngIf="cell" style="padding:8px;border-right:1px solid rgb(199, 199, 199);min-width:90px;">
      <span style="color: rgba(0,0,0,0.6);margin-bottom:5px;font-weight: 400;">{{cell.PVMVariantDescr2}}</span>
      <input type="number" size="3" [(ngModel)]="order[cell.ArtNr]" placeholder="{{cell.ArtDisp}}" style="width:3em" min="0"/>
    </div>
  </ng-container>
</div>
</ng-container>
<div class="button" fxLayout="row nowrap" fxLayoutAlign="flex-end flex-start">
  <button mat-raised-button color="primary" (click)="submitToCart()">Add to cart</button>
</div>