<div fxLayout="row wrap" *ngIf="isLoaded && deliveryDates" style="padding: 10px;">
    <div style="margin-right:10px">Stock availability: </div>

    <mat-radio-group [(ngModel)]="dateSelect" (change)="emit(dateSelect)">
        <mat-radio-button *ngFor="let date of deliveryDates" [value]="date">
            {{date}}
        </mat-radio-button>
    </mat-radio-group>
</div>
<div *ngIf="!isLoaded">
    Loading purchase data...
</div>