import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  public ordernb: number;

  constructor(public route: ActivatedRoute) { }


  ngOnInit() {
    this.route.params.subscribe(res => {
      this.ordernb = res.ordernb;
    });
  }
}
