import { Component, OnInit, Input } from '@angular/core';
import { JvsarticleService } from '@services/jvsarticle.service';
import { Article } from '@classes/article';
import { ArticlesBanner } from '@classes/site';
import { AppService } from '@services/app.service';
import { ContentItemStorage, ContentItemType } from '../../classes/content-core-types';
import { ContentItem } from '../../classes/content-core-classes';
import { ContentService } from '../../services/content.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-content-core-output',
  templateUrl: './content-core-output.component.html',
  styleUrls: ['./content-core-output.component.css']
})
export class ContentCoreOutputComponent implements OnInit {
  // @Input() contentInput: BehaviorSubject<ContentHome[]> = new BehaviorSubject(null);
  @Input() contentInput$: BehaviorSubject<ContentItem[]> = new BehaviorSubject(null);

  isLoading = true;

  contents: ContentItem[] = [];

  articleSettings: {
    viewColor: boolean;
    viewPriceRetail: boolean;
    viewPriceCustomer: boolean;
  } = {
    viewColor: true,
    viewPriceRetail: true,
    viewPriceCustomer: true
  };

  constructor(private jvsarticle: JvsarticleService, private app: AppService, private content: ContentService) {}

  ngOnInit() {

    this.contentInput$.subscribe(contentItems => {
      if(contentItems) {
        this.initContent(contentItems);
      }
    })

    // if (this.contentInput) {
    //   this.initContent(this.contentInput);
    // }

    // this.initContent();

    this.app.view.view$.subscribe(() => {
      this.articleSettings.viewPriceCustomer = this.app.view.getValue(
        'viewPriceCustomer',
        true
      ).value;
      this.articleSettings.viewPriceRetail = this.app.view.getValue(
        'viewPriceRetail',
        true
      ).value;
    });
  }

  test() {
    const types: ContentItem[] = [];

    this.contents.forEach(content => {
      types.push(content);
    });

    const post: ContentItemStorage = {CntItemName: 'New Content item', CntTypeId: 1, CntItemDat: types};

    // const post: ContentItemRequestPost = {CntItemName: 'New Content item', CntTypeId: 1, CntItemDat: {test:2, hej:2} as any};

    // const subGet = this.content.get(11);
    // const subPost = this.content.post(post);
    // const subPut = this.content.put(4);

    // subPost.subscribe(result => console.log('Post', result));

    // subGet.subscribe(content => {
    //   console.log('Content Request', content);
    //   const item = new ContentItem();
    //   item.import(JSON.parse(content[0].CntItemDat));
    //   console.log('Item', item);
    // });
  }

  reload(timer = 100) {
    this.isLoading = true;
    setTimeout(() => (this.isLoading = false), timer);
  }

  initContent(contentItems: ContentItem[]) {
    this.isLoading = true;
    this.contents = contentItems;
    this.isLoading = false;

    // contentItems.forEach(contentItem => {
    //   if(contentItem.content.type == 'articles-banner') {
    //     console.log(contentItem.content);
    //     this.queryProdTyp(contentItem.content as ArticlesBanner);
    //   }
    // })
  }

  mergeCss(first, second) {
    const newCss = {};

    if (first && typeof second !== 'undefined') {
      Object.assign(newCss, first);
      Object.assign(newCss, second);

      return newCss;
    } else {
      return first;
    }
  }

  genArray(n: number) {
    const genArray = Array(n)
      .fill(n)
      .map((x, i) => i);

    return genArray;
  }

}
