import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from '@app/classes/user';

@Component({
  selector: 'app-admin-user-data',
  templateUrl: './admin-user-data.component.html',
  styleUrls: ['./admin-user-data.component.css']
})
export class AdminUserDataComponent implements OnInit {
  @Input() user: User;
  @Output() output: EventEmitter<User> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  saveUser() {
    this.output.emit(this.user);
  }
}
