import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DataService } from '@services/data.service';
import { UserService } from '@services/user.service';
import { JvscontactService } from '@services/jvscontact.service';
import { MessageService } from '@services/message.service';

import { User } from '@classes/user';
import { Customer, Contact, ComData } from '@classes/customer';
import { CustomerSearchDialogComponent } from '@components/dialog/customer-search-dialog/customer-search-dialog.component';


export interface RequestState {
  user: boolean;
  contact: boolean;
}

@Component({
  selector: 'app-admin-registration',
  templateUrl: './admin-registration.component.html',
  styleUrls: ['./admin-registration.component.css']
})

export class AdminRegistrationComponent implements OnInit {

  // Template variables
  isDone = false;
  contactRadio: string[][] = [];
  contactRadioSelected: string[] = [];
  contactsSelected: Array<string | number> = [];
  isValid: Array<boolean> = [];


  // API request targets
  users: User[];
  company: Customer[] = [];
  contacts: Array<Contact[]> = [];



  constructor(
    private api: DataService, private jvscontact: JvscontactService,
    private userService: UserService,
    private dialog: MatDialog,
    private message: MessageService) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.userService.getRegistrated().subscribe( users => {
      this.users = users as User[];
      this.processResponse();
    });
  }

  processResponse() {
    if (this.users) {
      this.users.forEach((user) => {
        user.valid = 1;
      });

      this.isDone = true;
    }
  }

  customerDialog(i: number): void {

    const dialogRef = this.dialog.open(CustomerSearchDialogComponent, {
      height: '85%',
      width: '85%'
    });

    const sub = dialogRef.componentInstance.output.subscribe(res => {
      this.company[i] = res;
      this.getContacts(res, i);

      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  getContacts(customer: Customer, i: number) {

    const endpoint: string = '/jeeves/customers/' + customer.FtgNr + '/contacts';
    const headers: any = [];

    this.api.getData(endpoint, headers).subscribe(
       data => this.contacts[i] = data as Contact[],
       err => console.error(err),
       () => this.processContacts(customer, i)
     );

  }

  processContacts(customer: Customer, i: number) {

    // Create template data for each contact.
    // Check if registered mail adress exist in request data
    this.contactRadio[i] = [];
    this.contactRadio[i].push('New');
    this.contactRadioSelected[i] = 'New';

    if (this.contacts[i] === null || this.contacts[i] === undefined) {
      return;
    }

    let match = false;
    this.contacts[i].forEach((contact) => {
      contact.ComData.forEach((data) => {
        if (data.ComNr === this.users[i].mail) {
          this.contactRadio[i].push('Update');
          this.contactRadioSelected[i] = 'Update';
          this.contactsSelected[i] = contact.FtgKontaktNr;
          match = true;
        }
      });
    });

    if (!match) {
      this.contactRadio[i].push('Update');
    }
  }

  reqComData(i: number) {
    // Support function for checking if contact data (request) has all required data
    const result: Array<{type: string, isset: boolean}> = Array(
      {type: '4', isset: false}, {type: '7', isset: false});

    this.contacts[i].forEach((contact) => {
      if (contact.FtgKontaktNr === this.contactsSelected[i]) {
        contact.ComData.forEach((data) => {
          result.forEach((check) => {

            if (data.ComKod === check.type) {
              check.isset = true;
            }

          });
        });
      }
    });

    return result;
  }

  save(i: number) {

    switch (this.contactRadioSelected[i]) {
      case 'New':

        const contact: Contact = {
          FtgPerson: this.users[i].firstname + ' ' + this.users[i].lastname,
          q_gdpraccept: this.users[i].usermeta.registration.terms,
          ComData: [
            {ComKod: '7', ComNr: this.users[i].mail},
            {ComKod: '4', ComNr: this.users[i].usermeta.registration.phone}
          ]
        };

        this.jvscontact.newContact(this.company[i].FtgNr, contact).subscribe(res => {
            const newContact = res as Contact;
            this.contactsSelected[i] = newContact.FtgKontaktNr;
            this.updateUser(i);
        });

        break;


      case 'Update':
        let runInsert = false;
        const comData: Array<ComData> = [];

        const reqComdata = this.reqComData(i);

        // Run an insert if contact (request) has partial data registered.
        reqComdata.forEach((required) => {
          if (!required.isset) {
            switch (required.type) {
              case '4':
                comData.push({ComKod: '4', ComNr: this.users[i].usermeta.registration.phone});
                runInsert = true;
                break;

              case '7':
                comData.push({ComKod: '7', ComNr: this.users[i].mail});
                runInsert = true;
                break;
            }

          }
        });

        if (runInsert) {
          const newContact: Contact = {
            ComData: comData
          };

          this.jvscontact.newComData(this.company[i].FtgNr, this.contactsSelected[i], newContact).subscribe(res => { });
        }

        // Run the actual UPDATE request
        const updateData: Contact = {
          q_gdpraccept: this.users[i].usermeta.registration.terms,
          ComData: Array(
            {ComKod: '7', ComNr: this.users[i].mail},
            {ComKod: '4', ComNr: this.users[i].usermeta.registration.phone}
          )
        };

        this.jvscontact.updateContact(this.company[i].FtgNr, this.contactsSelected[i], updateData).subscribe(res =>
          this.updateUser(i)
        );

        break;
    }
  }

  updateUser(i: number) {
    const payload: User = {} as User;

    payload.userid = this.users[i].userid;
    payload.jvscompany = this.company[i].FtgNr;
    payload.jvscontact = this.contactsSelected[i];
    payload.valid = this.users[i].valid;
    payload.usermeta = {...this.users[i].usermeta };

    delete payload.usermeta.registration;

    this.userService.updateUser(payload).subscribe(
      () => {
        this.userService.activate(payload.userid).subscribe(
          () => {
            this.message.send({type: 'snackbar', message: 'User updated', source: 'User Service', duration: 2000});
            }
        );

        this.saveSuccess(i);
      }
    );
  }

  saveSuccess(i: number) {
    this.getUsers();
  }
}
