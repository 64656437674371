import { Component, OnInit } from '@angular/core';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-settings-list',
  templateUrl: './settings-list.component.html',
  styleUrls: ['./settings-list.component.css']
})
export class SettingsListComponent implements OnInit {

  checkboxes = {
    viewPriceCustomer: true,
    viewPriceRetail: true
  };

  constructor(private app: AppService) { }

  ngOnInit() {
    this.checkboxes.viewPriceCustomer = this.app.view.getValue('viewPriceCustomer', true).value;
    this.checkboxes.viewPriceRetail = this.app.view.getValue('viewPriceRetail', true).value;
  }

  publish(name: string, value: any) {
    this.app.view.setValue(name, value, true);
  }


}
