import { Injectable } from '@angular/core';
import { DataService } from '@services/data.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  endpoint = '/app/report/mail';

  constructor(private api: DataService) { }

  getReport(query: any) {
    return this.api.postData(this.endpoint, query, {responseType: 'blob'});
  }
}
