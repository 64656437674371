import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { MenuService} from '@services/menu.service';
import { Menu, MenuItems } from '@classes/menu';

@Component({
  selector: 'app-menu-route',
  templateUrl: './menu-route.component.html',
  styleUrls: ['./menu-route.component.css']
})
export class MenuRouteComponent implements OnInit {

  menues: Menu[] = null;
  menuItems$: BehaviorSubject<MenuItems[]> = new BehaviorSubject(null);
  drawerState$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private menuService: MenuService, private router: Router) { }

  ngOnInit() {
    this.menuService.menuList$.subscribe(menuList => {
      this.menues = menuList;
    });
  }

  openSubMenu(menuItems: MenuItems[]) {
    this.menuItems$.next(menuItems);
  }

  subMenuClick(menuItems: MenuItems) {

    if (!menuItems.MenuDescr) {
      // ProdCat Naviagation
      const query = this.buildQuery(menuItems);
      this.router.navigate(['/articles/prodtyp'], {queryParams: query});
    } else {
      // Manual link navigation
      this.router.navigate([menuItems.children[0].MenuLink]);
    }
  }

  buildQuery(menuItems: MenuItems) {

    const query: MenuItems = {
      PriAttr: menuItems.PriAttr ? menuItems.PriAttr : null
      , PriVrt: menuItems.PriVrt ? menuItems.PriVrt : null
      , level2Attr: menuItems.level2Attr ? menuItems.level2Attr : null
      , level2Vrt: menuItems.level2Vrt ? menuItems.level2Vrt : null
      , level3Attr: menuItems.children[0] ? menuItems.children[0].level3Attr : null
      , level3Vrt: menuItems.children[0] ? menuItems.children[0].level3Vrt : null
    };

    return query;
  }

  drawerToggle() {
    this.drawerState$.next(!this.drawerState$.getValue());
  }
}
