import { BehaviorSubject } from 'rxjs';

export interface Login {
    username: string;
    password: string;
}

export interface ApiKey {
    value: string;
}

export interface User {
    created: string;
    userid: number;
    username: string;
    role_id?: number;
    usermeta?: {registration?: Registration};
    mail: string;
    firstname: string;
    lastname: string;
    jvscompany?: string;
    jvscontact?: number | string;
    valid: number;
    activated: number;
    cartactive: number;
    options?: UserOption[];
}

export interface UserOption {
    option: string;
    value: any;
}

export interface UserSettingsInt {
    priceRegular: boolean;
    priceRetail: boolean;
    priceHide: boolean;
}

export class Registration {

    constructor(
        public shop_name: string = '',
        public firstname: string = '',
        public lastname: string = '',
        public address: string = '',
        public city: string = '',
        public email: string = '',
        public phone: string = '',
        public password: string = '',
        public confirm: string = '',
        public terms: boolean = false
    ) { }
}


export class UserSettings {
    constructor(
        public userSettings$: BehaviorSubject<UserSettingsInt>
    ) {
        this.defaultSettings();
    }

    defaultSettings() {
        this.userSettings$ = new BehaviorSubject({
            priceRegular: true, priceRetail: false, priceHide: false
        });
    }
}
