import { Component, OnInit, Input } from '@angular/core';
import { Article } from '@classes/article';
import { CartItem } from '@classes/cart';
import { BehaviorSubject } from 'rxjs';
import { CartService } from '@services/cart.service';

@Component({
  selector: 'app-article-matrix',
  templateUrl: './article-matrix.component.html',
  styleUrls: ['./article-matrix.component.css']
})
export class ArticleMatrixComponent implements OnInit {
  @Input() matrix: {colors: Article[], sizes: Article[]};
  @Input() state: BehaviorSubject<Article>;

  private colorActive = null;
  private order = {};

  constructor(private cart: CartService) { }

  ngOnInit() {
    if (typeof this.state === 'undefined') {
      this.state = new BehaviorSubject(null);
    }

    this.state.subscribe(article => {
      if (article) {
        this.colorActive = article.PVMVariant1;
      }
    });
  }

  setActive(article: Article) {
    this.colorActive = article.PVMVariant1;
    this.state.next(article);
  }

  dateToString(date: Date) {
    if (date) {
      return date.toISOString().slice(0, 10);
    } else {
      return null;
    }
  }

  submitToCart() {
    const items: CartItem[] = [];
    let quantity = 0;

    for (const item in this.order) {
      if (this.order[item]) {
        items.push({
          itemnb: item, itemqty: this.order[item],
          itemvat: 0, deliverydat: this.dateToString(this.cart.deliveryDate$.getValue()),
          itemmeta: { }
        });

        quantity += Number(this.order[item]);
        this.order[item] = null;
      }
    }

    this.cart.addItem(items);
  }
}
