<div *ngIf="isDone">
  <mat-accordion>
    <ng-container *ngFor="let user of users; let i=index">
      <mat-expansion-panel *ngIf="user.valid == '0'">
        <mat-expansion-panel-header>
            {{user.firstname | uppercase }} {{user.lastname | uppercase }}
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>