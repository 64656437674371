<section class="mat-typography">

<ng-container [ngSwitch]="template">
    <ng-container *ngSwitchCase="'order'">
    <h1>SPECIFIC TERMS FOR PRODUCT DELIVERY</h1>
    <h3>1. Prices and freight</h3>
    <p>Bagheera AB reserves the right to change prices and product specifications at any time during the order processs. All prices are excluding VAT.</p>
    <p>Cost for freight will be added to all deliveries where applicable. The total freight cost is calculated and applied to the invoice.</p>

    <h3>2. Terms of payment and security</h3>
    <p>Payment can be made by invoice. All customers are subject to credit worthiness examination prior to order acceptance. If there is any doubt about the customers credit worthiness Bagheera AB reserves the right to change or revoke existing terms and refuse delivery.</p>
    <p>Over due invoices are subject to 12% interest each overdue month.</p>
    
    <h3>3. Delivery</h3>
    <p>Delivery is made within Sweden by carrier or delivery firm engaged by Bagheera AB with delivery terms: Ex works Bagheera AB´s warehouse. The risk for delivery is passed to the customer when Bagheera AB hands over the product to carrier.</p>
    <p>Bagheera AB does not carry any responsibility for delivery being delayed, regardless if the delay depends on delay by the carrier , delay or back order by the product manufacturer or any other circumstance</p>

    <h3>4. Repurchase</h3>
    <p>For all wishes to repurchase, the customer shall contact Bagheera AB via <a href="mailtoo:order@bagheera.se">order@bagheera.se</a> or by phone +46 (0)226 58500 for approval and agreement of repurchase terms. Bagheera AB reserves the rights to refuse any request of repurchase.</p> 
    <p>Returns not accepted for repurchase are returned to the customer on the customer´s expense. Accepted returns are credited the customer after deducting return costs (if any). Approval of returns are subject to the customer´s fulfilment of agreed obligations.</p>

    <h3>5. Transport damages</h3>
    <p>Upon receipt of the delivery, the customer must check the delivered products. Transportation damages shall be reported on the day of delivery to the carrier and to Bagheera AB. A hidden transport damage that is not discovered or should have been discovered shall be complained of as soon as possible and under all circumstances within a week from receipt of delivery. If the customer does not make a claim for transport damages within the stipulated timeframe, the customer loses its right to make a claim against the carrier and Bagheera AB. </p>

    <h3>6. Complaint</h3>
    <p>Upon receipt of the delivery, the customer must check the delivered products. To have a right to claim that a product/delivery was defective, the customer shall make a complaint to Bagheera AB immediately after the defect was discovered and in no event later than 10 days from the date of invoice, and return the product in the specified manner.</p>
    
    
    
  </ng-container>

  <ng-container *ngSwitchCase="'customer'">
    <h1>Bagheeras Privacy Policy</h1>
    <h3>1. General</h3>
    <p>This privacy policy sets out how Bagheera AB will store and use information relating to you (the “customer” or “you”) when you use or place an order on the website b2b.bagheera.se (the “Website”).</p>
    <p>Bagheera AB, 5562-41 (the “Company”, “we” or “us”) is the controller of personal data</p>

    <h3>2. Collection Of Personal Data</h3>
    <p>When you place an order on the Website, we collect and store the following information that you provide: company name, customer number, name of the person placing the order/reference/contact person, address, email address and phone number.</p>
    <p>If you contact our customer service in relation to your order, we may – in addition to what is set out above – store such information you provide to our customer service.</p>
    <p>Please note that information that may identify you as an individual is considered personal data, even if you place the order on behalf of a company.</p>

    <h3>3. Our User Of Your Personal Data</h3>
    <p>We use the personal data we collect for the following purposes:</p>
    <ul>
      <li>To accept and process your order</li>
      <li>To, when needed, contact you in relation to your order.</li>
      <li>To deliver and invoice the goods you have ordered (including to notify you of delivery).</li>
      <li>To manage complaints and warranty related matters, legal matters and questions to our customer service.</li>
      <li>Provided that you have not opted out, to send you information regarding us, our products and our services.</li>
    </ul>
    <p>The Company does not collect or purchase customer data from a third party. Neither do we disclose your personal data to a third party, except and insofar it is necessary to fulfill the purposes set out in section 3, to manage our IT systems or if it follows from law or a decision by a public authority.</p>
    <p>Partners who process personal data on our behalf will always enter into a data processing agreement with us, to ensure that our partners maintain a high level of security for your personal data.</p>
    
    <h3>4. Legal Ground For Processing Of Personal Data</h3>
    <p>Personal data may only be processed if there is legal ground, and the Company will always process your personal data in accordance with applicable law.</p>
    <p>The Company will process personal data since it is necessary for us to fulfill the purchase agreement with the customer. Marketing to customer is based on our legitimate interest to provide relevant information and offers to our customers. In case we need to process personal data for legal matters (e.g. in relation to product liability), it is a legitimate interest for the company to protect our legal interests.</p>

    <h3>5. How Long Do We Keep Data?</h3>
    <p>We will only keep your personal information for as long it is necessary according to the purposes set out in section 3, or is mandated by law. User accounts that have not been accessed for 36 months will automatically removed.</p>

    <h3>6. Your Rights</h3>
    <p>You have the right to, in accordance with applicable data protection legislation, at any time request access to the personal data concerning you that we process. Further, you have the right to obtain rectification of inaccurate personal data concerning you, to obtain erasure or restriction of processing of personal data concerning you, to exercise your right to data portability and to object the processing of personal data concerning you. </p>
    <p>In case you would like to exercise any of your rights, please contact us:<br>
        Bagheera AB, Industrigatan 8, 77435 Avesta, Sweden<br>
        info@bagheera.se (write “Data Protection” in the subject line) </p>
    <p>You are further entitled to, at any time, lodge a complaint with the competent supervisory authority if you consider that your personal data has been processed in violation of applicable data protection legislation:<br>
        Datainspektionen<br>
        Box 8114<br>
        104 20 Stockholm<br>
        www.datainspektionen.se</p>
  </ng-container>
</ng-container>

<div>
  <button mat-raised-button color="primary" (click)="accept()">I accept above terms</button>
</div>
</section>