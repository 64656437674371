
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2" fxHide.lt-md>
    
    <ng-container matColumnDef="FtgKontaktNr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact nb. </th>
      <td mat-cell *matCellDef="let element; let i=index"> {{element.FtgKontaktNr}} </td>
    </ng-container>
  
    <ng-container matColumnDef="FtgPerson">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element; let i=index"> 
        <ng-container *ngIf="!editMode[i]">{{element.FtgPerson}}</ng-container>

        <ng-container *ngIf="editMode[i]">
          <mat-form-field>
            <input matInput type="text" [(ngModel)]="contacts[i].FtgPerson">
          </mat-form-field>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="ComBeskr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell *matCellDef="let element; let i=index"> {{element.ComBeskr}} </td>
    </ng-container>

    <ng-container matColumnDef="ComNr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
      <td mat-cell *matCellDef="let element; let i=index"> 
        <ng-container *ngIf="!editMode[i]">{{element.ComNr}}</ng-container>

        <ng-container *ngIf="editMode[i]">
          <mat-form-field>
            <input matInput type="text" [(ngModel)]="contacts[i].ComNr">
          </mat-form-field>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="new">
      <th mat-header-cell *matHeaderCellDef> Add </th>
      <td mat-cell *matCellDef="let element; let i=index">
        <ng-container *ngIf="!editMode[i]">
          <i class="fa fa-edit" style="cursor:pointer;margin-right:8px;" (click)="toggleEditMode(i)"></i>
          <i class="fa fa-trash" style="cursor:pointer;margin-right:8px;"></i>
        </ng-container>
        <ng-container *ngIf="editMode[i]">
          <i class="fa fa-check" style="cursor:pointer;margin-right:8px;" (click)="saveUpdates(i)"></i>
          <i class="fa fa-ban" style="cursor:pointer;margin-right:8px;" (click)="toggleEditMode(i)"></i>
        </ng-container>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator *ngIf="paginateEnabled" [pageSizeOptions]="[5, 15, 30]" showFirstLastButtons fxHide.lt-md></mat-paginator>

  <div class="mob-container" fxLayout="row wrap" fxHide fxShow.lt-md>

    <mat-card *ngFor="let contact of input; let i=index" fxFlex="100%" fxLayout="row wrap">

      <div fxFlex="100%">
        <ng-container *ngIf="!editMode[i]"><h3>{{contact.FtgPerson}}</h3></ng-container>

        <ng-container *ngIf="editMode[i]">
          <mat-form-field>
            <input matInput type="text" [(ngModel)]="input[i].FtgPerson">
          </mat-form-field>
        </ng-container>
      </div>

      <ng-container *ngFor="let ComData of contact.ComData; let ii=index">

        <div fxFlex="100%" fxLayout="row nowrap">

          <div style="min-width:120px;width:auto;">{{ComData.ComBeskr}}</div>
          <div fxFlex="100%">
            <ng-container *ngIf="!editMode[i]">{{ComData.ComNr}}</ng-container>

            <ng-container *ngIf="editMode[i]">
              <mat-form-field>
                <input matInput type="text" [(ngModel)]="input[i].ComData[ii].ComNr">
              </mat-form-field>
            </ng-container>
          </div>

          <div *ngIf="toolsEnabled" style="min-width:50px;margin-left:5px;">
            <ng-container *ngIf="!editMode[i]">

              <i class="fa fa-edit" style="cursor:pointer;margin-right:8px;" (click)="toggleEditMode(i)"></i>
              <i class="fa fa-trash" style="cursor:pointer;margin-right:8px;"></i>

            </ng-container>
            <ng-container *ngIf="editMode[i]">

              <i class="fa fa-check" style="cursor:pointer;margin-right:8px;" (click)="saveUpdates(i,ii)"></i>
              <i class="fa fa-ban" style="cursor:pointer;margin-right:8px;" (click)="toggleEditMode(i)"></i>

            </ng-container>
          </div>
        </div>

      </ng-container>
    </mat-card>

  </div>