<div class="container" fxLayout="column nowrap" fxLayoutAlign="flex-start" fxLayoutGap="10px">

  <div fxLayout="column nowrap" fxLayoutAlign="flex-start stretch">
    <span class="product-header">{{article.ArtBeskr | uppercase}}</span>
    <span class="product-subtitle">Articel nb. {{article.PvmMasterItem}}</span>
    <mat-divider [inset]="true"></mat-divider>

    <span *ngIf="!isCampaign" class="product-price">
      <ng-container *ngIf="viewPriceCustomer">
        {{article.Pris | currency:'SEK':'symbol-narrow'}} 
      </ng-container>
      <ng-container *ngIf="viewPriceCustomer && viewPriceRetail">
         / 
      </ng-container>
      <ng-container *ngIf="viewPriceRetail">
        {{article.ArtCirkaPris | currency:'SEK':'symbol-narrow'}}
      </ng-container>
    </span>

    <span *ngIf="isCampaign" class="product-price" style="text-decoration: line-through;">
      <ng-container *ngIf="viewPriceCustomer">
        {{article.Vb_Pris | currency:'SEK':'symbol-narrow'}} 
      </ng-container>
      <ng-container *ngIf="viewPriceCustomer && viewPriceRetail">
         / 
      </ng-container>
      <ng-container *ngIf="viewPriceRetail">
        {{article.ArtCirkaPris | currency:'SEK':'symbol-narrow'}}
      </ng-container>
    </span>

    <span *ngIf="isCampaign" class="product-price" style="color: #ff4081">
      <ng-container *ngIf="viewPriceCustomer">
        {{article.Pris | currency:'SEK':'symbol-narrow'}} 
      </ng-container>
      <ng-container *ngIf="viewPriceCustomer && viewPriceRetail">
         / 
      </ng-container>
      <ng-container *ngIf="viewPriceRetail">
        {{article.ArtCirkaPrisPrl | currency:'SEK':'symbol-narrow'}}
      </ng-container>
    </span>
    
    <mat-divider [inset]="true"></mat-divider>
  </div>

  <div>
    <mat-tab-group fxHide.lt-sm>
      <mat-tab label="Description">
        <p [innerHtml]="article.Edit"></p>
      </mat-tab>
    </mat-tab-group>
    <mat-divider [inset]="true"></mat-divider>

    <mat-accordion fxHide fxShow.lt-sm>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          Description
        </mat-expansion-panel-header>
          <mat-list-item>
            <p [innerHtml]="article.Edit"></p>
          </mat-list-item>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

</div>