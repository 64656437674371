<div fxHide.lt-md *ngIf="isDone">
<table mat-table [dataSource]="tableSource" class="mat-elevation-z2">
    <ng-container matColumnDef="rowcreate">
      <th mat-header-cell *matHeaderCellDef> Created </th>
      <td mat-cell *matCellDef="let element">{{element.rowcreate | date}}</td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef> Comment </th>
      <td mat-cell *matCellDef="let element; let i=index">
          <mat-form-field floatLabel="always">
          <input 
            matInput
            [value]="element.comment" [readonly]="isShared(element.shared)"
            [(ngModel)]="element.comment" [ngModelOptions]="{ updateOn: 'blur' }" (ngModelChange)="updateCart(i, 'comment')">
          </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="shared">
      <th mat-header-cell *matHeaderCellDef> Shared </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox [checked]="isShared(element.shared)" [disabled]="true"></mat-checkbox>
        <!-- <input matInput type="checkbox" [checked]="isShared(element.shared)" [readonly]="true" readonly> -->
        <!-- <i *ngIf="element.shared === '1'" class="fas fa-check"></i> -->
      </td>
    </ng-container>

    <ng-container matColumnDef="selected">
      <th mat-header-cell *matHeaderCellDef> Active </th>
      <td mat-cell *matCellDef="let element"><i *ngIf="element.cartid === cartactive" class="fas fa-check" style="color:#ff4081"></i></td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element">
        <i class="fa fa-check"
          style="cursor:pointer;margin-right:10px;"
          matTooltipPosition="above"
          matTooltip="Select cart"
          (click)="user.updateUserCart(element.cartid)" ></i>

        <i class="fa fa-ban"
          style="cursor:pointer;"
          matTooltipPosition="above"
          matTooltip="Delete cart"
          (click)="deleteCart(element.cartid)"></i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>

<div fxHide fxShow.lt-md class="container-mob" fxLayout="row wrap" *ngIf="isDone">
    <mat-card class="section" *ngFor="let element of tableSource.data" fxFlex="100%" fxLayout="row wrap">
      <div fxFlex="100%" class="header" fxLayout="row nowrap" fxLayoutAlign="space-between">
        <span>{{element.rowcreate | date}}</span>
        <i *ngIf="element.cartid === cartactive" class="fas fa-check" style="color:#ff4081"></i>
      </div>
      <div fxFlex="100%" fxLayout="row nowrap" fxLayoutAlign="space-between" fxLayoutGap="10px">
        <div fxFlex="60%" fxLayout="column wrap" fxLayoutAlign="flex-start flex-start">
          <mat-form-field floatLabel="always">
            <input 
            matInput
            placeholder="Comment"
            [value]="element.comment" [readonly]="isShared(element.shared)"
            [(ngModel)]="element.comment" [ngModelOptions]="{ updateOn: 'blur' }" (ngModelChange)="updateCart(i, 'comment')">
          </mat-form-field>
          
          <div style="height:auto;width:auto">
            <mat-checkbox [checked]="isShared(element.shared)" [disabled]="true">Shared </mat-checkbox>
          </div>

        </div>
        <div fxFlex="40%" fxLayout="column nowrap" fxLayoutAlign="flex-start flex-end" fxLayoutGap="10px">
            <button mat-raised-button color="primary" (click)="user.updateUserCart(element.cartid)">Select</button>
            <button mat-raised-button color="accent" (click)="deleteCart(element.cartid)">Delete</button>
        </div>
      </div>
    </mat-card>
</div>