<div style="width:100%; height:100%;" fxHide.lt-md>
    <ng-container *ngIf="iframeSrc">
        <iframe [src]="iframeSrc" frameborder="0" style="width:100%;height:100%"></iframe>
    </ng-container>
</div>

<div style="width:100%; height:100vh;" fxHide fxShow.lt-md>
    <ng-container *ngIf="iframeSrc">
        <iframe [src]="iframeSrc" frameborder="0" style="width:100%;height:100%"></iframe>
    </ng-container>
</div>
