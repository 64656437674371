import { Article } from '@classes/article';
import { ContentItem } from './content-core-classes';

export interface FlexLayout {
  fxLayoutGap?: string;
  fxLayoutAlign?: string;
}

export interface CotentArticleCard {
  article: Article;
  settings?: {showColor: boolean, showPrice: boolean};
}

export interface ContentArticlesOld {
  type: string;
  articles: Article[];
  displayCount: number;
  settings: {showColor: boolean, showPrice: boolean};
  query: any;
}

export interface ContentItemType {
  type?: string;
  hide?: boolean;
  css?: {[key: string]: string};
  cssMob?: {[key: string]: string};
  flex?: {[key: string]: string};
  value?: string;
  link?: string;
  displayCount?: number;
  queryParams?: {PriAttr?: number, PriVrt?: number, level2Attr?: number, level2Vrt?: number, level3Attr?: number, level3Vrt?: number};
  queryArt?: string[];
  articles?: Article[];
  children?: ContentItem[] | ContentItemType[];
}

export interface ContentItemStorageGet {
  CntItemId?: number;
  CntItemDate?: string;
  CntTypeId?: number;
  CntItemName?: string;
  CntItemStatus?: number;
  CntItemSort?: number;
  CntItemDat?: string;
}

export interface ContentItemStorage {
  CntItemId?: number;
  CntItemDate?: string;
  CntTypeId?: number;
  CntItemName?: string;
  CntItemStatus?: number;
  CntItemSort?: number;
  CntItemDat?: ContentItem[];
}
