<div class="container" fxLayout="row wrap" fxLayoutAlign="center flex-start">

  <div fxFlex="100%" class="spinner" fxLayout="column nowrap" fxLayoutAlign="center center" *ngIf="!isDone">
    <mat-spinner></mat-spinner>
  </div>

  <div fxFlex="100%" class="main" fxLayout="row nowrap" fxLayoutAlign="center center" *ngIf="isDone">
    <img src="{{env.arImg100 + view.imageUrl}}" alt="" (click)="openDialog(view)">
  </div>

  <div fxFlex="100%" class="extra" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="isDone">
    <ng-container *ngIf="productImages.length">
      <img 
        *ngFor="let image of productImages"
        src="{{env.arImg300 + image.imageUrl}}" 
        alt=""  
        [class.active]="view.imageUrl === image.imageUrl"
        (click)="view = image">
    </ng-container>
  </div>

</div>