import { Component, OnInit } from '@angular/core';
import { CartService } from '@services/cart.service';

@Component({
  selector: 'app-cartitem-footer',
  templateUrl: './cartitem-footer.component.html',
  styleUrls: ['./cartitem-footer.component.css']
})
export class CartitemFooterComponent implements OnInit {

  cartSubQty = 0;
  cartSubTotal = 0;
  cartSubVat = 0;

  constructor( private cart: CartService) { }

  ngOnInit() {
    this.cart.cartQty$.subscribe(qty => this.cartSubQty = qty);
    this.cart.cartSum$.subscribe(sum => this.cartSubTotal = sum);
    this.cart.cartVat$.subscribe(vat => this.cartSubVat = vat);
  }

}
