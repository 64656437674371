import { Component, OnInit } from '@angular/core';

import { UserService } from '@services/user.service';
import { User } from '@classes/user';
import { AuthService } from '@services/auth.service';
import { MenuService } from '@services/menu.service';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.css']
})
export class UserDropdownComponent implements OnInit {

  ddShow = false;
  activeuser: User;
  isDone = false;
  logoutAll = false;
  showPrice = true;

  constructor(private user: UserService, private auth: AuthService, private menu: MenuService) { }

  ngOnInit() {
    this.user.user$.subscribe(user => {
      if (user.userid) {
        this.activeuser = user;
        this.isDone = true;
      }
    });
  }

  logout() {
    this.menu.reset();
    this.auth.logout(this.logoutAll);
  }
}
