import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { JwtService } from '@services/jwt.service';
import { DataService } from '@services/data.service';
import { MessageService } from '@services/message.service';

@Injectable()
export class AuthService {
  private apiLoginEndpoint = '/app/auth';
  private apiLoginoutEndpoint = '/app/auth/logout';
  private apiTokenEndpoint = '/app/authtoken';
  private apiResponse: any = {};
  public sessionStorage = true;

  private authHeaders: any = {
      Authorization: '',
      'Content-Type': 'application/json'
  };

  private hasToken = false;
  private hasLogin = false;

  // Set to false on app init (JWT) if no jwt is avalible.
  public isAuth: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isPending: BehaviorSubject<boolean> = new BehaviorSubject(false);


  constructor( public jwt: JwtService, private api: DataService, private message: MessageService) { }

  public login(user: string, password: string) {

    if (!user || !password) {
      this.message.send({
        type: 'snackbar'
        , message: 'Username and password is required'
        , source: 'Authentication'
        , duration: 3000
      });
    } else {
      this.encodeAuthHeader(user, password);
      this.userAuth();
    }
  }

  public logout(logoutAll = false) {
    if (logoutAll) {
      this.logoutAll().subscribe(res => {

        this.message.send({
          type: 'snackbar'
          , message: 'All devices logged out successfully'
          , source: 'Authentication'
          , duration: 3000
        });

        this.resetSession();
      });
    } else {
      this.resetSession();
    }
  }

  public resetSession() {
    this.isPending.next(true);
    this.jwt.clear();
    this.apiResponse = {};
    this.isAuth.next(false);
    this.isPending.next(false);
  }

  private loginSuccess() {
    this.jwt.save(this.apiResponse.jwt, this.sessionStorage);
    this.hasLogin = true;
    this.isAuth.next(true);
    this.isPending.next(false);
  }

  private loginFaile(error: {message:string, code:number}) {
    this.jwt.clear();

    let message: string = 'Login could not be processed';
    let source: string = 'Server Error';
    let duration: number = 6000;

    switch(error?.code) {
      case 400:
        source = 'Authentication';
        message = 'Authentication failed';
        duration = 3000;
      break;

      case 401:
        source = 'Authentication';
        message = 'Authentication failed';
        duration = 3000;
      break;
    }

    this.message.send({
      type: 'snackbar'
      , message: message
      , source: source
      , duration: duration
    });
    this.isPending.next(false);
  }

  private userAuth() {
    const body: any = {};
    const httpOptions = {
      headers: new HttpHeaders(this.authHeaders)
    };

    this.isPending.next(true);

    this.api.postData(this.apiLoginEndpoint, body, httpOptions).subscribe(
       (data) => {
          this.apiResponse = data;
          this.loginSuccess();
        },
        (error: {message:string, code:number}) => this.loginFaile(error)
     );
  }

  private encodeAuthHeader(user: string, password: string) {
    const base64: string = btoa(user + ':' + password);
    this.authHeaders.Authorization = 'Basic ' + base64;
  }


  public getJwtId() {
    return this.jwt.jwtId;
  }

  public jwtAuth() {
    this.isPending.next(true);

    if (this.jwt.isValid) {
      this.hasToken = true;

      const body: any = [];
      const headers: any = [];

      this.api.postData(this.apiTokenEndpoint, body, headers).subscribe(
        () => {
          this.isAuth.next(true);
          this.isPending.next(false);
         },
        (error) => {
          this.jwt.clear();
          this.isAuth.next(false);
          this.isPending.next(false);
        }
       );
     } else {
      this.jwt.clear();
      this.isAuth.next(false);
      this.isPending.next(false);
     }
  }

  public logoutAll() {
    const endpoint = this.apiLoginoutEndpoint;

    return this.api.getData(endpoint, []);
  }
}
