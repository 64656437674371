<ng-container *ngIf="isDone">
  <mat-card fxLayout="row wrap" fxLayoutAlign="center flex-start">

    <div class="header" fxFlex="100%" fxLayout="row nowrap" fxLayoutAlign="flex-start flex-start">
      <h3>ORDER {{order.OrderNr}}</h3>
      <app-report-dl [input]="{'template':'order_confirmImg', query:order.OrderNr}"></app-report-dl>
    </div>

    <div class="section" fxFlex="33%" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
      <div class="section-row" fxFlex="100%">
        <mat-form-field class="full-width" floatLabel="always">
            <input matInput placeholder="Customer name" [value]="order.DelFtgNamn" readonly>
        </mat-form-field>
      </div>
      <div class="section-row" fxFlex="100%">
        <mat-form-field class="full-width" floatLabel="always">
            <input matInput placeholder="Delivery address" [value]="order.FtgPostadr5" readonly>
        </mat-form-field>

      </div>
      <div class="section-row" fxFlex="100%">
        <mat-form-field class="full-width" floatLabel="always">
            <input matInput placeholder="Zip / City" [value]="order.FtgLevPostNr +' '+ order.FtgPostLevAdr3" readonly>
        </mat-form-field>
      </div>

      <div class="section-row" fxFlex="100%" fxLayout="column" fxLayoutAlign="center flex-start">
        <button mat-raised-button color="primary" (click)="openDialog(order.OrderNr)" fxHide.lt-md>Track delivery</button>
        <button mat-raised-button color="primary" routerLink="/order/track/{{order.OrderNr}}" fxHide fxShow.lt-md>Track delivery</button>
      </div>
    </div>

    <div class="section" fxFlex="33%" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
      <div class="section-row" fxFlex="100%">
        <mat-form-field class="full-width" floatLabel="always">
            <input matInput placeholder="Type" [value]="order.OrdTypBeskr" readonly>
        </mat-form-field>
      </div>
      <div class="section-row" fxFlex="100%">
        <mat-form-field class="full-width" floatLabel="always">
            <input matInput placeholder="Status" [value]="order.OrdStatBeskr" readonly>
        </mat-form-field>
      </div>
      <div class="section-row" fxFlex="100%">
        <mat-form-field class="full-width" floatLabel="always">
            <input matInput placeholder="Delivery date" [value]="order.OrdBerLevDat" readonly>
        </mat-form-field>
      </div>
    </div>

    <div class="section" fxFlex="33%" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
      <div class="section-row" fxFlex="100%">
        <mat-form-field class="full-width" floatLabel="always">
            <input matInput placeholder="Delivery type" [value]="order.LevSattBeskr" readonly>
        </mat-form-field>
      </div>
      <div class="section-row" fxFlex="100%">
        <mat-form-field class="full-width" floatLabel="always">
            <input matInput placeholder="Payment terms" [value]="order.BetVillBeskr" readonly>
        </mat-form-field>
      </div>
      <div class="section-row" fxFlex="100%">
        <mat-form-field class="full-width" floatLabel="always">
            <input matInput placeholder="Seller" [value]="order.SaljareNamn" readonly>
        </mat-form-field>
      </div>
      <div class="section-row" fxFlex="100%">
        <mat-form-field class="full-width" floatLabel="always">
            <input matInput placeholder="Total amount (ex. VAT)" [value]="order.OrdSum | currency:'SEK':'symbol-narrow'" readonly>
        </mat-form-field>
      </div>
    </div>

  </mat-card>
</ng-container>