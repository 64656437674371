import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { environment } from '@env/environment';
import { Image, Images } from '@classes/dialog';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.css']
})

export class ImageDialogComponent implements OnInit {

  public env = environment;
  active: Image;
  count: number;

  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Images) {}

  ngOnInit() {
    this.active = this.data.active;
    this.count = this.data.images.length;
    console.log(this.count);
  }

  cycle(id: string, i: number) {
    let activeIndex: number;
    let loadIndex: number;

    this.data.images.forEach((image, index) => {
      if (id === image.id) {
        activeIndex = index;
      }
    });

    loadIndex = activeIndex + i;

    if (activeIndex + i > this.count - 1) {
      loadIndex = 0;
    }

    if (activeIndex + i < 0) {
      loadIndex = this.count - 1;
    }

    this.active = this.data.images[loadIndex];
  }
}
