import { NgModule } from '@angular/core';
import { ContentCoreOutputComponent } from './components/content-core-output/content-core-output.component';
import { GlobalArticleModule } from '../global/global-article.module';
export * from './classes/content-core-types';
export * from './classes/content-core-classes';


@NgModule({
  declarations: [ContentCoreOutputComponent],
  imports: [
    GlobalArticleModule,
  ],
  exports: [
    ContentCoreOutputComponent
  ]
})
export class ContentCoreModule { }
