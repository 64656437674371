import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { JvsarticleService } from '@services/jvsarticle.service';
import { JvsarticlefilterService } from '@services/jvsarticlefilter.service';
import { Article } from '@classes/article';

@Component({
  selector: 'app-articles-route',
  templateUrl: './articles-route.component.html',
  styleUrls: ['./articles-route.component.css']
})
export class ArticlesRouteComponent implements OnInit, OnDestroy {

  articlesView: Article[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jvsarticle: JvsarticleService,
    public jvsArtFilter: JvsarticlefilterService) {

    this.jvsArtFilter.articleOutput$.subscribe(output => {
      if(output) {
        this.articlesView = output;
        this.jvsArtFilter.restoreScrollPosition();
      }
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.jvsArtFilter.processProdTypQuery(params);
      this.jvsarticle.processProdTypQuery(params);
    });

    // Alternative way to capture scroll position on route change
    // this.router.events.subscribe((ev: any) => {
    //   if (ev instanceof NavigationStart) {
    //       console.log('Event', window.scrollY);
    //   }
    // });
  }

  ngOnDestroy() {
    this.jvsArtFilter.scrollPosition = window.scrollY;
  }
}
