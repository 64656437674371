import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { AuthService } from '@services/auth.service';


@Injectable()

// Auth guard check if endpoint is authorized. Needs to check more details if user has endpoint authorization.
// Details in app-routing needs to uncommented for this to work.

export class AuthGuardService implements CanActivate {
  private guardStatus = true;

  constructor(public auth: AuthService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.auth.isAuth.subscribe(isAuth => {

      this.guardStatus = true;

      if (!isAuth) {
        this.guardStatus = false;
        this.router.navigate(['/login'], {
          queryParams: {
            redirect: state.url
          }
        });
      }
    });

    return this.guardStatus;
  }
}
