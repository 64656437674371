
<mat-tab-group>
  <mat-tab label="New Contact">
    <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" style="padding:10px;">

      <div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" fxLayoutGap="10px">
        <mat-form-field floatLabel="always" fxFlex.lt-md="100%">
          <input matInput placeholder="Name"  [(ngModel)]="newContact.FtgPerson" name="FtgPerson">
        </mat-form-field>
        
        <mat-form-field floatLabel="always" fxFlex.lt-md="100%">
          <mat-select placeholder="Contact type" [(value)]="newComData">
            <mat-option *ngFor="let option of comDataOptions" [value]="option">
              {{option.ComBeskr}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="always" fxFlex.lt-md="100%">
          <input matInput placeholder="Value" [(ngModel)]="newComData.ComNr" name="ComNr">
        </mat-form-field>
        <button mat-raised-button color="accent" [disabled]="newComData.ComNr !== '' ? 'false':'true'" (click)="addComData()"> Add </button>
      </div>
      
      <div *ngFor="let ComData of newContact.ComData; let i=index" fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" fxLayoutGap="10px" >
          <mat-form-field floatLabel="always" fxFlex.lt-md="100%">
              <mat-select placeholder="Contact type" [(value)]="newContact.ComData[i].ComKod">
                <mat-option *ngFor="let option of comDataOptions" [value]="option.ComKod" >
                  {{option.ComBeskr}}
                </mat-option>
              </mat-select>
            </mat-form-field>
    
            <mat-form-field floatLabel="always" fxFlex.lt-md="100%">
              <input matInput placeholder="Value" [(ngModel)]="newContact.ComData[i].ComNr" name="ComNr">
            </mat-form-field>

            <i class="fa fa-trash" style="cursor:pointer;" (click)="removeComData(i)"></i>
      </div>

      <div fxFlex="100%">
        <button mat-raised-button color="accent" [disabled]="(newContact.FtgPerson !== '' && newContact.ComData.length ) ? 'false':'true'" (click)="postContact()"> Save </button>
      </div>

    </div>
  </mat-tab>


  <mat-tab label="Add details">
    <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" style="padding:10px;">
      <div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" fxLayoutGap="10px">
        <mat-form-field floatLabel="always" fxFlex.lt-md="100%">
          <mat-select placeholder="Contact" [(value)]="updateContact">
            <mat-option *ngFor="let option of contacts" [value]="option">
              {{option.FtgPerson}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="always" fxFlex.lt-md="100%">
          <mat-select placeholder="Contact type" [(value)]="newComData">
            <mat-option *ngFor="let option of comDataOptions" [value]="option">
              {{option.ComBeskr}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="always" fxFlex.lt-md="100%">
          <input matInput placeholder="Value" [(ngModel)]="newComData.ComNr" name="ComNr">
        </mat-form-field>
      </div>
      <div fxFlex="100%">
        <button mat-raised-button color="primary" (click)="putContact()"> Save </button>
      </div>

    </div>
  </mat-tab>

</mat-tab-group>