import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/user.service';
import { User } from '@classes/user';

@Component({
  selector: 'app-admin-validation',
  templateUrl: './admin-validation.component.html',
  styleUrls: ['./admin-validation.component.css']
})
export class AdminValidationComponent implements OnInit {

    isDone = false;

    // API request targets
    users: User[];

  constructor(private userService: UserService) { }

 ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.userService.getUser().subscribe( users => {
      this.users = users as User[];
      this.isDone = true;
    });
  }

}
