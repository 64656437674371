<mat-card fxLayout="row wrap">
  <div class="row">
    <h3>Change password</h3>
  </div>

  <div class="row">
    <mat-form-field class="full">
      <input matInput type="password" placeholder="Old password" value="" readonly>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="full">
      <input matInput type="password" placeholder="New password" value="" readonly>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="full">
      <input matInput type="password" placeholder="Validate new password" value="" readonly>
    </mat-form-field>
  </div>

  <div class="row">
    <button mat-raised-button color="primary">Save</button>
  </div>
  
</mat-card>
    