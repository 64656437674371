import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let jwt: string;

    if (localStorage.getItem(this.auth.getJwtId())) {
      jwt = localStorage.getItem(this.auth.getJwtId());
    } else {
      jwt = sessionStorage.getItem(this.auth.getJwtId());
    }

    if (jwt && !req.headers.has('Authorization')) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ` + jwt,
        }
      });
    }
    return next.handle(req);
  }
}
