import { Injectable } from '@angular/core';

import { DataService } from '@services/data.service';
import { MessageService } from '@services/message.service';

import { Contact, ComData, ContactFlat } from '@classes/customer';
import { UtilityService } from '@services/utility.service';


@Injectable({
  providedIn: 'root'
})
export class JvscontactService {

  endpointCustomer = '/jeeves/customers/{FtgNr}/contacts';
  endpointContact = '/jeeves/customers/{FtgNr}/contacts/{FtgKontaktNr}';

  constructor( private api: DataService, private message: MessageService ) { }

  getContact(FtgNr: string, FtgKontaktNr: number | string) {
    const endpoint = this.endpointContact.replace('{FtgNr}', FtgNr).replace('{FtgKontaktNr}', String(FtgKontaktNr));

    return this.api.getData(endpoint, []);
  }

  getContacts(FtgNr: string) {
    const endpoint = this.endpointCustomer.replace('{FtgNr}', FtgNr);

    return this.api.getData(endpoint, []);
  }

  updateContact(FtgNr: string, FtgKontaktNr: number | string, data: Contact) {

    const endpoint = this.endpointContact.replace('{FtgNr}', FtgNr).replace('{FtgKontaktNr}', String(FtgKontaktNr));

    return this.api.putData(endpoint, data, []);
  }

  newContact(FtgNr: string, data: Contact | ComData[]) {
    const endpoint = this.endpointCustomer.replace('{FtgNr}', FtgNr);

    return this.api.postData(endpoint, data, []);
  }

  newComData(FtgNr: string, FtgKontaktNr: number | string, data: Contact) {

    if (FtgKontaktNr === null) {
      FtgKontaktNr = '';
    }

    const endpoint = this.endpointContact.replace('{FtgNr}', FtgNr).replace('{FtgKontaktNr}', String(FtgKontaktNr));

    return this.api.postData(endpoint, data, []);
  }

  deleteContact(FtgNr: string, FtgKontaktNr: number) {

  }

  searchContact(FtgNr: string, search: string) {
    const endpoint = this.endpointCustomer.replace('{FtgNr}', FtgNr);
    const params = {params: {search}};

    return this.api.getData(endpoint, params);
  }

  flatList(contacts: Contact[]) {
    const result = Array();

    contacts.forEach((contact: Contact) => {
      const flat = {} as ContactFlat;
      flat.FtgPerson = contact.FtgPerson;
      flat.FtgKontaktNr = contact.FtgKontaktNr;

      contact.ComData.forEach((comdata: ComData) => {
        flat.ComKod = comdata.ComKod;
        flat.ComBeskr = comdata.ComBeskr;
        flat.ComNr = comdata.ComNr;

        result.push({... flat});
      });
    });

    return result;
  }

  unflatList(contacts: ContactFlat[]) {
    const utility = new UtilityService();

    const result = [];
    const map = utility.group(contacts, 'FtgKontaktNr');
    const values = Array.from(map.values());

    values.forEach((value) => {
      const storage: Contact = {
        ComData: []
      };

      value.forEach((contact) => {
        const comdata: ComData = {
          ComBeskr: contact.ComBeskr
          , ComKod: contact.ComKod
          , ComNr: contact.ComNr
        };

        if (contact.FtgKontaktNr !== null) {
          storage.FtgPerson = contact.FtgPerson;
        }
        storage.FtgKontaktNr = contact.FtgKontaktNr;
        storage.ComData.push(comdata);
      });

      result.push(storage);
    });

    return result;
  }

}
