import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { JvsporderService } from '@services/jvsporder.service';
import { Article } from '@app/classes/article';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-porder-rdlist',
  templateUrl: './porder-rdlist.component.html',
  styleUrls: ['./porder-rdlist.component.css']
})
export class PorderRdlistComponent implements OnInit {

  @Input() article: Article = null;
  @Input() articleState: BehaviorSubject<Article> = new BehaviorSubject(null);
  @Output() output: EventEmitter<Date> = new EventEmitter();

  deliveryDates: Set<string> = null;
  dateSelect: string;
  today: string = new Date().toISOString().slice(0, 10);
  isLoaded = false;

  constructor(private jvsPOrd: JvsporderService) { }

  ngOnInit() {
    this.isLoaded = false;
    this.dateSelect = this.today;

    if (this.article) {
      this.getData(this.article.PvmMasterItem);
    }

    this.articleState.subscribe(article => {
      if (article) {
        this.getData(article.ArtNr);
      }
    });
  }

  getData(search: string) {
    this.isLoaded = false;
    this.jvsPOrd.getArtOrd(search).subscribe(porder => {
      if (porder) {
        let newList = porder.map(item => item.BestBerLevDat);

        newList.push(this.today);
        newList = newList.sort();

        this.deliveryDates = new Set(newList);
      } else {
        this.deliveryDates = null;
      }

      this.isLoaded = true;
    },
    (error) => this.isLoaded = true);
  }

  emit(delivery: string) {
    const localDate = new Date(delivery);
    this.output.emit(localDate);
  }
}
