import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { Contact, ComData, ContactFlat } from '@classes/customer';
import { JvscontactService } from '@services/jvscontact.service';

@Component({
  selector: 'app-contact-table',
  templateUrl: './contact-table.component.html',
  styleUrls: ['./contact-table.component.css']
})
export class ContactTableComponent implements OnInit {

  @Input() input: Contact[];
  @Output() output: EventEmitter<Contact> = new EventEmitter<Contact>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public tableSource: MatTableDataSource<ContactFlat>;
  public displayedColumns: string[] = ['FtgKontaktNr', 'FtgPerson', 'ComBeskr', 'ComNr', 'select'];

  constructor(private dialog: MatDialog, private jvsContact: JvscontactService ) { }

  ngOnInit() {
      const flatContacts: Array<ContactFlat> = this.jvsContact.flatList(this.input);

      this.tableSource = new MatTableDataSource<ContactFlat>(flatContacts);
      this.tableSource.paginator = this.paginator;
      this.tableSource.sort = this.sort;
   }

   selectCustomer(contact: Contact) {
    this.output.emit(contact);
   }
}
