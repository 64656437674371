<mat-card fxLayout="row wrap">
  <div class="row">
    <h3>{{user.firstname}} {{user.lastname}}</h3>
  </div>

  <div class="row">
    <mat-form-field class="small">
      <input matInput placeholder="First name" value="{{user.firstname}}" readonly>
    </mat-form-field>

    <mat-form-field class="large">
      <input matInput placeholder="Last name" value="{{user.lastname}}" readonly>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="full">
      <input matInput placeholder="Username" value="{{user.username}}" readonly>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="full">
      <input matInput placeholder="E-mail" value="{{user.mail}}" readonly>
    </mat-form-field>
  </div>
  
</mat-card>
  