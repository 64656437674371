
<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      USER
    </mat-expansion-panel-header>

    <mat-list>
      <mat-list-item (click)="route('user')">My profile</mat-list-item>
      <mat-list-item (click)="logout()">Logout</mat-list-item>
    </mat-list>

  </mat-expansion-panel>
</mat-accordion>

<!-- <div class="dd-profile"><a routerLink="user">My profile</a></div>
<div class="dd-logout-link">Logout</div> -->