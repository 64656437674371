import { Component, Input, Output, EventEmitter, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent {
  @Input() template = 'order';
  @Output() output: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public dialogRef: MatDialogRef<TermsComponent>, @Inject(MAT_DIALOG_DATA) public MatData: {template: string}) {
    if (MatData) {
      this.template = this.MatData.template;
    }
  }

  accept() {
    this.output.emit(true);
    this.dialogRef.close();
  }
}
