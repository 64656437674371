import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';

import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-user-recover',
  templateUrl: './user-recover.component.html',
  styleUrls: ['./user-recover.component.css']
})
export class UserRecoverComponent implements OnInit {

  isLoading = true;
  validation = false;
  isFail = false;
  recoverySuccess = false;

  passwordForm: FormGroup;

  hash: string;

  error: {description: string};

  constructor( private auth: AuthService, private userService: UserService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.auth.isAuth.subscribe(isAuth => {
      if (isAuth) {
        this.router.navigate(['']);
      }
    });

    this.route.params.subscribe(params => {
      if (params.hash) {
        this.hash = params.hash;

        this.userService.recoverHash(params.hash).subscribe(
          () => this.init(),
          (error) => this.initError(error)
        );
      }
    });

    this.passwordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}')
      ]),
      confirm: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}')
      ])
    });
  }

  init() {
    this.validation = true;
    this.isLoading = false;
  }

  initError(error) {
    this.error = {
      description: error.description
    };

    this.isLoading = false;
  }

  submit() {

    if (this.passwordForm.valid) {
      const password: string = this.passwordForm.value.password;
      const confirm: string = this.passwordForm.value.confirm;

      // If passwords not matching then stop
      if (confirm !== password) {
        return;
      }

      this.passwordForm.disable();
      this.isLoading = true;

      this.userService.recoverSetPassword(this.hash, password).subscribe(
        () => this.submitSuccess(),
        (error) => this.submitError(error)
      );

    }

  }

  submitSuccess() {
    this.recoverySuccess = true;
    this.isLoading = false;
  }

  submitError(error) {
    this.validation = false;
    this.error = {
      description: error.description
    };

    this.isLoading = false;
  }
}
