
<div [class.drawer-visible] = "isOpen" class="drawer-container" (click)="drawerToggle()">

    <!-- Drawer content goes here -->
    <div [class.drawer-visible] = "isOpen" class="drawer" (click)="$event.stopPropagation()">
      <ng-container *ngIf="isOpen">
        
      <div style="width:auto;height:auto;padding:5px;">
        <app-search (output)="drawerToggle()"></app-search>
      </div>

      <mat-accordion>
        <ng-container *ngFor="let group of menues">
          <mat-expansion-panel *ngIf="!group.children[0].MenuIsLink">
            <mat-expansion-panel-header [class.isCampain]="group.MenuCampain">
              {{group.MenuDescr | uppercase}}
            </mat-expansion-panel-header>

            <ng-container *ngFor="let section of group.children">
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header>
                    <ng-container *ngIf="section.level2VrtDescr">{{section.level2VrtDescr | uppercase}}</ng-container>
                    <ng-container *ngIf="section.MenuDescr">{{section.MenuDescr | uppercase}}</ng-container>
                  </mat-expansion-panel-header>

                  <mat-list>
                    <mat-list-item
                      *ngFor="let category of section.children"
                      (click)="clickLink(section,category)"
                      >
                        
                        <ng-container *ngIf="category.level3VrtDescr">{{category.level3VrtDescr | titlecase}}</ng-container>
                        <ng-container *ngIf="category.MenuDescr">{{category.MenuDescr | uppercase}}</ng-container>
                    </mat-list-item>
                  </mat-list>

              </mat-expansion-panel>
            </ng-container>

          </mat-expansion-panel>
        </ng-container>

        <app-user-mobile (output)="drawerToggle()"></app-user-mobile>

        <app-settings-list></app-settings-list>

        <mat-list>
          <ng-container *ngFor="let menu of menues">
            <mat-list-item 
            *ngIf="menu.children[0].MenuIsLink"
            (click)="route(menu.children[0].MenuLink)">
              {{menu.MenuDescr | uppercase}}
            </mat-list-item>
          </ng-container>
        </mat-list>
      </mat-accordion>

    </ng-container>

    </div>

  </div>