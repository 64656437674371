<i class="fas fa-user fa-2x" style="cursor:pointer;color: rgb(39, 39, 39);" (click)="ddShow = !ddShow"></i>
<div *ngIf="isDone" class="dd-container" fxLayout="row wrap" [class.ddVisible]="ddShow" (mouseleave)="ddShow = false">
  <div class="dd-name" fxFlex="100%" fxLayout="row wrap">
    <mat-form-field floatLabel="always">
      <input matInput type="text" placeholder="Name" [value]="activeuser.firstname + ' ' + activeuser.lastname" readonly>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <input matInput type="text" placeholder="User" [value]="activeuser.username" readonly>
      </mat-form-field>
  </div>

  <div class="dd-profile"><a routerLink="user">My profile</a></div>

  <div class="dd-logout-container">
    <div class="dd-logout-link">Logout</div>

    <div class="dd-logout-options">
      <mat-radio-group [(ngModel)]="logoutAll">
        <mat-radio-button class="dd-option" [value]="false">This device</mat-radio-button>
        <mat-radio-button class="dd-option" [value]="true">All devices</mat-radio-button>
      </mat-radio-group>
    </div>
    <button mat-raised-button color="primary" (click)="logout()"> Logout </button>
  </div>
</div>