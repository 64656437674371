import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DataService } from '@services/data.service';

import { Article } from '@classes/article';
import { MenuItems } from '@classes/menu';
import { ProdTyp } from '@classes/filter';

@Injectable({
  providedIn: 'root'
})
export class JvsarticlefilterService {

  endpointFilters = '/jeeves/articles/filters';

  // Output subscriptions
  articleOutput$: BehaviorSubject<Article[]> = new BehaviorSubject(null);
  filterOutput$: BehaviorSubject<ProdTyp[]> = new BehaviorSubject(null);
  isActive$: BehaviorSubject<number> = new BehaviorSubject(0);
  scrollPosition: number = 0;
  

  // Original source data
  sourceData: Article[] = null;
  sourceFilters: ProdTyp[] = null;

  // Somthing is wrong, should not be neccesary
  originalFilters: ProdTyp[];

  // Index list for faster filtering
  filterAllow: Array<string> = Array();
  filterFormAllow: Array<string> = Array();

  // List of active filters
  filterList: Array<Filter> = Array();

  // Active url, re-use requests and filter settings
  articleListUrl: string = null;

  constructor(private api: DataService) { }

  // Filter form control
  setFilter(group, option) {
    const filter: Filter = {
      ProdTypAttrId: group.ProdTypAttrId,
      ProdTypVrtId: option.ProdTypVrtId,
      ProdTypVrtDescr: option.ProdTypVrtDescr,
      State: option.State,
      items: option.children
    };

    if (option.State) {
      this.addFilter(filter);
    } else {
      this.removeFilter(filter);
    }
  }

  // Add filter changes to filterList
  addFilter(filter: Filter) {
    this.filterList.push(filter);
    this.applyFilter(filter);
  }


  // Deactivates the filter
  removeFilter(filter: Filter) {
    this.filterList.forEach((listitem, index) => {
      if (listitem.ProdTypAttrId === filter.ProdTypAttrId && listitem.ProdTypVrtId === filter.ProdTypVrtId) {
        this.filterList.splice(index, 1);
      }
    });

    this.applyFilter();
  }

  // Resets all filters
  resetFilterForm() {
    this.sourceFilters = JSON.parse(JSON.stringify(this.originalFilters));
    this.filterOutput$.next(this.sourceFilters);
    this.filterList = Array();
    this.applyFilter();
  }


  // Activates filter list
  applyFilter(filter: Filter = null) {

    let filterList = Array();
    let articleList: Article[] = Array();
    this.scrollPosition = 0;

    // Applies current filters or adds new filter to list
    if (!filter) {
      filterList = this.filterList;
      articleList = this.sourceData;
    } else {
      filterList = Array(filter);
      articleList = this.articleOutput$.getValue();
    }

    filterList.forEach((row) => {
      this.filterAllow = Array();

      row.items.forEach((item) => {
        this.filterAllow[item.ArtNr] = item.ArtNr;
      });

      articleList = this.filterArticles(articleList);
    });

    // Updates the filter form with avalible filters after filtering
    if (articleList.length < 1 || this.filterList.length < 1) {
      this.articleOutput$.next(this.sourceData);
      this.filterFilterList();
    } else {
      this.articleOutput$.next(articleList);
      this.filterFilterList();
    }

    this.isActive$.next(this.filterList.length);
  }



  // Filter the article list
  filterArticles(articleInput: Article[]) {
    const articleOutput: Article[] = Array();

    articleInput.forEach((article: Article) => {
      if (typeof this.filterAllow[article.ArtNr] !== 'undefined') {
        articleOutput.push(article);
      }
    });

    return articleOutput;
  }

  // Update the forms
  setFormAllow(filters: ProdTyp[]) {
    filters.forEach(filter => {
      filter.children.forEach(option => {
        option.children.forEach(ArtNr => {
          if (!Array.isArray(this.filterFormAllow[ArtNr.ArtNr])) {
            this.filterFormAllow[ArtNr.ArtNr] = Array();
          }
          this.filterFormAllow[ArtNr.ArtNr].push({ProdTypAttrId: filter.ProdTypAttrId, ProdTypVrtId: option.ProdTypVrtId});
        });
      });
    });
  }

  // Main work function that runs after the bord is set.
  filterFilterList() {
    const filters = [...this.sourceFilters];

    filters.forEach(filter => {
      filter.children.forEach(option => {
        filter.enabled = false;
        option.enabled = false;
      });
    });

    this.articleOutput$.getValue().forEach(article => {
      this.filterFormAllow[article.ArtNr].forEach(filterAllow => {
        filters.forEach(filter => {
          let child = false;

          filter.children.forEach(option => {
            if (filter.ProdTypAttrId === filterAllow.ProdTypAttrId && option.ProdTypVrtId === filterAllow.ProdTypVrtId) {
              option.enabled = true;
              child = true;
            }
          });

          if (child) {
            filter.enabled = true;
          }

        });
      });
    });


    this.filterOutput$.next(filters);
  }


  // Sets original source articel data
  setSource(articles: Article[]) {
    this.sourceData = articles;
    this.scrollPosition = 0;
    this.articleOutput$.next(articles);
  }

  // Checks current filters correspond to active url (Remeber settings after back button from article view)
  processProdTypQuery(query: MenuItems) {
    if (this.queryActive(query, this.articleListUrl)) {
      this.filterList = Array();
      this.getProdTypFilter(query);
    } 
    // else {
    //   this.applyFilter();
    // }
  }

  // Is url query active or not
  queryActive($newQuery: any, $oldQuery: string) {

    if (JSON.stringify($newQuery) !== $oldQuery) {
      this.articleListUrl = JSON.stringify($newQuery);
      return true;

    } else {
      return false;
    }
  }


  // Request avalible filters for active url query
  getProdTypFilter(query) {
    const params = {params: query};

    this.getProdTypFilterReq(params).subscribe((filters: ProdTyp[]) => {

      filters.forEach(filter => {
        filter.children.forEach(option => {
          filter.enabled = true;
          option.enabled = true;
        });
      });

      this.sourceFilters = filters;
      this.originalFilters = JSON.parse(JSON.stringify(filters));

      this.setFormAllow(filters);
      this.filterOutput$.next(filters);
    });
  }

  // Request Filter observable
  getProdTypFilterReq(params) {
    const endpoint = this.endpointFilters;
    return this.api.getData(endpoint, params);
  }

  restoreScrollPosition() {
    window.setTimeout(() => {
      window.scrollTo({behavior: 'smooth',top:this.scrollPosition, left:0});
    }, 200);
  }
}

export interface Filter {
  ProdTypAttrId: number;
  ProdTypVrtId: number;
  ProdTypVrtDescr: string;
  State: boolean;
  items: Array<Article>;
}

