<div class="container" fxLayout="row nowrap" fxLayoutAlign="center flex-start">
  <div *ngIf="!isLoading" fxFlex="50%" fxFlex.lt-md="100%" fxLayout="row wrap" class="content" fxLayoutAlign="center flex-start">

    <div fxFlex="100%" class="brand">
      <img src="assets/img/logo_xsmall_inv.png" alt="Brand Logo">
    </div>

    <ng-container *ngIf="validation">

      <div fxFlex="100%" class="header">
        <h3>Password reset form</h3>
      </div>

      <div  fxFlex="100%" class="info">

        <form [formGroup]="passwordForm" (ngSubmit)="submit()" fxLayout="row wrap" fxLayoutAlign="center flex-start">
          <mat-form-field fxFlex="100%" class="password-input" floatLabel="always">
              <input matInput type="password" placeholder="New password" formControlName="password">
              <mat-error *ngIf="passwordForm.get('password').hasError('pattern') && !passwordForm.get('password').hasError('required')">
                Minimum 8 characters with a small and big letter, number and a special character
              </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="100%" class="password-input" floatLabel="always">
            <input matInput type="password" placeholder="Confirm new password" formControlName="confirm">
            <mat-error *ngIf="passwordForm.value.password != passwordForm.value.confirm && passwordForm.value.password">
              Passwords dont match
            </mat-error>
          </mat-form-field>

          <button *ngIf="!recoverySuccess" fxFlex="100%" type="submit" class="password-button" mat-raised-button color="primary">Save</button>

        </form>

      </div>

      <div *ngIf="recoverySuccess" fxFlex="100%"> 
        <h3>Your password has been updated</h3>
      </div>

      <button *ngIf="recoverySuccess" fxFlex="100%" class="password-button" routerLink="" mat-raised-button color="primary">Continue to login</button>

    </ng-container>


    <ng-container *ngIf="!validation">

      <div fxFlex="100%" class="header">
        <h3>Validation failed with below error</h3>
      </div>
      <div fxFlex="300px" fxFlex.lt-md="100%" class="error-message">
          {{error.description}}
      </div>

    </ng-container>

  </div>


  <div *ngIf="isLoading" fxFlex="50%" fxFlex.lt-md="100%" fxLayout="row wrap" class="content">

      <div fxFlex="100%" class="brand">
        <img src="assets/img/logo_xsmall_inv.png" alt="Brand Logo">
      </div>
    
      <div fxFlex="100%" class="spinner" fxLayoutAlign="center">
        <mat-spinner></mat-spinner>
      </div>
    </div>
</div>

