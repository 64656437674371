<div class="container">
<div class="inputs" *ngIf="!submitted">
  <form class="input-form" fxLayout="row wrap" fxLayoutAlign="flex-start" (ngSubmit)="recover()">

    <mat-form-field class="full-width" floatLabel="always" >
      <input matInput placeholder="Username" [(ngModel)]="username" [ngModelOptions]="{standalone: true}">
    </mat-form-field>

    <button mat-raised-button color="primary" type="submit" [disabled]="isLoading">Submit</button>
  </form>
</div>

<div *ngIf="submitted">
  <h3>A mail has been sent to your registered address with instructions on how to reset your password</h3>
</div>
</div>