<div fxLayout="row nowrap" fxHide.lt-md >
    <div fxFlex="20%">
        <app-articles-filters-active></app-articles-filters-active>
        <app-articles-filters></app-articles-filters>
    </div>
    <div fxFlex="80%">
        <app-articles-list-cards [articles]="articlesView"></app-articles-list-cards>
    </div>
</div>

<div fxLayout="column nowrap" fxHide fxShow.lt-md>
    <div>
        <mat-accordion>
            <mat-expansion-panel>
        
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Filters
                    </mat-panel-title>
                </mat-expansion-panel-header>
            
                <app-articles-filters></app-articles-filters>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div>
        <app-articles-filters-active></app-articles-filters-active>
        <app-articles-list-cards [articles]="articlesView"></app-articles-list-cards>        
    </div>
</div>