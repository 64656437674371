import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { environment } from '@env/environment';
import { Cart, CartItem } from '@classes/cart';
import { CartService } from '@services/cart.service';

@Component({
  selector: 'app-cartitem-table',
  templateUrl: './cartitem-table.component.html',
  styleUrls: ['./cartitem-table.component.css']
})
export class CartitemTableComponent implements OnInit {

  public env = environment;

  public isDone = false;
  public showComp = false;
  public showCartdd = false;
  public cartCheckout: Cart;
  public showImages = true;
  public showDateSelect = false;

  public updateItemQty: number[] = [];
  public showSave = false;
  public disableSave = false;
  public notValid: any = [];

  public cartLoading = false;

  public tableSourceDelivery: Array<{delivery: string, items: MatTableDataSource<CartItem>}> = Array();

  public displayedColumns: string[] = Array(
    'imageUrl', 'ArtNr', 'ArtBeskr', 'PVMVariantDescr', 'PVMVariantDescr2', 'Pris', 'Rabatt', 'itemqty', 'Remove');

  constructor(private cart: CartService) { }

  ngOnInit() {
    this.cart.cart$.subscribe(cart => {
      if (cart && cart.cartid) {
        this.cartCheckout = cart as Cart;
        this.loadCart();
      }
    });

    this.cart.cartWorking$.subscribe(isLoading => this.cartLoading = isLoading);
  }

  loadCart(): void {
    if (this.cartCheckout.items) {

      // 2 leve cart view
      // Get unique delivery dates and sort array
      const tempCart = new Set(this.cartCheckout.items.map(item => {
        if (!item.deliverydat) {
          return new Date().toISOString().slice(0, 10);
        } else {
          return item.deliverydat;
        }
      }).sort());

      // Reset current delivery
      this.tableSourceDelivery = Array();

      // Populate each delivery date with mattable source
      tempCart.forEach((date) => {
        this.tableSourceDelivery.push({
          delivery: date,
          items: new MatTableDataSource<CartItem>(this.cartCheckout.items.filter(item => {

            // Null handling of 'date', counts null as today
            if (item.deliverydat === date) {
              return item;
            }

            if (!item.deliverydat && date === new Date().toISOString().slice(0, 10)) {
              return item;
            }
          }))
        });
      });
      this.isDone = true;
    } else {
      this.isDone = false;
    }
  }

  cartItemQty(item: CartItem, qty: number) {
    this.cart.updateItem(item.itemid, 'itemqty', qty);
  }

  cartItemRemove(item: CartItem) {
    this.cart.deleteItem(item.itemid);
  }

  cartItemDelivery(item: CartItem, delivery: Date) {
    this.cart.updateItem(item.itemid, 'deliverydat', delivery.toISOString().slice(0, 10));
  }

  toggleImages() {

    if (this.showImages) {
      this.displayedColumns.splice(0, 1);
    } else {
      this.displayedColumns.unshift('imageUrl');
    }

    this.showImages = !this.showImages;
  }

}
