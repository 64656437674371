import { Injectable } from '@angular/core';
import { DataService } from '@services/data.service';
import { Observable } from 'rxjs';
import { POrderRader } from '@classes/porder';

@Injectable({
  providedIn: 'root'
})
export class JvsporderService {

  endpointPOrders = '/jeeves/porders/{BestNr}';
  endpointPOrdersArt = '/jeeves/porders/article/{ArtNr}';

  constructor(private api: DataService) {

  }

  getArtOrd(artnr: string) {
    const endpoint = this.endpointPOrdersArt.replace('{ArtNr}', artnr);

    return this.api.getData(endpoint, []) as Observable<POrderRader[]>;
  }
}
