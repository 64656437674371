  <mat-horizontal-stepper [linear]="true" #stepper>

    <mat-step label="Ordered items" [editable]="!stepperComplete">
        <mat-tab-group>

          <mat-tab label="Active cart">
              <div class="tab-container">
                
                <ng-container *ngIf="!isEmpty">
                    <app-cartitem-table></app-cartitem-table>
                    <div fxLayout="row nowrap" fxLayoutAlign="flex-end flex-start">
                      <div fxFlex.lt-md="100%" fxFlex.gt-sm="600px">
                        <app-cartitem-footer></app-cartitem-footer>

                        <div class="no-credit" *ngIf="!creditValidity" fxLayout="row nowrap" fxLayoutAlign="center center">
                          <span>Order cannot be proceccsed due to credit settings. Please contact Bagheera AB for further information.</span>
                        </div>

                        <div style="margin-top:20px;" fxLayout="row nowrap" fxLayoutAlign="flex-end center">
                            <button mat-raised-button color="primary" matStepperNext [disabled]="isEmpty || !creditValidity">Delivery options</button>
                        </div>

                      </div>
                    </div>
                </ng-container>

                <app-cartitem-empty *ngIf="isEmpty"></app-cartitem-empty>

              </div>
            </mat-tab>

            <mat-tab label="Manage Carts">
          
              <div class="tab-container">
                <app-cartoperations></app-cartoperations>
              </div>
              <div class="tab-container">
                <app-cartlist-table></app-cartlist-table>
              </div>
  
            </mat-tab>

        </mat-tab-group>    
    </mat-step>

    <mat-step *ngIf="creditValidity" label="Delivery options" [editable]="!stepperComplete">

          <app-carthead-table (output)="checkConfirm($event)" [customer$]="customers$"></app-carthead-table>
          
          <div fxFlexLayout="row" fxLayoutAlign="space-around">
            <button mat-raised-button color="primary" matStepperPrevious>Back</button>
            <button mat-raised-button color="primary" [disabled]="!termsConfirmed" (click)="confirm(stepper)">Finish</button>
          </div>
          
    </mat-step>
    
    <mat-step *ngIf="creditValidity" label="Done">

        <app-cart-done *ngIf="isDone" [result]="result" [cart]="activeCart"></app-cart-done>

    </mat-step>
  </mat-horizontal-stepper>