<ng-container *ngIf="isDone">
  <div class="container" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
    <mat-card fxFlex.lt-md="100%" fxFlex.gt-sm="49%">
      <div fxLayout="row wrap" fxLayoutAlign="space-between">
        <h3>Delivery options</h3>
        <mat-form-field>
          <mat-select [(ngModel)]="forms.FtgNr" name="FtgNr" placeholder="Delivery site" (ngModelChange)="processInput('levplats')">
            <mat-option *ngFor="let LevPlats of deliverySites" [value]="LevPlats.FtgNr">
                {{LevPlats.FtgNr}} - {{LevPlats.ftgnamn}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row wrap">
        <div fxLayout="column">
          <mat-form-field>
            <input matInput placeholder="Name" [readonly]="true" [value]="view.customer.ftgnamn" readonly>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Address" [readonly]="true" [value]="view.customer.FtgPostadr5" readonly>
          </mat-form-field>
          <div>
            <mat-form-field>
              <input matInput placeholder="Zip code" [readonly]="true" [value]="view.customer.FtgLevPostNr" readonly>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="City" [readonly]="true" [value]="view.customer.FtgPostLevAdr3" readonly>
            </mat-form-field>
          </div>
          <mat-form-field>
            <input matInput placeholder="Country" [readonly]="true" [value]="view.customer.land" readonly>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column">
      <form class="mail-form">
        <mat-form-field class="mail-input">
          <input matInput [(ngModel)]="forms.mail" [ngModelOptions]="{ updateOn: 'blur' }" (ngModelChange)="processInput('mail')" name="mail" placeholder="Order mail confirmation">
        </mat-form-field>
      </form>
      <mat-form-field>
        <textarea matInput [(ngModel)]="forms.comment" [ngModelOptions]="{ updateOn: 'blur' }" (ngModelChange)="processInput('comment')" name="comment" placeholder="Order Comment" class="textarea-wide" rows="2">
        </textarea>
      </mat-form-field>
      </div>

    </mat-card>
  
    <mat-card fxFlex.lt-md="100%" fxFlex.gt-sm="49%">
      <div fxLayout="row wrap" fxLayoutAlign="space-between">
        <h3>Delivery terms</h3>
      </div>
      <div fxLayout="row wrap">
        <mat-form-field>
          <input matInput placeholder="Payment" [readonly]="true" [value]="customer.betEO" readonly>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Freight terms" [readonly]="true" [value]="customer.LevVillkKodValue" readonly>
        </mat-form-field>
      </div>
      <div fxLayout="row wrap">
        <mat-form-field>
          <input matInput placeholder="Expected Delivery" [readonly]="true" [value]="customer.betEO" readonly>
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <h3>Invoice</h3>
        <mat-form-field>
          <input matInput placeholder="Name" [readonly]="true" [value]="customer.ftgnamn" readonly>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Address" [readonly]="true" [value]="customer.ftgpostadr2" readonly>
        </mat-form-field>
        <div>
          <mat-form-field>
            <input matInput placeholder="Zip code" [readonly]="true" [value]="customer.ftgpostnr" readonly>
          </mat-form-field>
          <mat-form-field>
              <input matInput placeholder="City" [readonly]="true" [value]="customer.ftgpostadr3" readonly>
          </mat-form-field>
        </div>
        <mat-form-field>
          <input matInput placeholder="Country" [readonly]="true" [value]="customer.land" readonly>
        </mat-form-field>
        <div fxFlex="100%" fxLayout="row nowrap" fxLayoutGap="5px" fxLayoutAlign="flex-start flex-start">
          <div><mat-checkbox [(ngModel)]="forms.confirm" (ngModelChange)="confirmTerms()"></mat-checkbox></div>
          <div>In order to complete your order you must read and confirm our<a (click)="openDialog()" style="color:#3f51b5;font-weight: bold"> general terms of sale</a></div>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
