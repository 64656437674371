import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

@Injectable()
export class DataService {

  private apiUrl = environment.apiUrl;
  public requestError: BehaviorSubject<HttpErrorResponse> = new BehaviorSubject(null);

  constructor(private http: HttpClient) { }

  // !!!!!!!!!!!!!!!!!!!!! REBUILD DATA SERVICE LIKE THIS FOR BETTER ERROR MANAGEMENT !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // getData2(ep:string,options:object):BehaviorSubject<any> {
  //   const data:BehaviorSubject<any> = new BehaviorSubject(false as any);

  //   this.http.get(this.apiUrl+ep,options).subscribe(
  //     response=> data.next(response)
  //     ,err => console.log(err)
  //   );

  //   return data;
  // }

  getData(ep: string, options: object) {

    const defaultOptions = {
      withCredentials: true
    };

    Object.assign(defaultOptions, options);

    return this.http.get(this.apiUrl + ep, defaultOptions).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  postData(ep: string, body: object, options: object) {

    const defaultOptions = {
      withCredentials: true
    };

    Object.assign(defaultOptions, options);

    return this.http.post(this.apiUrl + ep, body, defaultOptions).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  putData(ep: string, body: object, options: object) {
    const defaultOptions = {
      withCredentials: true
    };

    Object.assign(defaultOptions, options);

    return this.http.put(this.apiUrl + ep, body, defaultOptions).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  deleteData(ep: string, options: object) {
    const defaultOptions = {
      withCredentials: true
    };

    Object.assign(defaultOptions, options);

    return this.http.delete(this.apiUrl + ep, defaultOptions).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  handleError(error: HttpErrorResponse) {

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(error.error);
    }
    // return an observable with a user-facing error message
    // return throwError(
    //   'Something bad happened; please try again later.');

    this.requestError.next(error);

    return throwError(error.error);
  }
}
