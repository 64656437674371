import { Component, OnInit } from '@angular/core';
import { ExportService } from '@app/services/export.service';

@Component({
  selector: 'app-article-export-icon',
  templateUrl: './article-export-icon.component.html',
  styleUrls: ['./article-export-icon.component.css']
})
export class ArticleExportIconComponent implements OnInit {

  badge: number = null;

  constructor(private exportService: ExportService) { }

  ngOnInit() {
    this.exportService.exportList$.subscribe(exportList => {
      const exportItem = exportList.get('articleExport');

      if (exportItem) {
        this.badge = exportItem.count;
      } else {
        this.badge = null;
      }
    });
  }

}
