import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UserService } from '@services/user.service';
import { JvscontactService } from '@services/jvscontact.service';
import { JvscustomerService } from '@services/jvscustomer.service';
import { MessageService } from '@app/services/message.service';

import { User } from '@classes/user';
import { Customer, Contact, ComData } from '@classes/customer';
import { CustomerSearchDialogComponent } from '@components/dialog/customer-search-dialog/customer-search-dialog.component';
import { ContactSearchDialogComponent } from '@components/dialog/contact-search-dialog/contact-search-dialog.component';


export interface PanelView {
  state: boolean;
  data: Customer | User | Contact | Contact[];
}

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.css']
})
export class AdminUserComponent implements OnInit {
  // usersView is rendered array
  users: User[];
  usersDone = false;

  userSelected: User;
  customerSelected: Customer;
  contactSelected: Contact;

  // Active customer filter
  filterCustomer: Customer;

  constructor(
      private jvscontact: JvscontactService,
      private userService: UserService,
      private jvscustomer: JvscustomerService,
      private dialog: MatDialog,
      private msgService: MessageService
      ) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.usersDone = false;

    this.userService.getUser().subscribe((users: User[]) => {
        this.users = users;
        this.usersDone = true;
      }
    );
  }

  filterUsers() {
    if(this.filterCustomer) {
      return this.users.filter(user => user.jvscompany === this.filterCustomer.FtgNr);
    }

    return this.users;
  }

    // Dialogs
  customerDialog(user: User, filter = false): void {

    const dialogRef = this.dialog.open(CustomerSearchDialogComponent, {
      height: '85%',
      width: '85%'
    });

    const sub = dialogRef.componentInstance.output.subscribe((customer: Customer) => {
      if(filter) {
        this.filterCustomer = customer;
      } else {
        user.jvscompany = customer.FtgNr;
        this.saveUser(user);
      }

      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  contactDialog(user: User): void {

    this.jvscontact.getContacts(user.jvscompany).subscribe(contacts => {
      const dialogRef = this.dialog.open(ContactSearchDialogComponent, {
        height: '85%',
        width: '85%',
        data: contacts
      });

      const sub = dialogRef.componentInstance.output.subscribe((contact: Contact) => {
        user.jvscontact = contact.FtgKontaktNr
        this.saveUser(user);

        dialogRef.close();
      });

      dialogRef.afterClosed().subscribe(() => {
        sub.unsubscribe();
      });
    });
  }



  // Panel operations
  loadUser(user: User) {
    this.userSelected = user;
    this.customerSelected = null;
    this.contactSelected = null;

    if(user.jvscompany) {
      this.jvscustomer.getCustomer(user.jvscompany).subscribe(customer => {
        if(customer) {
          this.customerSelected = customer;
        } else {
          this.customerSelected = null;
        }
      })
    }

    if(user.jvscontact != null && user.jvscompany != null) {
      this.jvscontact.getContact(user.jvscompany, user.jvscontact).subscribe(contact => {
        if(contact) {
          this.contactSelected = contact;
        } else {
          this.contactSelected = null;
        }
      })
    }
  }


  // Write changes to API
  saveUser(user: User) {
    this.userService.updateUser(user).subscribe(res => {
      this.loadUser(user);

      this.msgService.send({
        type: 'snackbar'
        , message: 'User updated'
        , source: 'User service'
        , duration: 2000
      });
    });
  }

}
