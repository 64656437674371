import { Injectable } from '@angular/core';
import { Message } from '@classes/message';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class MessageService {

  public dialogRef;

  constructor(public snackBar: MatSnackBar) {

  }

  public send(message: Message) {

    switch (message.type) {
      case 'snackbar': {
        this.snackbar(message);
        break;
      }
    }
  }

  private snackbar(message: Message) {
    this.snackBar.open(message.source, message.message, {
      duration: message.duration,
    });
  }
}
