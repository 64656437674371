<div *ngIf="invoice" class="mob-container" fxLayout="row wrap" fxHide fxShow.lt-md>
    <mat-card *ngFor="let row of response.InvoiceRows" class="mob-card" fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
      <div class="mob-header" fxFlex="100%">
        <a *ngIf="row.Artikel.PvmMasterItem != 'FR1'" routerLink="/articles/{{row.Artikel.PvmMasterItem}}">
          {{row.Artikel.PvmMasterItem}} {{row.Artikel.ArtBeskr}} {{row.Artikel.PVMVariantDescr}} {{row.Artikel.PVMVariantDescr2}}
        </a>
      </div>
      <div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div fxFlex="105px">
          <div class="mob-image"> <img src="{{env.arImg300 + row.Artikel.imageUrl}}" alt="Item image"> </div>
        </div>
  
        <div fxFlex="60%" fxLayout="column" fxLayoutAlign="flex-start flex-end">
  
          <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
            <div>Article nb</div>
            <div><a *ngIf="row.Artikel.PvmMasterItem != 'FR1'" routerLink="/articles/{{row.Artikel.PvmMasterItem}}">{{row.Artikel.PvmMasterItem}}</a></div>
          </div>

          <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
            <div>Color code</div>
            <div>{{row.Artikel.PVMVariant1}}</div>
          </div>
  
          <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
            <div>Price</div>
            <div>{{row.vb_pris | currency:'SEK':'symbol-narrow'}}</div>
          </div>
  
          <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
            <div>Discount</div>
            <div>{{row.FaktRadRab}}%</div>
          </div>
  
          <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
            <div>Qty</div>
            <div>{{row.FaktLevAnt}}</div>
          </div>
  
          <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
            <div>Delivery</div>
            <div>{{row.OrdLevDat | date:'shortDate'}}</div>
          </div>
  
          <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
            <div>Sum</div>
            <div>{{row.vb_FaktRadSumma | currency:'SEK':'symbol-narrow'}}</div>
          </div>
  
        </div>
      </div>
    </mat-card>
  </div>



<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2" *ngIf="invoice" fxHide.lt-md>

    <ng-container matColumnDef="OrdRadnr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Order row no. </th>
      <td mat-cell *matCellDef="let element"> {{element.OrdRadNr}}</td>
    </ng-container>
  
    <ng-container matColumnDef="OrdLevDat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery date </th>
      <td mat-cell *matCellDef="let element"> {{element.OrdLevDat | date:'shortDate'}} </td>
    </ng-container>
  
    <ng-container matColumnDef="PvmMasterItem">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Item no. </th>
      <td mat-cell *matCellDef="let element"> <a *ngIf="element.Artikel.PvmMasterItem != 'FR1'" routerLink="/articles/{{element.Artikel.PvmMasterItem}}">{{element.Artikel.PvmMasterItem}}</a> </td>
    </ng-container>
  
    <ng-container matColumnDef="ArtBeskr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.Artikel.ArtBeskr}} </td>
    </ng-container>
  
    <ng-container matColumnDef="PVMVariantDescr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
      <td mat-cell *matCellDef="let element"> {{element.Artikel.PVMVariantDescr}} </td>
    </ng-container>
  
    <ng-container matColumnDef="PVMVariantDescr2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
        <td mat-cell *matCellDef="let element"> {{element.Artikel.PVMVariantDescr2}} </td>
      </ng-container>
    
    <ng-container matColumnDef="FaktLevAnt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity </th>
      <td mat-cell *matCellDef="let element"> {{element.FaktLevAnt}} </td>
    </ng-container>
  
    <ng-container matColumnDef="vb_pris">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
      <td mat-cell *matCellDef="let element"> {{element.vb_pris | currency:element.ValKod:'symbol-narrow'}} </td>
    </ng-container>
  
    <ng-container matColumnDef="FaktRadRab">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Discount % </th>
      <td mat-cell *matCellDef="let element"> {{element.FaktRadRab}} </td>
    </ng-container>
  
    <ng-container matColumnDef="vb_FaktRadSumma">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Row sum </th>
      <td mat-cell *matCellDef="let element"> {{element.vb_FaktRadSumma | currency:element.ValKod:'symbol-narrow'}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  