import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DataService } from '@services/data.service';
import { Cart, CartItem } from '@classes/cart';
import { MessageService } from '@services/message.service';
import { UserService } from '@services/user.service';

@Injectable({
  providedIn: 'root'
})

export class CartService {
  public endpointCart = '/app/cart/{cartid}';
  public endpointCartItem = '/app/cart/{cartid}/item/{itemid}';
  public endpointCartOp = '/app/cart/{cartid}/{operation}';

  public defaultMeta: {comment: string, levplats: string, wEmailAddress: string} = {comment: '', levplats: '', wEmailAddress: ''};

  public deliveryDate$: BehaviorSubject<Date>;

  public cart$: BehaviorSubject<Cart>;
  public cartlist$: BehaviorSubject<Cart[]>;
  public cartactive$: BehaviorSubject<number>;
  public cartClose$: BehaviorSubject<boolean>;

  public cartSum$: BehaviorSubject<number>;
  public cartQty$: BehaviorSubject<number>;
  public cartVat$: BehaviorSubject<number>;
  public cartWorking$: BehaviorSubject<boolean>;

  constructor(private api: DataService, private message: MessageService, private user: UserService) {

    this.cartSum$ = new BehaviorSubject(0);
    this.cartQty$ = new BehaviorSubject(0);
    this.cartVat$ = new BehaviorSubject(0);
    this.cart$ = new BehaviorSubject(null);
    this.cartlist$ = new BehaviorSubject([]);
    this.cartactive$ = new BehaviorSubject(null);
    this.cartClose$ = new BehaviorSubject(false);
    this.cartWorking$ = new BehaviorSubject(false);

  }

  init() {
    // this.deliveryDate$ = new BehaviorSubject(new Date(new Date().toISOString().slice(0, 10)));
    this.deliveryDate$ = new BehaviorSubject(null);

    this.cartlist$.subscribe(list => {
      this.processCarts(list);
    });

    this.cart$.subscribe(cart => {
      this.processCart(cart);
    });

    this.cartactive$.subscribe(id => {
        this.processCartActive(id);
    });

    this.cartClose$.subscribe(close => {
      if (close) {
      }
    });


    this.getCarts();

    this.user.user$.subscribe(user => {
      // Only run on init, not if user 'cartactive' is updated later
        this.getCart(user.cartactive);
    });

  }


  processCarts(list: Cart[]) {
    if (list) {
      // console.log('Cart2 list processed', list);
    }
  }


  processCart(cart: Cart) {
    // console.log('Cart2 processed', cart);
    this.calculateCart(cart);
  }

  processCartActive(id: number) {
    if (id) {
      // console.log('Cart2, activated:',id);
    }
  }

  setActive(id: number) {
    // console.log('Cart2 Set user active cart');
    this.cartactive$.next(id);
  }


  // Cart API operations
  getCarts() {
    this.getCartsReq().subscribe(
      (list: Cart[]) => {
        this.cartlist$.next(list);
    });
  }

  getCart(id: number) {
    if (id) {
      this.getCartReq(id).subscribe((cart: Cart) => {
        if (cart) {
          cart = this.checkMeta(cart);
          this.cartactive$.next(id);
          this.cart$.next(cart);
        }
      });
    }
  }

  updateCart(payload: any, cartid: number = null) {

    this.updateCartReq(payload).subscribe((cart: Cart) => {
      cart = this.checkMeta(cart);
      this.cart$.next(cart);
      this.getCarts();
    });
  }

  deleteCart(id: number) {
    if (id === this.cartactive$.getValue()) {
      this.message.send({
        type: 'snackbar'
        , message: 'Delete failed, cart is ACTIVE'
        , source: 'Cart'
        , duration: 2000
      });

    } else {

      this.cartWorking$.next(true);

      this.deleteCartReq(id).subscribe((cartlist: Cart[]) => {
        this.getCarts();

        this.cartWorking$.next(false);

        this.message.send({
          type: 'snackbar'
          , message: 'Cart deleted successfully'
          , source: 'Cart'
          , duration: 1000
        });
      },
      (error) => {
        this.cartWorking$.next(false);
      });
    }
  }


  // Cart API item operations
  addItem(items: CartItem[]) {
    this.cartWorking$.next(true);

    if (items && items.length > 0) {
      this.postItemReq(items).subscribe((cart: Cart) => {
        cart = this.checkMeta(cart);
        this.cart$.next(cart);

        // API returns a new cart if no cart is selected. Set new cart as active and refresh cartlist.
        if (!this.cartactive$.getValue()) {
          this.setActive(cart.cartid);
          this.getCarts();
          this.user.updateUserCart(cart.cartid);
        }

        this.cartWorking$.next(false);

        this.message.send({
          type: 'snackbar'
          , message: 'Items added successfully'
          , source: 'Cart'
          , duration: 1000
        });
      },
      (error) => {
        this.cartWorking$.next(false);
      });
    }
  }

  deleteItem(itemid: number) {
    this.cartWorking$.next(true);

    this.deleteItemReq(itemid).subscribe((cart: Cart) => {
      this.cart$.next(cart);
      this.deliveryDate$.next(null);
      this.cartWorking$.next(false);

      this.message.send({
        type: 'snackbar'
        , message: 'Item removed successfully'
        , source: 'Cart'
        , duration: 1000
      });
    },
    (error) => {
      this.cartWorking$.next(false);
    });
  }

  updateItem(itemid: number, field: string, value: any) {
    this.cartWorking$.next(true);

    this.updateItemReq(itemid, field, value).subscribe((cart: Cart) => {
        cart = this.checkMeta(cart);
        this.cart$.next(cart);

        this.cartWorking$.next(false);

        this.message.send({
          type: 'snackbar'
          , message: 'Items uppdated successfully'
          , source: 'Cart'
          , duration: 1000
        });

      },
      (error) => {
        this.cartWorking$.next(false);
      });
  }

  // Cart Operations

  newCart(cart = {meta: {comment: '', levplats: '', wEmailAddress: '' }} as Cart) {
    this.postCartReq(cart).subscribe((list: Cart[]) => {
      this.user.updateUserCart(list[0].cartid);
      this.cartlist$.next(list);
      this.cartactive$.next(list[0].cartid);
    });
  }


  emptyCart() {

    if (!this.cartactive$.getValue()) {
      this.message.send({
        type: 'snackbar'
        , message: 'Cart is already empty'
        , source: 'Cart'
        , duration: 1000
      });

      return;
    }

    this.opCartPutReq(this.cart$.getValue(), 'empty').subscribe((cart: Cart) => {
      this.cart$.next(null);

      this.message.send({
        type: 'snackbar'
        , message: 'Cart emptied successfully'
        , source: 'Cart'
        , duration: 1000
      });
    });

  }

  closeCart(status: number = 20) {
    const payload: any = {cartstatus: status};

    this.updateCartReq(payload).subscribe((cart: Cart) => {
      this.user.updateUserCart();
      this.cartactive$.next(null);
      this.cart$.next(null);
      this.getCarts();
    });

  }




  // Helper functions
  calculateCart(cart: Cart) {
    let qty = 0;
    let sum = 0;
    let vat = 0;

    if (cart && cart.cartid) {
      cart.items.forEach((item) => {
        qty += Number(item.itemqty);
        sum += Number(item.itemqty) * Number(this.getPrice(item));
        vat += Number(item.itemqty) * Number(this.getPrice(item)) * Number(item.itemvat) / 100;
      });
    }

    this.cartQty$.next(qty);
    this.cartVat$.next(vat);
    this.cartSum$.next(sum);
  }

  getPrice(item: CartItem) {
    if (!item.fields) {
      return 0;
    }

    switch (true) {
      case item.fields.Pris !== null:
        return item.fields.Pris;

      default:
        return item.fields.ArtListPris;
    }
  }

  checkMeta(cart: Cart) {
    if (!cart.meta) {
      cart.meta = this.defaultMeta;
    }

    return cart;
  }




  // API Endpoint objects
  getCartsReq() {
    const endpoint = this.endpointCart.replace('{cartid}', '');
    return this.api.getData(endpoint, []);
  }

  getCartReq(id: number) {
    const endpoint = this.endpointCart.replace('{cartid}', id.toString());
    return this.api.getData(endpoint, []);
  }

  postCartReq(cart: Cart) {
    const header: any = [];
    const endpoint = this.endpointCart.replace('{cartid}', '');

    return this.api.postData(endpoint, cart, header);
  }

  deleteCartReq(id: number) {
    const endpoint = this.endpointCart.replace('{cartid}', id.toString());
    const header: any = [];

    return this.api.deleteData(endpoint, header);
  }

  updateCartReq(payload: any, cartid: number = null) {
    let endpoint: string;
    const header: any = [];

    if (cartid) {
      endpoint = this.endpointCart.replace('{cartid}', cartid.toString());
    } else {
      endpoint = this.endpointCart.replace('{cartid}', this.cart$.getValue().cartid.toString());
    }

    return this.api.putData(endpoint, payload, header);
  }

  opCartPutReq(cart: Cart, operation: string) {

    const endpoint = this.endpointCartOp.replace('{cartid}', cart.cartid.toString()).replace('{operation}', operation);
    const header: any = [];

    return this.api.putData(endpoint, {}, header);
  }

  postItemReq(items: CartItem[]) {

    let cartid: string;

    if (this.cart$.getValue()) {
      cartid = this.cart$.getValue().cartid.toString();
    } else {
      cartid = '';
    }

    const endpoint = this.endpointCart.replace('{cartid}', cartid);
    const header: any = [];
    const body: { items: CartItem[] } = {items};

    return this.api.postData(endpoint, body, header);
  }

  updateItemReq(id: number, field: string, value: any) {
    const endpoint = this.endpointCartItem.replace('{cartid}', this.cart$.getValue().cartid.toString()).replace('{itemid}', id.toString());
    const header: any = [];
    const body: any = { item: { [field]: value } };

    return this.api.putData(endpoint, body, header);
  }

  deleteItemReq(id: number) {
    const endpoint = this.endpointCartItem.replace('{cartid}', this.cart$.getValue().cartid.toString()).replace('{itemid}', id.toString());
    const header: any = [];

    return this.api.deleteData(endpoint, header);

  }

}

