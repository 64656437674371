export const environment = {
  production: true,
  appVersion: 'v100',
  siteUrl:  'https://b2b.bagheera.se',
  siteId: 'b2b',
  apiUrl:   'https://hal.bagheera.se/api/v1',
  arImg100: 'https://arimg.bagheera.se/100/',
  arImg200: 'https://arimg.bagheera.se/200/',
  arImg300: 'https://arimg.bagheera.se/300/',
  arImg400: 'https://arimg.bagheera.se/400/',
  arImg500: 'https://arimg.bagheera.se/500/',
  pacsoftid: 'EHLPX4XPA7E5YWCP'
};
