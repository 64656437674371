import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';

import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [ ],
  exports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
  ]
})
export class GlobalModule { }
