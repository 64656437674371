<h3>Active Orders</h3>

<mat-card *ngFor="let order of viewSource" fxHide fxShow.lt-sm>
    <mat-card-subtitle>
      <span class="card-subt">{{order.OrdTypBeskr}}</span>
      <span class="card-subt"><a routerLink="/order/{{order.OrderNr}}">{{order.OrderNr}}</a></span>
    </mat-card-subtitle>
    <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Delivery:</div>
          <div class="card-content">{{order.OrdBerLevDat | date:'shortDate'}}</div>
      </div>
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Status:</div>
          <div class="card-content">{{order.OrdStatBeskr}}</div>
      </div>
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Shipping:</div>
          <div class="card-content">{{order.LevSattBeskr}}</div>
      </div>
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Started:</div>
          <div class="card-content">{{order.PLUtskrDat | date:'shortDate'}}</div>
      </div>
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Finished:</div>
          <div class="card-content">{{order.FsUtskrDat | date:'shortDate'}}</div>
      </div>
      <div fxFlex="100%" fxLayout="row">
          <div class="card-header">Report:</div>
          <div class="card-content"><app-report-dl [input]="{'template':'order_confirmImg', query:order.OrderNr}"></app-report-dl></div>
      </div>
    </div>
</mat-card>


<div class="container mat-elevation-z8" fxHide.lt-sm>
  <table mat-table [dataSource]="dataSource" matSort fxHide.lt-sm>
    
    <ng-container matColumnDef="OrderNr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Order no. </th>
      <td mat-cell *matCellDef="let element"> <a routerLink="/order/{{element.OrderNr}}">{{element.OrderNr}}</a></td>
    </ng-container>

    <ng-container matColumnDef="OrdTypBeskr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell *matCellDef="let element"> {{element.OrdTypBeskr}}</td>
    </ng-container>

    <ng-container matColumnDef="OrdBerLevDat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery date </th>
      <td mat-cell *matCellDef="let element"> {{element.OrdBerLevDat | date:'shortDate'}} </td>
    </ng-container>

    <ng-container matColumnDef="OrdStatBeskr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell *matCellDef="let element"> {{element.OrdStatBeskr}} </td>
    </ng-container>

    <ng-container matColumnDef="LevSattBeskr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Shipping </th>
      <td mat-cell *matCellDef="let element"> {{element.LevSattBeskr}} </td>
    </ng-container>

    <ng-container matColumnDef="PLUtskrDat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Start </th>
      <td mat-cell *matCellDef="let element"> {{element.PLUtskrDat | date:'shortDate'}} </td>
    </ng-container>

    <ng-container matColumnDef="FsUtskrDat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Finish </th>
      <td mat-cell *matCellDef="let element"> {{element.FsUtskrDat | date:'shortDate'}} </td>
    </ng-container>
    
    <ng-container matColumnDef="Operations">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Report </th>
      <td mat-cell *matCellDef="let element"><app-report-dl [input]="{'template':'order_confirmImg', query:element.OrderNr}"></app-report-dl></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>