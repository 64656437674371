import { Component, OnInit, Input } from '@angular/core';
import { Article } from '@classes/article';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-articles-list-cards',
  templateUrl: './articles-list-cards.component.html',
  styleUrls: ['./articles-list-cards.component.css']
})
export class ArticlesListCardsComponent implements OnInit {
@Input() articles: Article[];

  articleSettings = {viewColor: true, viewPriceCustomer: true, viewPriceRetail: true};

  constructor(private app: AppService) { }

  ngOnInit() {
    this.app.view.view$.subscribe(() => {
      this.articleSettings.viewPriceCustomer = this.app.view.getValue('viewPriceCustomer', true).value;
      this.articleSettings.viewPriceRetail = this.app.view.getValue('viewPriceRetail', true).value;
    });
  }
}
