<div *ngIf="isDone">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let user of users; let i=index">
            

            <mat-expansion-panel-header>
                {{user.usermeta.registration.shop_name | uppercase }}
            </mat-expansion-panel-header>

            <mat-list-item>
                <div class="container" fxLayout="row wrap" fxLayoutAlign="flex-start stretch" fxLayout.lt-md="column nowrap" fxLayoutAlign.lt-md="flex-start center">

                    <div class="section userinfo" fxFlex="32%" fxFlex.lt-md="100%">
                        <div class="row"><h3>User</h3></div>
                        <div class="row">
                            <p>{{user.usermeta.registration.firstname}} {{user.usermeta.registration.lastname}}</p>
                        </div>
                        <div class="row">
                            <p>{{user.usermeta.registration.email}}</p>
                        </div>
                        <div class="row">
                            <p>{{user.usermeta.registration.phone}}</p>
                        </div>
                        <div class="row">
                            <p>{{user.usermeta.registration.address}} {{user.usermeta.registration.city}}</p>
                        </div>

                    </div>

                    <div class="section company" fxFlex="32%" fxFlex.lt-md="100%">
                        <div class="row"><h3>Company</h3></div>

                        <ng-container *ngIf="company[i] != undefined">
                            <div class="row">
                                <mat-form-field class="company-nb">
                                    <input matInput placeholder="Customer nb" [value]="company[i].FtgNr" readonly>
                                </mat-form-field>
                                <mat-form-field class="company-name">
                                    <input matInput placeholder="Customer name" [value]="company[i].ftgnamn" readonly>
                                </mat-form-field>
                            </div>
                        </ng-container>
                        
                        <div class="row">
                            <button mat-raised-button color="primary" (click)="customerDialog(i)">Search</button>
                        </div>
                    </div>

                    <ng-container *ngIf="company[i] != undefined">
                        <div class="section contact" fxFlex="32%" fxFlex.lt-md="100%">

                            <div class="row"><h3>Contact</h3></div>

                                <div class="row">
                                    <mat-form-field>
                                        <mat-select [(value)]="contactsSelected[i]" placeholder="Select contact">
                                            <mat-option *ngFor="let contact of contacts[i]" [value]="contact.FtgKontaktNr">
                                                {{contact.FtgPerson}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            
                            <div class="row">
                                <mat-radio-group [(ngModel)]="contactRadioSelected[i]">
                                    <mat-radio-button *ngFor="let option of contactRadio[i]" [value]="option">{{option}}</mat-radio-button>
                                </mat-radio-group>
                                <mat-checkbox
                                    [checked]="user.valid === 1" 
                                    (change)="user.valid = $event.checked ? 1 : 0">Valid
                                </mat-checkbox>
                            </div>

                            <div class="row">
                                <button mat-raised-button color="accent" (click)="save(i)">Save</button>
                            </div>

                        </div>
                    </ng-container>

                </div>               
            </mat-list-item>

        </mat-expansion-panel>
    </mat-accordion>
</div>
