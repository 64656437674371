<!-- Desktop view -->
<div *ngIf="menues" class="menu-container" fxHide.lt-md>
  <app-progressbar></app-progressbar>
  <div class="menu-padding-top" fxLayout="row" fxLayoutAlign="space-between center">

    <div fxFlex="200px" fxLayout="row" fxLayoutAlign="flex-start center">
      <img src="assets/img/logo_xsmall_inv.png" alt="Logo" class="menu-logo" routerLink="/">
    </div>

    <div fxFlex="40%" fxLayout="row" fxLayoutAlign="center center">
        <app-search fxFlex="100%"></app-search>
    </div>

    <div fxFlex="200px" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
      <app-article-export-icon></app-article-export-icon>
      <app-carticon></app-carticon>
      <app-user-dropdown></app-user-dropdown>
      <app-settings-ddicon></app-settings-ddicon>
    </div>

  </div>

  <!-- Main menu-->
  <div fxLayout="row nowrap" class="menu-bar" fxLayoutAlign="center center">
    <app-menu-list [menuList]="menues" (output)="openSubMenu($event)"></app-menu-list>
  </div>

  <!-- Main menu dropp down-->
  <app-menu-submenu [menuItems$]="menuItems$" (output)="subMenuClick($event)"></app-menu-submenu>

</div>


<!-- Mobile view -->

<div *ngIf="menues" class="menu-container-mob" fxHide fxShow.lt-md>
  <app-progressbar></app-progressbar>
  <div class="menu-bar-mob" fxLayout="row nowrap" fxLayoutAlign="space-between center">
    <div fxFlex="33%" fxLayout="row nowrap" fxLayoutAlign="flex-start center" style="padding-left:20px;" (click)="drawerToggle()">
      <i class="fas fa-bars fa-2x" style="color: rgb(39, 39, 39);"></i>
    </div>
    <div fxFlex="33%" fxLayout="row nowrap" fxLayoutAlign="center center">
      <img src="assets/img/logo_xsmall_inv.png" alt="Logo" style="height:40px;" routerLink="/">
    </div>
    <div fxFlex="33%" fxLayout="row nowrap" fxLayoutAlign="flex-end center" style="padding-right:30px;">
      <app-carticon></app-carticon>
    </div>

  </div>
  <app-menu-drawer [menues]="menues" [drawerState$]="drawerState$" (output)="subMenuClick($event)" fxHide fxShow.lt-md></app-menu-drawer>
</div>
