import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { DataService } from '@services/data.service';
import { MessageService } from '@services/message.service';

import { User, Registration, UserOption, ApiKey } from '@classes/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public endpoint = '/app/user/';
  public endpointVerify = '/app/user/{userid}/verify';
  public endpointVerifHash = '/app/user/verify/{hash}';
  public endpointActivate = '/app/user/{userid}/activate';
  public endpointRegistered = '/app/user/registration';
  public endpointCart = '/app/user/cart/{cartid}';
  public endpointMailVerify = '/app/mail/userverification/{userid}';
  public endpointMailActive = '/app/mail/useractivated/{userid}';
  public endpointPassword = '/app/user/password';
  public endpointRecover = '/app/user/recover';
  public endpointRecHash = '/app/user/recover/{hash}';
  public endpointApiKey = '/app/apikey/{userid}';

  public verifyMail = true;
  public activateMail = true;

  public user$: BehaviorSubject<User>;

  constructor(private api: DataService , private message: MessageService) {
    this.user$ = new BehaviorSubject({} as User);
   }

  getUser(userid: number = null): Observable<User[] | User> {
    let endpoint: string = this.endpoint;

    if (userid) {
      endpoint += String(userid);
    }

    return this.api.getData(endpoint, []);
  }

  getRegistrated() {
    return this.api.getData(this.endpointRegistered, []);
  }

  updateUser(data: User) {
    const userid: number = data.userid;
    const endpoint: string = this.endpoint + userid;

    return this.api.putData(endpoint, data, []);
  }

  updateUserCart(cartid: number = null) {
    let parameter: string;

    if (cartid) {
      parameter = cartid.toString();
    } else {
      parameter = '';
    }

    const endpoint: string = this.endpointCart.replace('{cartid}', parameter);

    this.api.postData(endpoint, {}, []).subscribe( res => {
      const user: User = this.user$.getValue();
      user.cartactive = cartid;
      this.user$.next(user);

    });

  }

  newUser(data: User) {

  }

  newRegistration(data: Registration) {
    const endpoint: string = this.endpointRegistered;
    const payload: { registration: Registration } = {registration: data};

    return this.api.postData(endpoint, payload, []);
  }

  isRegistered(email: string) {

  }

  verify(userid: number) {
    const endpoint: string = this.endpointVerify.replace('{userid}', String(userid));
    const data = {mail: this.mailVerify};

    return this.api.postData(endpoint, {}, []);
  }

  verifyHash(hash: string) {
    const endpoint: string = this.endpointVerifHash.replace('{hash}', hash);

    return this.api.getData(endpoint, []);
  }

  activate(userid: number) {
    const endpoint: string = this.endpointActivate.replace('{userid}', String(userid));
    const data = {mail: this.mailActivate};

    return this.api.postData(endpoint, data, []);
  }

  mailVerify(userid: number) {
    const endpoint: string = this.endpointMailVerify.replace('{userid}', String(userid));

    return this.api.postData(endpoint, {}, []);
  }

  mailActivate(userid: number) {
    const endpoint: string = this.endpointMailActive.replace('{userid}', String(userid));

    return this.api.postData(endpoint, {}, []);
  }

  setPassword(password: string, oldpassword: string) {
    const endpoint: string = this.endpointPassword;
    const data = {password, oldpassword};

    return this.api.postData(endpoint, data, []);
  }

  recoverPassword(param: string) {
    const data: { username: string } = {username: param};

    return this.api.postData(this.endpointRecover, data, []);
  }

  recoverHash(hash: string) {
    const endpoint: string = this.endpointRecHash.replace('{hash}', hash);

    return this.api.getData(endpoint, []);
  }

  recoverSetPassword(hash: string, newPassword: string) {
    const endpoint: string = this.endpointRecHash.replace('{hash}', hash);
    const data: {password: string} = {password: newPassword};

    return this.api.postData(endpoint, data, []);
  }

  setActiveUser(userid: number) {
    this.getUser(userid).subscribe((user: User) => {
      this.user$.next(this.setOption(user));
    });
  }

  setOption(user: User) {
    const options: UserOption[] = Array();

    for (const prop in user) {
      if (prop) {
        if (prop === 'role_id' && user[prop] === 1) {
          options.push({option: 'admin', value: true});
        }

        if (prop === 'extid' && user[prop]) {
          options.push({option: 'extenda', value: true});
        }
      }
    }

    user.options = options;
    return user;
  }

  hasOption(user: User = null, option: string = null) {
    if (!user || !option || !user.options) {
      return false;
    }

    const resOpt = user.options.filter(opt => opt.option === option);

    if (resOpt.length > 0) {
      return resOpt[0].value;
    } else {
      return false;
    }
  }

  getApiKey(userid: number): Observable<ApiKey> {
    const endpoint: string = this.endpointApiKey.replace('{userid}', userid ? String(userid):'');

    return this.api.getData(endpoint, []);
  }

  newApiKey(userid: number): Observable<ApiKey> {
    const endpoint: string = this.endpointApiKey.replace('{userid}', userid ? String(userid):'');

    return this.api.postData(endpoint, [], []);
  }
}

