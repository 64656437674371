import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { Menu, MenuItems } from '@classes/menu';

@Component({
  selector: 'app-menu-drawer',
  templateUrl: './menu-drawer.component.html',
  styleUrls: ['./menu-drawer.component.css']
})
export class MenuDrawerComponent implements OnInit {
  @Input() drawerState$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() menues: Menu[] = null;

  @Output() output: EventEmitter<MenuItems> = new EventEmitter();

  isOpen = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.drawerState$.subscribe(state => this.isOpen = state);

  }

  clickLink(item: MenuItems, link: MenuItems = null) {
    const data: MenuItems = {... item};
    data.children = link ? Array(link) : Array(null);
    this.drawerToggle();

    this.output.emit(data);
  }

  route(route: string) {
    this.router.navigate([route]);
    this.drawerToggle();
  }

  drawerToggle() {
    this.drawerState$.next(!this.isOpen);
  }

}
