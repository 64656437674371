<div class="container">
<div class="inputs" *ngIf="!submitted">
  <form class="input-form" [formGroup]="regForm" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" (ngSubmit)="submit()">

    <mat-form-field class="full-width" floatLabel="always">
      <input matInput placeholder="Shop name" formControlName="shop_name">
    </mat-form-field>

    <mat-form-field class="full-width" floatLabel="always" fxFlex="0 1 60%" fxFlex.lt-sm="100" style="margin-right:2%">
      <input matInput placeholder="Adress" formControlName="address">
    </mat-form-field>

    <mat-form-field class="full-width" floatLabel="always" fxFlex="0 1 38%" fxFlex.lt-sm="100" >
      <input matInput placeholder="City" formControlName="city">
    </mat-form-field>

    <mat-form-field class="full-width" floatLabel="always" fxFlex="0 1 38%" fxFlex.lt-sm="100"  style="margin-right:2%">
      <input matInput placeholder="First name" formControlName="firstname">
    </mat-form-field>

    <mat-form-field class="full-width" floatLabel="always" fxFlex="0 1 60%" fxFlex.lt-sm="100" >
      <input matInput placeholder="Last name" formControlName="lastname">
    </mat-form-field>

    <mat-form-field class="full-width" floatLabel="always" fxFlex="0 1 60%" fxFlex.lt-sm="100"  style="margin-right:2%">
      <input matInput name="email"  placeholder="E-Mail" required formControlName="email">
      <mat-error *ngIf="regForm.get('email').hasError('email') && !regForm.get('email').hasError('required')">
        Please enter a valid email address
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" floatLabel="always" fxFlex="0 1 38%" fxFlex.lt-sm="100" >
      <input matInput placeholder="Phone" formControlName="phone">
    </mat-form-field>

    <mat-form-field class="full-width" floatLabel="always" fxFlex="0 1 49%" fxFlex.lt-sm="100"  style="margin-right:2%;margin-bottom:10px;">
      <input matInput type="password" placeholder="Password" formControlName="password">
    </mat-form-field>

    <mat-form-field class="full-width" floatLabel="always" fxFlex="0 1 49%" style="margin-bottom:10px;">
      <input matInput type="password" placeholder="Confirm password" formControlName="confirm">
    </mat-form-field>

    <div class="error-container" fxFlex="100%" *ngIf="regForm.get('password').value">
      <mat-error *ngIf="regForm.get('password').getError('minlength')">
        Password must be at least 8 characters long
      </mat-error>
      <mat-error *ngIf="regForm.get('password').getError('upperCase')">
        Password must contain at least 1 large character
      </mat-error>
      <mat-error *ngIf="regForm.get('password').getError('lowerCase')">
        Password must contain at least 1 small character
      </mat-error>
      <mat-error *ngIf="regForm.get('password').getError('number')">
        Password must contain at least 1 number
      </mat-error>
      <mat-error *ngIf="regForm.get('password').getError('special')">
        Password must contain at least 1 special character
      </mat-error>
      <mat-error *ngIf="regForm.get('password').getError('blank')">
        Password cannot contain any spaces
      </mat-error>
      <mat-error *ngIf="!passwordMatch">
        Passwords dont match
      </mat-error>
    </div>

    <div fxFlex="100%" fxLayout="row nowrap" fxLayoutGap="5px" fxLayoutAlign="flex-start flex-start">
      <div><mat-checkbox formControlName="terms"></mat-checkbox></div>
      <div>
        In order to complete your registration you must read and accept <a (click)="openDialog()" style="color:#3f51b5;font-weight: bold">our terms</a>
      </div>
    </div>

    <button type="submit" mat-raised-button color="primary" [disabled]="!regForm.valid">Submit</button>
  </form>
  {{regForm.get('confirm').errors?.mismatch}}

</div>

<div *ngIf="submitted">
  <p>Thank you for registrating!</p>
  <p>A mail has been sent to your email adress for confirmation</p>

</div>
</div>