import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';

import { UserService } from '@services/user.service';
import { MessageService } from '@services/message.service';
import { JwtService } from '@services/jwt.service';
import { User } from '@classes/user';
import { CustomValidators } from '@modules/utility/utility';

@Component({
  selector: 'app-user-psw',
  templateUrl: './user-psw.component.html',
  styleUrls: ['./user-psw.component.css']
})
export class UserPswComponent implements OnInit {

  @Input() user: User;

  passwordForm: FormGroup;
  passwordMatch = true;
  CustomValidator = new CustomValidators();

  constructor(private userService: UserService, private message: MessageService, public jwt: JwtService) {

    this.passwordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        this.CustomValidator.regTest(/[A-Z]/, true, 'upperCase'),
        this.CustomValidator.regTest(/[a-z]/, true, 'lowerCase'),
        this.CustomValidator.regTest(/[0-9]/, true, 'number'),
        this.CustomValidator.regTest(/\W|_/, true, 'special'),
        this.CustomValidator.regTest(/\s/g, false, 'blank')
      ]),
      oldpassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ])
      , confirm: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ])
    });

  }

  ngOnInit() {
    this.passwordForm.get('confirm').valueChanges.subscribe(confirm => {
      if (this.passwordForm.get('password').value === confirm) {
        this.passwordMatch = true;
      } else {
        this.passwordMatch = false;
        this.passwordForm.get('confirm').setErrors({confirm: {confirm: false}});
      }
    });
  }

  submit() {
    if (this.passwordForm.valid) {
      const password: string = this.passwordForm.value.password;
      const oldpassword: string = this.passwordForm.value.oldpassword;
      const confirm: string = this.passwordForm.value.confirm;

      // If passwords not matching then stop
      if (confirm !== password) {
        this.passwordMatch = false;
        return;
      }

      this.passwordForm.disable();
      this.userService.setPassword(password, oldpassword).subscribe(
        (res) => {
          if (res.jwt) {
            this.jwt.save(res.jwt);
            this.success();

          } else {
            this.error({description: 'Somthing went wrong'});
          }
        },
        (error) => this.error(error)
      );

    }
  }

  success() {
    this.message.send({
      type: 'snackbar'
      , message: 'Password was updated successfully'
      , source: 'User service'
      , duration: 3000
    });

    this.passwordForm.reset();
    this.passwordForm.markAsPristine();
  }

  error(message: any) {
    this.message.send({
      type: 'snackbar'
      , message: message.message
      , source: 'User service'
      , duration: 3000
    });

    this.passwordForm.enable();
  }
}
