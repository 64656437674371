// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v100',
  siteUrl: 'http://localhost:4200/',
  siteId: 'b2b',
  apiUrl: 'https://hal.bagheera.se/api/v1',
  arImg100: 'https://arimg.bagheera.se/100/',
  arImg200: 'https://arimg.bagheera.se/200/',
  arImg300: 'https://arimg.bagheera.se/300/',
  arImg400: 'https://arimg.bagheera.se/400/',
  arImg500: 'https://arimg.bagheera.se/500/',
  pacsoftid: 'EHLPX4XPA7E5YWCP'
};

// Local Dev
// export const environment = {
//   production: false,
//   appVersion: 'v100',
//   siteUrl: 'http://localhost:4200/',
//   siteId: 'b2b',
//   apiUrl: 'http://vh.dev.se/api/dev',
//   arImg100: 'http://vh.dev.se/image_library/100/',
//   arImg200: 'http://vh.dev.se/image_library/200/',
//   arImg300: 'http://vh.dev.se/image_library/300/',
//   arImg400: 'http://vh.dev.se/image_library/400/',
//   arImg500: 'http://vh.dev.se/image_library/500/',
//   pacsoftid: '780505800077441'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
