import { Component, Renderer2 } from '@angular/core';
import { filter } from 'rxjs/operators';

import { DataService } from '@services/data.service';
import { CartService } from '@services/cart.service';
import { JvsorderService } from '@services/jvsorder.service';
import { JwtService } from '@services/jwt.service';
import { AuthService } from '@services/auth.service';
import { AppService } from '@services/app.service';
import { Router, NavigationEnd } from '@angular/router';
import { MessageService } from '@services/message.service';
import { UserService } from '@services/user.service';
import { MenuService } from '@services/menu.service';
import { JvsarticleService } from '@services/jvsarticle.service';
import { ContentService } from '@modules/content-core/services/content.service';
import { JvsarticlefilterService } from './services/jvsarticlefilter.service';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'App';
  showFooter = false;

  constructor(
    private renderer: Renderer2,
    public app: AppService,
    public router: Router,
    public api: DataService,
    public content: ContentService,
    public jwt: JwtService,
    public auth: AuthService,
    public menu: MenuService,
    public user: UserService,
    public cart: CartService,
    public jvsorder: JvsorderService,
    public jvsarticle: JvsarticleService,
    public jvsArtFilter: JvsarticlefilterService,
    public messages: MessageService ) {

      // Google Analytics
      const navEndEvents = router.events.pipe(
        filter(
          event => event instanceof NavigationEnd
        )
      );

      navEndEvents.subscribe(
        (event: NavigationEnd) => {
          gtag('config', 'UA-11657116-5', {
            page_path: event.urlAfterRedirects,
          });
        }
      );


      this.api.requestError.subscribe(error => {
        if (error !== null && error.status === 401) {
          this.auth.isAuth.next(false);
          this.menu.reset();
        }
      });

      this.jwt.init();
      this.auth.jwtAuth();

      // Init when authication is done
      this.auth.isAuth.subscribe(isAuth => {
        this.showFooter = isAuth;

        if (isAuth) {
          this.user.setActiveUser(this.jwt.body.userid);
          this.cart.init();

          // Initiate main menu
          this.menu.getMenuList();
          this.menu.menuAppend('customer');

          // Initiate main content
          this.content.init();

          // Add user based menu items
          this.user.user$.subscribe(usersub => {

            // Run method with array of parameters
            Array('admin', 'extenda', 'test').map(item => {
              if (this.user.hasOption(usersub, item)) {
                this.menu.menuAppend(item);
              }
            });
          });
        }
      });


      this.app.layoutOverflow$.subscribe(visible => {
        if (!visible) {
          // Apply overflow: hidden;
          this.renderer.setStyle(document.body, 'overflow', 'hidden', 1);
        } else {
          // Apply overflow: auto;
          this.renderer.setStyle(document.body, 'overflow', 'auto');
        }
      });

      this.jvsarticle.articleList$.subscribe(articleList => {
        this.jvsArtFilter.setSource(articleList);
      });

  }

  searchArticle(search: any) {
    if (search) {
      this.router.navigate(['articles/', search]);
    }
  }
}
