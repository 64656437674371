<ng-container *ngIf="isDone">

    <div class="container" fxLayout="column nowrap" fxLayoutAlign="flex-start stretch" fxLayoutGap="10px">
        <mat-card>
            <div fxLayout="row wrap">
                <div fxFlex.gt-sm="50%" fxFlex.lt-md="100%" style="min-height:100px;">
                    <app-article-img [article]="matrixState"></app-article-img>
                </div>
                <div fxFlex.gt-sm="50%" fxFlex.lt-md="100%" style="min-height:100px;">
                    <app-article-data [article]="activeArticle"></app-article-data>
                    <app-article-img-list [articles]="this.matrix.colors" [state]="matrixState"></app-article-img-list>
                </div>
            </div>
        </mat-card>
        <mat-card>
            <div fxLayout="row wrap">
                <div fxFlex="100%">
                    <mat-tab-group fxHide.lt-md>
                        <mat-tab label="Size matrix">
                            <ng-template matTabContent>
                                <app-order-delivery [date$]="cart.deliveryDate$"></app-order-delivery>
                                <app-porder-rdlist [article]="articles[0]" (output)="cart.deliveryDate$.next($event)"></app-porder-rdlist>
                                <app-article-matrix [matrix]="matrix" [state]="matrixState"></app-article-matrix>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Article data">
                            <ng-template matTabContent>
                                <div style="padding:10px;">
                                    <app-export-table [articles]="articles"></app-export-table>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                    <app-order-delivery fxHide fxShow.lt-md [date$]="cart.deliveryDate$"></app-order-delivery>
                    <app-porder-rdlist fxHide fxShow.lt-md [articleState]="matrixState" (output)="cart.deliveryDate$.next($event)"></app-porder-rdlist>
                    <app-article-matrix-xs fxHide fxShow.lt-md [matrix]="matrix" [state]="matrixState">
                    </app-article-matrix-xs>
                </div>
            </div>
        </mat-card>
    </div>

</ng-container>