import { ContentItemType } from './content-core-types';

export class ContentItemTemplate {
  types: {[key: string]: ContentItemType};
  props: {[key: string]: Array<{prop: string}>};

  constructor() {
    this.defaultTypes();
    this.defaultProps();
  }

  defaultTypes() {
    this.types = {};
    this.types.ad = {type: 'ad', css: {}, cssMob: {}, flex: {}, children: []};
    this.types.section = {type: 'section', css: {}, cssMob: {}, flex: {}, children: []};
    this.types.container = {type: 'container', css: {}, cssMob: {}, flex: {}, children: []};
    this.types.text = {type: 'text', css: {}, cssMob: {}, flex: {}, value: 'text value'};
    this.types.img = {type: 'img', css: {width: '100%'}, cssMob: {}, flex: {}, value: 'image url'};
    this.types.button = {
      type: 'button', css: {}, cssMob: {}, flex: {}, value: 'New Button', link: '', queryParams: {}
    };

    this.types['articles-banner'] = {
      type: 'articles-banner', displayCount: 4, css: {}, cssMob: {}, flex: {}, articles: []
    };
  }

  defaultProps() {
    this.props = {};
    this.props.css = [];
    this.props.cssMob = [];
    this.props.flex = [];
    // this.props.queryParams = [];
    // this.props.queryArt = [];
  }

  getType(type: string = 'ad') {
    return this.types[type];
  }

  getProps(prop = null) {
    if (!prop) {
      return this.props;
    } else {
      return this.props[prop];
    }
  }
}

export class ContentItem {

  content: ContentItemType;

  constructor(content: ContentItemType = null) {
    if (content) {
      this.content = content;
    }
  }

  getProperties() {
    if(this.content.articles) {
      const data = {...this.content};
      delete data.articles;

      return data;
    }

    return this.content;
  }

  import(contentItemData: ContentItemType) {
    this.content = {... contentItemData};
    this.content.children = [];

    if (contentItemData.children) {
      contentItemData.children.forEach(child => {
        if (typeof child.content !== 'undefined') {
          const newChild = new ContentItem();
          newChild.import(child.content);
          this.content.children.push(newChild);
        }
      });
    }

    if(this.content.articles) {
      this.content.articles = [];
    }
  }

  setType(type: string) {
    const template = new ContentItemTemplate();
    this.content = template.getType(type);
  }

  propSet(target: string, property: string, value: string) {
    if (!property || !target) {
      console.error('propAdd requires property,value and target');
      return;
    }

    if (typeof this.content[target] === 'undefined') {
      this.content[target] = {};
    }

    const targetProp = this.content[target];

    if (!value) {
      return this.propClear(targetProp, property);
    }

    if (this.propUpdate(targetProp, property, value)) {
      return true;
    }

    targetProp[property] = value;
    return true;
  }

  propUpdate(targetProp: any, property: string, value: string) {
    if (typeof targetProp[property] !== undefined) {
      targetProp[property] = value;
      return true;
    }

    return false;
  }

  propClear(targetProp: any, property: string) {
    if (Array.isArray(targetProp)) {
      targetProp.splice(Number(property), 1);

      return true;
    }

    if (typeof targetProp[property] !== undefined) {
      delete targetProp[property];
      return true;
    } else {
      return false;
    }
  }

  deleteChild(index: number) {
    this.content.children.splice(index, 1);
  }

  newContent(type: string) {
    const template = new ContentItemTemplate();
    return new ContentItem(template.getType(type));
  }

  addChild(type: string) {
    const template = new ContentItemTemplate();
    const contentItem = new ContentItem(template.getType(type));
    this.content.children.push(contentItem);
  }

  stringLength(str: string, defaultLength = 10) {
    if (!str || typeof str.length === 'undefined') {
      return defaultLength;
    }

    if (str.length > 0) {
      return str.length;
    } else {
      return defaultLength;
    }
  }

}
