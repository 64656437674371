import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Contact, ComData, Customer } from '@classes/customer';
import { JvscontactService } from '@services/jvscontact.service';

@Component({
  selector: 'app-contact-add',
  templateUrl: './contact-add.component.html',
  styleUrls: ['./contact-add.component.css']
})
export class ContactAddComponent implements OnChanges {

  @Input() customer: Customer;
  @Input() contacts: Contact[];
  @Output() output: EventEmitter<boolean> = new EventEmitter<boolean>();

  showForm = false;

  updateContact: Contact;
  newContact: Contact;
  newComData: ComData;

  comDataOptions: ComData[] = Array(
    {ComKod: '0', ComBeskr: 'Shop phone', ComNr: ''}
    , {ComKod: '4', ComBeskr: 'Mobile', ComNr: ''}
    , {ComKod: '7', ComBeskr: 'E-mail', ComNr: ''}
    );

  constructor(private jvsContact: JvscontactService) { }

  ngOnChanges() {
    this.init();
  }

  init(): void {

    this.newContact = {
      FtgPerson: ''
      , ComData: []
    };

    this.newComData = {
      ComKod: '0'
      , ComBeskr: 'Shop phone'
      , ComNr: ''
    };
  }

  addComData() {
    this.newContact.ComData.push({... this.newComData});
    this.newComData = {... this.newComData};
    this.newComData.ComNr = '';
  }

  removeComData(i: number) {
    this.newContact.ComData.splice(i, 1);
  }

  putContact() {
    let FtgKontaktNr: string | number;

    if (!this.updateContact.FtgKontaktNr || this.updateContact.FtgKontaktNr === undefined) {
      FtgKontaktNr = null;
    } else {
      FtgKontaktNr = this.updateContact.FtgKontaktNr;
    }

    const payload: Contact = {
      ComData: Array({... this.newComData})
    };

    this.jvsContact.newComData(this.customer.FtgNr, FtgKontaktNr, payload).subscribe(res => {
      this.output.emit(true);
    });
  }

  postContact() {
    if (this.newContact.FtgPerson === '') {
      delete this.newContact.FtgPerson;
    }

    this.jvsContact.newContact(this.customer.FtgNr, this.newContact).subscribe(res => {
        this.output.emit(true);
    });

    this.init();
  }

}
