import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { environment } from '@env/environment';
import { OrderRader } from '@classes/order';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-orderrows',
  templateUrl: './orderrows.component.html',
  styleUrls: ['./orderrows.component.css']
})
export class OrderrowsComponent implements OnInit {

  @Input() ordernb: number;
  @Input() paginate = true;

  public env = environment;
  public response: OrderRader[];
  public displayedColumns: string[] = [
    'OrdRadnr', 'OrdBerLevDat', 'PvmMasterItem'
    , 'ArtBeskr', 'PVMVariantDescr', 'PVMVariantDescr2',
     'OrdAntal', 'vb_pris', 'VolymRabatt', 'itemSum'];

  public dataSource: MatTableDataSource<OrderRader>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private api: DataService) { }

  loadData(ordernb: number) {
    const endpoint: string = '/jeeves/order/' + ordernb;
    const headers: any = [];

    this.api.getData(endpoint, headers).subscribe(data => {
      this.response = data as OrderRader[];
      this.processResponse();
    });
  }

  processResponse() {
    this.dataSource = new MatTableDataSource<OrderRader>(this.response);

    if (this.paginate) {
      this.dataSource.paginator = this.paginator;
    }

    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    if (this.ordernb) {
      this.loadData(this.ordernb);
    }
  }
}
