<mat-tab-group>
  
  <mat-tab label="Users">
    <ng-template matTabContent>
      <app-admin-user></app-admin-user>
    </ng-template>
  </mat-tab>

  <mat-tab label="Registrations">
    <ng-template matTabContent>
      <div class="component-container">
        <app-admin-registration></app-admin-registration>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab label="Validating">
    <ng-template matTabContent>
      <div class="component-container">
        <app-admin-validation></app-admin-validation>
      </div>
    </ng-template>
  </mat-tab>
 
</mat-tab-group>