import { Component, OnInit, Input} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { environment } from '@env/environment';

@Component({
  selector: 'app-pacsofttrack',
  templateUrl: './pacsofttrack.component.html',
  styleUrls: ['./pacsofttrack.component.css']
})
export class PacsofttrackComponent implements OnInit {
  @Input() ordernb: string = null;

  baseUrl = 'https://www.unifaunonline.com/ext.uo.yy.track?apiKey={userid}&reference={ordernb}';
  userid = environment.pacsoftid;
  iframeSrc: SafeResourceUrl = null;

  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(param => {
      if (param.ordernb) {
        this.createUrl(param.ordernb);
      }
    });

    if (this.ordernb) {
      this.createUrl(this.ordernb);
    }
  }

  createUrl(ordernb: string) {
    const url = this.baseUrl.replace('{userid}', this.userid).replace('{ordernb}', ordernb);
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
