import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Customer } from '@classes/customer';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-customer-search-dialog',
  templateUrl: './customer-search-dialog.component.html',
  styleUrls: ['./customer-search-dialog.component.css']
})
export class CustomerSearchDialogComponent implements OnInit {

  @Output() output: EventEmitter<Customer> = new EventEmitter<Customer>();

  public searchResult$: BehaviorSubject<Customer[]> = new BehaviorSubject<Customer[]>([]);
  public search = false;

  constructor( ) { }

  ngOnInit() {
  }

  onSearch(results: Customer[]) {
    this.searchResult$.next(results);
    this.search = true;
  }

  onSelect(select: Customer) {
    this.output.emit(select);
  }

}
