import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DataService } from '@services/data.service';
import { AppService } from '@services/app.service';

import { Article } from '@classes/article';
import { MenuItems } from '@classes/menu';

@Injectable({
  providedIn: 'root'
})
export class JvsarticleService {

  public arImg = 'https://arimg.bagheera.se/';

  endpointArList = '/jeeves/articles/prodtyp';
  endpointAr = '/jeeves/article/{ArtNr}';
  endpointArImg = '/jeeves/pdm/{ArtNr}';

  articleList$: BehaviorSubject<Article[]> = new BehaviorSubject(null);
  article$: BehaviorSubject<Article> = new BehaviorSubject(null);

  articleListUrl: string = null;
  articleUrl: string = null;

  constructor(private api: DataService, private app: AppService) {
  }

  processProdTypQuery(query: MenuItems) {
    if (this.queryActive(query, this.articleListUrl)) {
      this.getArProdTyp(query);
    }
  }

  queryActive($newQuery: any, $oldQuery: string) {
    if (JSON.stringify($newQuery) !== $oldQuery) {
      this.articleListUrl = JSON.stringify($newQuery);
      return true;

    } else {
      return false;
    }
  }

  getAr(ArtNr: string, delivery: string = null) {
    const endpoint = this.endpointAr.replace('{ArtNr}', ArtNr);

    let params = {};

    if (delivery) {
      params = {params: {OrdBerLevDat: delivery}};
    }

    return this.api.getData(endpoint, params);
  }

  getArSearch(search: string) {
    const endpoint = this.endpointAr.replace('{ArtNr}', 'search/' + encodeURI(search));

    return this.api.getData(endpoint, []);
  }

  getArImg(ArtNr: string) {
    const endpoint = this.endpointArImg.replace('{ArtNr}', ArtNr);

    return this.api.getData(endpoint, []);
  }


  getArProdTyp(query) {
    this.app.start();
    const params = {params: query};

    this.articleList$.next(null);

    this.getArProdTypReq(params).subscribe((articles: Article[]) => {
      this.articleList$.next(articles);
      this.app.done();
    });
  }

  getArProdTypReq(params) {
    const endpoint = this.endpointArList;
    return this.api.getData(endpoint, params);
  }

  calculateMatrix(articles: Article[]) {

    const matrix = {
      colors: Array(),
      sizes: Array()
    };

    const colorsSet = new Set([... articles.map(article => article.PVMVariant1)]);
    const sizesSet = new Set([... articles.map(article => article.PVMVariant2)]);
    const colors = Array.from(colorsSet);
    const sizes = Array.from(sizesSet);

    colors.sort();
    sizes.sort();

    colors.forEach(color => {
      const colorMatch = articles.find(article => article.PVMVariant1 === color);
      matrix.colors.push(colorMatch);
    });

    sizes.forEach(size => {
      const sizeMatch = articles.find(article => article.PVMVariant2 === size);
      matrix.sizes.push(sizeMatch);
    });


    matrix.colors.forEach((color, index) => {
      matrix.colors[index].sizes = Array();

      matrix.sizes.forEach(size => {
        const match = articles.find(article => (article.PVMVariant1 === color.PVMVariant1 && article.PVMVariant2 === size.PVMVariant2));

        if (typeof match === 'undefined') {
          matrix.colors[index].sizes.push(null);
        } else {
          matrix.colors[index].sizes.push(match);
        }

      });
    });

    return matrix;
  }

}

/*
Background service that loads articles and article lists
Pass url to service for retrival and current list comparison
*/
