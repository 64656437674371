<div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" class="container">
    <div fxFlex="100%">
        <mat-form-field floatLabel="always" style="width:380px;">
            <input matInput placeholder="API Key" [value]="apiKey.value" disabled>
        </mat-form-field>
    </div>
    <div fxFlex="100%" fxLayout="row nowrap" fxLayoutAlign="flex-start center">
        <button mat-raised-button color="primary" (click)="generateApiKey()">Generate key</button>
    </div>
</div>
