import { Component, OnInit, Input } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ArticlesBanner } from '@classes/site';
import { AppService } from '@services/app.service';


@Component({
  selector: 'app-articles-list-banner',
  templateUrl: './articles-list-banner.component.html',
  styleUrls: ['./articles-list-banner.component.css']
})
export class ArticlesListBannerComponent implements OnInit {
  @Input() content: ArticlesBanner;
  @Input() settings: {viewColor: boolean, viewPriceCustomer: boolean, viewPriceRetail: boolean} = {
    viewColor: true, viewPriceCustomer: true, viewPriceRetail: true
  };

  view = {
    isDone: false,
    articles: [],
    start: 0,
    offset: 4
  };

  offset = 4;


  constructor(private breakpointObserver: BreakpointObserver, private app: AppService) { }

  ngOnInit() {
    this.breakpointObserver
      .observe(['(min-width: 959px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.view.offset = this.offset;
        } else {
          this.view.offset = 2;
        }
      });

    this.initView(this.content);
  }

  initView(data: ArticlesBanner) {
    this.view.articles = data.articles;
    this.view.isDone = true;
  }

  scroll(index: number) {

    let start = 0;

    if (index) {
      start = this.view.start + this.view.offset;
    } else {
      start = this.view.start - this.view.offset;
    }

    if (start < 0) {
      return;
    }

    if (start >= this.view.articles.length) {
      return;
    }

    this.view.start = start;
  }

}
