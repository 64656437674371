import { Component, OnInit, ViewChild } from '@angular/core';
import { OrdersDel } from '@classes/order';
import { JvsorderService } from '@services/jvsorder.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-ordersopen',
  templateUrl: './ordersopen.component.html',
  styleUrls: ['./ordersopen.component.css']
})
export class OrdersopenComponent implements OnInit {

  public response: OrdersDel[] = [];
  public displayedColumns: string[] = [
    'OrdTypBeskr', 'OrderNr', 'OrdBerLevDat', 'OrdStatBeskr', 'LevSattBeskr', 'PLUtskrDat', 'FsUtskrDat', 'Operations'];
  public dataSource: MatTableDataSource<OrdersDel>;
  public viewSource: OrdersDel[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private jvsOrder: JvsorderService) { }

  loadData() {

    this.jvsOrder.getActive().subscribe(data => {
      if (data) {
        this.response = data as OrdersDel[];
        this.processResponse();
      }
    });
  }

  processResponse() {
    this.dataSource = new MatTableDataSource<OrdersDel>(this.response);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    const sub = this.dataSource.connect().subscribe(res => this.viewSource = res);

  }

  ngOnInit() {
    this.loadData();
  }
}
