<!-- Dynamic Test -->
<ng-container *ngIf="!isLoading">
  <ng-container *ngFor="let content of contents">
    <!-- Products -->
    <ng-container
      *ngIf="content.content.type === 'articles-banner' && content.content.articles.length"
    >
      <app-articles-list-banner
        [content]="content.content"
        [settings]="articleSettings"
      >
      </app-articles-list-banner>
    </ng-container>

    <ng-container
      *ngIf="content.content.type === 'articles-banner' && !content.content.articles.length"
    >
      <div
        class="banner"
        fxLayout="row nowrap"
        fxLayoutAlign="space-around center"
      >
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
    </ng-container>

    <!-- Ads -->
    <div
      *ngIf="content.content.type === 'ad'"
      class="banner"
      [ngStyle]="content.content.css"
      [ngStyle.lt-md]="mergeCss(content.content.css, content.content.cssMob)"
      ngClass.lt-md="banner-mob"
      fxLayout="row wrap"
      [fxLayoutAlign]="content.content.flex.fxLayoutAlign || 'center center'"
      [fxLayoutGap.lt-md]="'0px'"
      [fxLayoutGap.gt-sm]="content.content.flex.fxLayoutGap || '20px'"
    >
      <div
        *ngFor="let section of content.content.children"
        class="section"
        ngClass.lt-md="section-mob"
        fxFlex="0 0 auto"
        fxFlex.lt-md="100%"
        fxLayout="column nowrap"
        [fxLayoutAlign]="section.content.flex.fxLayoutAlign || 'center center'"
        [fxLayoutGap.lt-md]="'0px'"
        [fxLayoutGap.gt-sm]="section.content.flex.fxLayoutGap || '0px'"
        [ngStyle]="section.content.css"
      >
        <ng-container *ngFor="let html of section.content.children">
          <img
            *ngIf="html.content.type === 'img'"
            [src]="html.content.value"
            [ngStyle]="html.content.css"
            [routerLink]="html.content.link"
            alt=""
          />

          <button
            *ngIf="html.content.type === 'button'"
            [ngStyle]="html.content.css"
            [routerLink]="html.content.link"
            [queryParams]="html.content.queryParams"
            mat-raised-button
            color="primary"
          >
            {{ html.content.value }}
          </button>

          <div
            *ngIf="html.content.type === 'container'"
            [ngStyle]="html.content.css"
            fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
          >
            <ng-container *ngFor="let element of html.content.children">
              <button
                *ngIf="element.content.type === 'button'"
                mat-raised-button
                [ngStyle]="element.content.css"
                [routerLink]="element.content.link"
                [queryParams]="element.content.queryParams"
                color="primary"
              >
                {{ element.content.value }}
              </button>
            </ng-container>
          </div>

          <div
            *ngIf="html.content.type === 'text'"
            [ngStyle]="html.content.css"
            [innerHtml]="html.content.value"
            [routerLink]="html.content.link"
            fxLayout="column nowrap"
            [fxLayoutAlign]="section.content.flex.fxLayoutAlign || 'center center'"
            [fxLayoutGap.lt-md]="'0px'"
            [fxLayoutGap.gt-sm]="section.content.flex.fxLayoutGap || '0px'"
          ></div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
