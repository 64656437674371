<table mat-table [dataSource]="tableSource" matSort class="mat-elevation-z2">
    
    <ng-container matColumnDef="FtgKontaktNr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer nb. </th>
      <td mat-cell *matCellDef="let element"> {{element.FtgKontaktNr}} </td>
    </ng-container>
  
    <ng-container matColumnDef="FtgPerson">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.FtgPerson}} </td>
    </ng-container>

    <ng-container matColumnDef="ComBeskr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.ComBeskr}} </td>
    </ng-container>

    <ng-container matColumnDef="ComNr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.ComNr}} </td>
    </ng-container>
  
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef> Select </th>
      <td mat-cell *matCellDef="let element"><button mat-raised-button color="primary" (click)="selectCustomer(element)" [disabled]="element.FtgKontaktNr === null ? true:false">Select</button></td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 15, 30]" showFirstLastButtons></mat-paginator>