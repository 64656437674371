<div class="container" *ngIf="menuItems && menuOpen" (mouseleave)="menuOpen = false" fxLayout="row nowrap" fxLayoutAlign="center flex-start">
  <div class="submenu" fxLayout="row wrap" fxLayoutAlign="flex-start">

    <!-- Section group -->
    <div fxFlex="0 1 auto" class="section" *ngFor="let section of menuItems" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" fxLayoutGap="30px">
      
      <!-- Section header -->
      <span *ngIf="section.level2VrtDescr" fxFlex="100%" class="section-header" (click)="clickLink(section)">{{section.level2VrtDescr | uppercase}}</span>
      <span *ngIf="section.MenuDescr" fxFlex="100%" class="section-header" (click)="clickLink(section)">{{section.MenuDescr | uppercase}}</span>

      <!-- Category cols -->
      <div *ngFor="let colCount of getCatCols(section.children,8);let colIndex=index" class="category" fxLayout="column wrap" fxLayoutAlign="flex-start">
        <ng-container *ngFor="let category of section.children;let i=index">
          <span *ngIf="i >= colCount-8 && i < colCount" class="category-item">
            <a *ngIf="section.level2VrtDescr" (click)="clickLink(section,category)">{{category.level3VrtDescr | titlecase}}</a>
            <a *ngIf="section.MenuDescr" (click)="clickLink(section,category)">{{category.MenuDescr | titlecase}}</a>
          </span>
        </ng-container>
      </div>

    </div>
  </div>
</div>