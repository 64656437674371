
<div style="padding:10px;">
    <mat-form-field>
        <input matInput 
            [formControl]="dateSelect"
            [matDatepicker]="picker"
            [min]="dateMin"
            [max]="dateMax"
            placeholder="Delivery date"
            (dateChange)="evaluate()">

        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-error *ngIf="dateSelect.getError('dateMin') && dateSelect.value">
        Minimum date is {{isoString(dateMin)}}
    </mat-error>

    <mat-error *ngIf="dateSelect.getError('dateMax') && dateSelect.value">
        Maximum date is {{isoString(dateMax)}}
    </mat-error>
</div>