import { Component, OnInit, Input } from '@angular/core';
import { Invoice } from '@classes/invoices';
import { DataService } from '@services/data.service';
import { DefaultDialogComponent } from '@components/dialog/default-dialog/default-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-invoice-head',
  templateUrl: './invoice-head.component.html',
  styleUrls: ['./invoice-head.component.css']
})
export class InvoiceHeadComponent implements OnInit {

  @Input() invoicenb: number;

  public response: Invoice;
  public invoice: Invoice;


  constructor(private api: DataService, private dialog: MatDialog) { }

  ngOnInit() {
    this.getInvoice(this.invoicenb);
  }

  getInvoice(invoicenb: number): void {
    const endpoint: string = '/jeeves/invoice/' + invoicenb;
    const headers: any = [];

    this.api.getData(endpoint, headers).subscribe(data => {
      this.response = data as Invoice;
      this.processResponse();
    });
  }

  processResponse(): void {
    this.invoice = this.response;
  }

  openDialog(ordernr: string): void {

    const dialogRef = this.dialog.open(DefaultDialogComponent, {
      height: '85%',
      width: '85%',
      data: {component: 'pacsofttrack', data: ordernr}
    });
  }
}
