import { Component, OnInit } from '@angular/core';
import { Cart, CartItem } from '@classes/cart';
import { CartService } from '@services/cart.service';

@Component({
  selector: 'app-carticon',
  templateUrl: './carticon.component.html',
  styleUrls: ['./carticon.component.css']
})
export class CarticonComponent implements OnInit {

  public cartSubTotal = 0;
  public cartSubQty = 0;

  constructor(private cart: CartService) { }

  ngOnInit() {

    this.cart.cartQty$.subscribe(res => this.cartSubQty = res);
    this.cart.cartSum$.subscribe(res => this.cartSubTotal = res);

   }
}
