import { Component, OnInit } from '@angular/core';
import { ContentService } from '@app/modules/content-core/services/content.service';

@Component({
  selector: 'app-home-route',
  templateUrl: './home-route.component.html',
  styleUrls: ['./home-route.component.css']
})
export class HomeRouteComponent implements OnInit {

  constructor(public contentService: ContentService) { }

  ngOnInit() { }

}
