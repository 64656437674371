import { Component, OnInit } from '@angular/core';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.css']
})
export class ProgressbarComponent implements OnInit {

  public isActive = false;

  constructor(public app: AppService) { }

  ngOnInit() {
    this.app.isLoading$.subscribe(res => {
      this.isActive = res;
    });
  }

}
