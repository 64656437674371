
  <div fxHide.lt-md>
    <mat-slide-toggle (change)="toggleImages()" [checked]="showImages">Show images</mat-slide-toggle>

    <div *ngFor="let delivery of tableSourceDelivery" style="margin-top:20px;">
      <span class="no-padding">
        Delivery {{delivery.delivery}}
      </span>
      <table mat-table [dataSource]="delivery.items" class="mat-elevation-z2">
        <ng-container matColumnDef="imageUrl" *ngIf="showImages">
          <th mat-header-cell *matHeaderCellDef> Image </th>
          <td mat-cell *matCellDef="let element"> <img src="{{env.arImg300 + element.fields.imageUrl}}" style="max-width:100px;max-height:80px;"></td>
        </ng-container>

        <ng-container matColumnDef="ArtNr">
          <th mat-header-cell *matHeaderCellDef > Article no. </th>
          <td mat-cell *matCellDef="let element"> <a routerLink="/articles/{{element.fields.PvmMasterItem}}">{{element.fields.ArtNr}}</a></td>
        </ng-container>

        <ng-container matColumnDef="ArtBeskr">
          <th mat-header-cell *matHeaderCellDef > Name </th>
          <td mat-cell *matCellDef="let element; let i = index"> {{element.fields.ArtBeskr}}</td>
        </ng-container>

        <ng-container matColumnDef="PVMVariantDescr">
          <th mat-header-cell *matHeaderCellDef > Color </th>
          <td mat-cell *matCellDef="let element"> {{element.fields.PVMVariantDescr}} </td>
        </ng-container>

        <ng-container matColumnDef="PVMVariantDescr2">
          <th mat-header-cell *matHeaderCellDef > Size </th>
          <td mat-cell *matCellDef="let element"> {{element.fields.PVMVariantDescr2}} </td>
        </ng-container>

        <ng-container matColumnDef="Pris">
          <th mat-header-cell *matHeaderCellDef > Price </th>
          <td mat-cell *matCellDef="let element"> {{cart.getPrice(element) | currency:'SEK':'symbol-narrow'}} </td>
        </ng-container>

        <ng-container matColumnDef="Rabatt">
            <th mat-header-cell *matHeaderCellDef > Discount </th>
            <td mat-cell *matCellDef="let element"> {{element.fields.Rabatt/100 | percent}} </td>
          </ng-container>

        <ng-container matColumnDef="itemqty">
          <th mat-header-cell *matHeaderCellDef > Quantity </th>
          <td mat-cell *matCellDef="let element"> 
            <input type="number" placeholder="{{element.itemqty}}" [disabled]="cartLoading" 
              value="{{element.itemqty}}" max="{{element.fields.ArtDisp}}" 
              style="width:50px;"
              [class.error]="element.fields.ArtDisp < element.itemqty"
              (change)="cartItemQty(element, $event.target.value)"
              >
          </td>

        </ng-container>

        <ng-container matColumnDef="Remove">
          <th mat-header-cell *matHeaderCellDef> Remove </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="!showDateSelect">
              <i (click)="cartItemRemove(element)" class="fa fa-trash" matTooltipPosition="below" matTooltip="Remove"></i>
              <i (click)="showDateSelect = true" class="far fa-calendar-alt" aria-hidden="true" matTooltipPosition="below" matTooltip="Delivery date"></i>
            </ng-container>
            <ng-container *ngIf="showDateSelect">
              <div fxLayout="row nowrap" fxLayoutAlign="flex-start center">
                <app-datepicker [dateDefault]="element.deliverydat" (output)="cartItemDelivery(element, $event)"></app-datepicker>
                <i (click)="showDateSelect = false" class="far fa-window-close" aria-hidden="true" matTooltipPosition="below" matTooltip="Close"></i>
              </div>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>



  
  <div fxHide fxShow.lt-md class="container-mob" fxLayout="row wrap" *ngFor="let delivery of tableSourceDelivery" style="margin-top:20px;">
      <span class="header-dark">
        Delivery {{delivery.delivery}}
      </span>
      <mat-card class="section" *ngFor="let item of delivery.items.filteredData" fxFlex="100%" fxLayout="row wrap">
          <div fxFlex="100%" class="header">
            {{item.fields.ArtBeskr | uppercase}} {{item.fields.PVMVariantDescr | uppercase}} {{item.fields.PVMVariantDescr2 | uppercase}}
          </div>

        <div class="section-image" fxFlex="40%">
          <img src="{{env.arImg300 + item.fields.imageUrl}}" alt="">
        </div>

        <div class="section-data" fxFlex="60%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
            <div fxFlex="60%">Article nb:</div>
            <div fxFlex="40%" style="text-align: center;"><a routerLink="/articles/{{item.fields.PvmMasterItem}}">{{item.itemnb}}</a></div>

            <div fxFlex="60%">Quantity:</div>
            <div fxFlex="40%" style="text-align: center;">
                <input type="number" placeholder="{{item.itemqty}}" [disabled]="cartLoading" 
                (change)="cartItemQty(item, $event.target.value)"
                [class.error]="item.fields.ArtDisp < item.itemqty"
                value="{{item.itemqty}}" max="{{item.fields.ArtDisp}}" style="width:40px;">
            </div>

            <div fxFlex="60%">Price:</div>
            <div fxFlex="40%" style="text-align: center;">{{cart.getPrice(item) | currency:'SEK':'symbol-narrow'}}</div>

            <ng-container *ngIf="item.fields.Rabatt">
              <div fxFlex="60%">Discount:</div>
              <div fxFlex="40%" style="text-align: center;">{{item.fields.Rabatt/100 | percent}}</div>
            </ng-container>

            <div fxFlex="60%"></div>
            <div fxFlex="40%" fxLayout="row nowrap" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-mini-fab color="accent" (click)="cartItemRemove(item)">
                  <i class="fa fa-trash" aria-hidden="true" matTooltipPosition="below" matTooltip="Remove"></i>
                </button>
                <app-datepicker [formType]="'icon'" [dateDefault]="item.deliverydat" (output)="cartItemDelivery(item, $event)"></app-datepicker>
            </div>
        </div>
      </mat-card>
  </div>