import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ExportData, ExportStatus, ExportList, ExportItem, ExportStorage } from '@classes/export';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})

export class ExportService {

  exportList$: BehaviorSubject<ExportList>;
  exportStorage: ExportStorage = new ExportStorage('ExportService');

  constructor(private msgSrv: MessageService) {
    const storedExportList = this.exportStorage.get();
    this.exportList$ = new BehaviorSubject(storedExportList);

    this.exportList$.subscribe(exportList => {
      if (exportList) {
        this.exportStorage.save(exportList);
      }
    });
  }

  add(exportItem: ExportItem): void {
    const exportList = this.exportList$.getValue();
    exportList.set(exportItem);
    this.exportList$.next(exportList);

    this.msgSrv.send({
      type: 'snackbar'
      , message: 'Adding data to export list'
      , source: 'Export Service'
      , duration: 2000
    });
  }

  clear(type: string): void {
    const exportList = this.exportList$.getValue();
    exportList.clear(type);
    this.exportList$.next(exportList);

    this.msgSrv.send({
      type: 'snackbar'
      , message: 'Removing data from export list'
      , source: 'Export Service'
      , duration: 2000
    });
  }
}
