<div class="mob-container" fxLayout="row wrap" fxHide fxShow.lt-md>
  <mat-card *ngFor="let row of response" class="mob-card" fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
    <div class="mob-header" fxFlex="100%">{{row.Artikel.PvmMasterItem}} {{row.Artikel.ArtBeskr}} {{row.Artikel.PVMVariantDescr}} {{row.Artikel.PVMVariantDescr2}}</div>
    <div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div fxFlex="105px">
        <div class="mob-image"> <img src="{{env.arImg300 + row.Artikel.imageUrl}}" alt="Item image"> </div>
      </div>

      <div fxFlex="60%" fxLayout="column" fxLayoutAlign="flex-start flex-end">

        <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
          <div>Article nb</div>
          <div>{{row.Artikel.ArtNr}}</div>
        </div>

        <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
          <div>Price</div>
          <div>{{row.vb_pris | currency:'SEK':'symbol-narrow'}}</div>
        </div>

        <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
          <div>Discount</div>
          <div>{{row.VolymRabatt/100 | percent}}</div>
        </div>

        <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
          <div>Qty</div>
          <div>{{row.OrdAntal}}</div>
        </div>

        <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
          <div>Delivery</div>
          <div>{{row.OrdBerLevDat | date:'shortDate'}}</div>
        </div>

        <div class="mob-itemdata"  fxLayout="row wrap" fxLayoutAlign="space-between flex-start">
          <div>Sum</div>
          <div>{{row.itemSum | currency:'SEK':'symbol-narrow'}}</div>
        </div>

      </div>
    </div>
  </mat-card>
</div>


<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2" fxHide.lt-md>
  
  <ng-container matColumnDef="OrdRadnr">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Order row no. </th>
    <td mat-cell *matCellDef="let element"> {{element.OrdRadnr}}</td>
  </ng-container>

  <ng-container matColumnDef="OrdBerLevDat">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery date </th>
    <td mat-cell *matCellDef="let element"> {{element.OrdBerLevDat | date:'shortDate'}} </td>
  </ng-container>

  <ng-container matColumnDef="PvmMasterItem">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Item no. </th>
    <td mat-cell *matCellDef="let element"> <a *ngIf="element.Artikel.PvmMasterItem != 'FR1'" routerLink="/articles/{{element.Artikel.PvmMasterItem}}">{{element.Artikel.PvmMasterItem}}</a> </td>
  </ng-container>

  <ng-container matColumnDef="ArtBeskr">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.Artikel.ArtBeskr}} </td>
  </ng-container>

  <ng-container matColumnDef="PVMVariantDescr">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
    <td mat-cell *matCellDef="let element"> {{element.Artikel.PVMVariantDescr}} </td>
  </ng-container>

  <ng-container matColumnDef="PVMVariantDescr2">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
      <td mat-cell *matCellDef="let element"> {{element.Artikel.PVMVariantDescr2}} </td>
    </ng-container>
  
  <ng-container matColumnDef="OrdAntal">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity </th>
    <td mat-cell *matCellDef="let element"> {{element.OrdAntal}} </td>
  </ng-container>

  <ng-container matColumnDef="vb_pris">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
    <td mat-cell *matCellDef="let element"> {{element.vb_pris | currency:'SEK':'symbol-narrow'}} </td>
  </ng-container>

  <ng-container matColumnDef="VolymRabatt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Discount </th>
    <td mat-cell *matCellDef="let element"> {{element.VolymRabatt/100 | percent}} </td>
  </ng-container>

  <ng-container matColumnDef="itemSum">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Row sum </th>
    <td mat-cell *matCellDef="let element"> {{element.itemSum | currency:'SEK':'symbol-narrow'}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator *ngIf="paginate" [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons fxHide.lt-md></mat-paginator>
