import { Component, OnInit, OnDestroy } from '@angular/core';
import { JvsarticlefilterService } from '@services/jvsarticlefilter.service';
import { AppService } from '@services/app.service';

import { ProdTyp } from '@classes/filter';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-articles-filters',
  templateUrl: './articles-filters.component.html',
  styleUrls: ['./articles-filters.component.css']
})
export class ArticlesFiltersComponent implements OnInit, OnDestroy {

  filters: ProdTyp[] = null;
  test: Subscription;
  panelState: any;

  constructor(private jvsArtFilter: JvsarticlefilterService, private app: AppService) { }

  ngOnInit() {
    this.test = this.jvsArtFilter.filterOutput$.subscribe(filters => {
      if (filters) {
        this.filters = [...filters];
      }
    });

    this.panelState = this.app.view.getValue('filterPanel', Array()).value;
  }


  ngOnDestroy() {
    this.test.unsubscribe();
  }

  updateFilter(group, option) {

    this.jvsArtFilter.setFilter(group, option);
  }

  resetFilters() {
    this.jvsArtFilter.resetFilterForm();
  }

  objectState(view: string, id: any, state: any ) {
    if (this.panelState === null) {
      this.panelState = Array();
    }

    this.panelState[id] = state;
    this.app.view.setValue(view, this.panelState, false);
  }

  isActive(id: number) {
    if (typeof (this.panelState[id]) !== 'undefined') {
      return this.panelState[id];
    } else {
      return false;
    }
  }
}
