import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { DataService } from '@services/data.service';
import { Customer } from '@classes/customer';

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.css']
})
export class CustomerSearchComponent implements OnInit {

  @Output() output: EventEmitter<Customer[]> = new EventEmitter<Customer[]>();

  search: any = '';
  results: Customer[];

  constructor(private api: DataService) { }

  ngOnInit() {
  }

  getData() {
    const endpoint = '/jeeves/customers/';
    const options: any = {params: {search: this.search} };

    this.api.getData(endpoint, options).subscribe(data => {
      if (!data) {
        this.results = Array();
      } else {
        this.results = data as Customer[];
      }

      this.emitResult();
    });
  }

  emitResult() {
    this.output.emit(this.results);
  }

}
