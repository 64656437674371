import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { JvscontactService } from '@services/jvscontact.service';

import { Customer, Contact, ContactFlat, ComData } from '@classes/customer';


@Component({
  selector: 'app-contact-table-crud',
  templateUrl: './contact-table-crud.component.html',
  styleUrls: ['./contact-table-crud.component.css']
})
export class ContactTableCrudComponent implements OnInit {

  @Input() customer: Customer;
  @Input() contacts: Contact[];

  @Output() output: EventEmitter<Contact> = new EventEmitter<Contact>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public tableSource: MatTableDataSource<ContactFlat>;
  public displayedColumns: string[] = ['FtgKontaktNr', 'FtgPerson', 'ComBeskr', 'ComNr', 'select'];

  constructor(private dialog: MatDialog, private jvsContact: JvscontactService) { }

  ngOnInit() {
    switch (true) {
      case (this.contacts !== undefined):
        this.processContacts(this.contacts);
        break;
      case (this.customer !== undefined) :
        this.getContacts(this.customer);
        break;
    }
  }

   selectCustomer(contact: Contact) {
    this.output.emit(contact);
   }


  getContacts(customer: Customer) {
    this.customer = customer;

    this.jvsContact.getContacts(this.customer.FtgNr).subscribe((contacts: Contact[]) => {
      this.contacts = contacts;
      this.processContacts(contacts);
    });
  }

  processContacts(contacts: Contact[]) {
    const flatContacts: Array<ContactFlat> = this.flatten(contacts);

    this.tableSource = new MatTableDataSource<ContactFlat>(flatContacts);
    this.tableSource.paginator = this.paginator;
    this.tableSource.sort = this.sort;

  }

  flatten(contacts: Contact[]) {
     const result = Array();

     contacts.forEach((contact: Contact) => {
      const flat = {} as ContactFlat;
      flat.FtgPerson = contact.FtgPerson;
      flat.FtgKontaktNr = contact.FtgKontaktNr;

      contact.ComData.forEach((comdata: ComData) => {
        flat.ComKod = comdata.ComKod;
        flat.ComBeskr = comdata.ComBeskr;
        flat.ComNr = comdata.ComNr;

        result.push({... flat});
      });
    });

     return result;
  }
}
