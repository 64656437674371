import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { CartService } from '@services/cart.service';
import { UserService } from '@services/user.service';
import { Cart } from '@classes/cart';


@Component({
  selector: 'app-cartlist-table',
  templateUrl: './cartlist-table.component.html',
  styleUrls: ['./cartlist-table.component.css']
})

export class CartlistTableComponent implements OnInit {

  public isDone = false;
  public cartactive: number = null;

  public tableSource: MatTableDataSource<Cart>;
  public displayedColumns: string[] = Array(
    'rowcreate', 'comment', 'shared', 'selected', 'actions');

  constructor(private cart: CartService, public user: UserService) { }

  ngOnInit() {
    this.cart.cartlist$.subscribe(cartlist => {
      if (cartlist) {
        this.updateTable(cartlist);
      } else {
        this.isDone = false;
      }

    });

    this.cart.cartactive$.subscribe(cartactive => {
      this.cartactive = cartactive;
    });
  }

  updateTable(cartlist: Cart[]) {
    this.tableSource = new MatTableDataSource<Cart>(cartlist);
    this.isDone = true;
  }

  updateCart(index: number, field: string) {
    const payload: any = {[field]: this.tableSource.data[index][field]};
    this.cart.updateCart(payload, this.tableSource.data[index].cartid);
  }

  deleteCart(cartid: number) {
    // Add "confirm" dialog
    this.cart.deleteCart(cartid);
  }

  isShared(data: any) {
    if (!data || data === '0') {
      return false;
    } else {
      return true;
    }
  }
}
