<div class="dialog">
  <div fxLayout="row wrap" class="container" fxLayoutAlign="flex-start flex-start">
    <div fxFlex="100%">
      
      <app-customer-search (output)="onSearch($event)"></app-customer-search>
  
    </div>
  
    <div fxFlex="100%">
      <ng-container *ngIf="search">
        <app-customer-table [input]="searchResult$" (output)="onSelect($event)"></app-customer-table>
      </ng-container>
    </div>
  </div>
</div>