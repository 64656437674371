import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';

import { AppService } from '@services/app.service';
import { JvsorderService } from '@services/jvsorder.service';
import { JvscustomerService } from '@services/jvscustomer.service';
import { CartService } from '@services/cart.service';
import { Order } from '@classes/order';
import { Cart } from '@classes/cart';
import { Customer } from '@classes/customer';


@Component({
  selector: 'app-cartmain',
  templateUrl: './cartmain.component.html',
  styleUrls: ['./cartmain.component.css']
})
export class CartmainComponent implements OnInit {

  customers$: BehaviorSubject<Customer> = new BehaviorSubject(null);

  activeCart: Cart = null;
  activeCustomer: Customer = null;

  result: {'status': boolean, 'data'?: Order};

  orderSub$: BehaviorSubject<{'status': boolean, 'data'?: Order}> = new BehaviorSubject({status: null});

  termsConfirmed = false;
  stepperComplete = false;
  isDone = false;
  isEmpty = true;
  creditValidity = true;

  constructor(
    private jvsorder: JvsorderService,
    private customers: JvscustomerService,
    private cart: CartService,
    private app: AppService) { }

  ngOnInit() {

    this.cart.cart$.subscribe(res => {
      this.activeCart = res;
    });

    this.cart.cartQty$.subscribe(qty => {
        this.isEmpty = Boolean(!qty);
    });

    this.orderSub$.subscribe(res => {
      this.subStatus(res);
    });

    this.customers$.subscribe(customer => {
      this.activeCustomer = customer;
    });

    this.customers.getCustomers().subscribe(customer => {
      this.customers$.next(customer);
      this.hasCredit(customer);
    });

   }


  checkConfirm(checkbox: any) {
    this.termsConfirmed = checkbox;
  }

  hasCredit(customer) {
    if (customer) {
      switch (customer.kreditsparr) {
        case '1':
          this.creditValidity = false;
          break;

        default:
          this.creditValidity = true;
      }
    }
  }

  subStatus(result: {'status': boolean, 'data'?: Order}) {

    if (result.status === false) {
      this.app.start();
    }

    if (result.status) {
      this.app.done();
      this.isDone = true;
      this.result = result;

      if (this.result.data.OrderNr) {
        this.cart.closeCart();
      }
    }
  }


  confirm(stepper: MatStepper) {
    if (!this.termsConfirmed) {
      return;
    }

    this.stepperComplete = true;
    stepper.next();

    this.orderSub$.next({status: false});

    const orderTest: Order = this.jvsorder.convertCart(this.activeCart);

    this.jvsorder.postOrder(orderTest).subscribe(res => {
      this.cart.deliveryDate$.next(null);
      this.orderSub$.next({status: true, data: res});
    });
  }
}
