import { Component, OnInit } from '@angular/core';
import { ExportService } from '@app/services/export.service';
import { Article } from '@app/classes/article';

@Component({
  selector: 'app-export-route',
  templateUrl: './export-route.component.html',
  styleUrls: ['./export-route.component.css']
})
export class ExportRouteComponent implements OnInit {

  articles: Article[] = Array();
  showTable = false;

  constructor(private expSrv: ExportService) { }

  ngOnInit() {

    this.expSrv.exportList$.subscribe(exportList => {
      const exportItem = exportList.get('articleExport');

      if (exportItem) {
        this.articles = exportItem.data;
      } else {
        this.articles = Array();
      }
    });
  }

  clear() {
    this.expSrv.clear('articleExport');
  }

}
