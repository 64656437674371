import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@services/auth.service';

import { Login } from '@classes/user';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public redirect = '';
  public formState = true;
  public isLoading = false;

  public loginForm = new FormGroup({
    username: new FormControl('', [ Validators.required ]),
    password: new FormControl('', [ Validators.required ]),
    sessionStorage: new FormControl(false)
  });


  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
      this.route.queryParams.subscribe(params => this.redirect = params.redirect || 'dashboard');

      this.auth.isAuth.subscribe(isAuth => {
        this.router.navigateByUrl(this.redirect);
      });

      this.auth.isPending.subscribe(isPending => {
        switch (isPending) {
          case true:
            this.isLoading = true;
            this.loginForm.disable();
            break;

          case false:
            this.isLoading = false;
            this.loginForm.enable();
            break;
        }
      });

    }

  login() {
    this.auth.sessionStorage = !this.loginForm.get('sessionStorage').value;
    this.auth.login(
      this.loginForm.get('username').value,
      this.loginForm.get('password').value
    );
  }


  logout() {
    this.auth.logout();
  }

}
