import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JvsarticleService } from '@services/jvsarticle.service';

import { Article } from '@classes/article';
import { BehaviorSubject } from 'rxjs';
import { AppService } from '@services/app.service';
import { CartService } from '@app/services/cart.service';

@Component({
  selector: 'app-article-route',
  templateUrl: './article-route.component.html',
  styleUrls: ['./article-route.component.css']
})
export class ArticleRouteComponent implements OnInit {

  public isDone = false;
  public articles: Article[] = null;
  public matrix = null;

  public matrixState: BehaviorSubject<Article> = new BehaviorSubject(null);
  public activeArticle: Article;
  public activeRoute: string;
  public deliveryDate: string;

  constructor(
    private route: ActivatedRoute,
    private JvsArt: JvsarticleService,
    private app: AppService,
    public cart: CartService
  ) { }

  ngOnInit() {

    this.deliveryDate = new Date().toISOString().slice(0, 10);
    this.activeRoute = null;

    this.route.params.subscribe(res => {
      if (res.artnr) {
        this.activeRoute = res.artnr;
        this.loadData(this.activeRoute, this.deliveryDate, true);
      }
    });

    this.cart.deliveryDate$.subscribe(date => {
      if (date) {
        // Hack to avoid summertime issues
        this.deliveryDate = date.toISOString().slice(0, 10);
        this.loadData(this.activeRoute, this.deliveryDate);
      }
    });

    this.matrixState.subscribe(article => {
      if (article) {
        this.activeArticle = article;
      }
    });
  }

  loadData(artnr: string, date: string = null, resState = false) {
    if (!artnr) {
      return;
    }

    this.app.start();

    this.JvsArt.getAr(artnr, date).subscribe(articles => {

      // Bad copy sollution sollution
      this.articles = JSON.parse(JSON.stringify(articles));

      this.matrix = this.JvsArt.calculateMatrix(articles);

      if (!this.matrixState.getValue() || resState) {
        this.matrixState.next(this.matrix.colors[0]);
      }

      // Reads color number from router queryParams and selects appropriate color </articles/86397?PVMVariant1=C3432>
      this.route.queryParams
        .subscribe(params => {
          if (params.PVMVariant1) {
            const select = this.articles.filter(article => article.PVMVariant1 === params.PVMVariant1);

            if(select && select.length > 0) {
              this.matrixState.next(select[0]);
            }
          }
        }
      );

      this.app.done();
      this.isDone = true;
    });
  }
}
