import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Menu, MenuItems } from '@classes/menu';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.css']
})
export class MenuListComponent implements OnInit {
  @Input() menuList: Menu[] = null;
  @Output() output: EventEmitter<MenuItems[]> = new EventEmitter(null);

  constructor(private router: Router) { }

  ngOnInit() {
  }

  menuSelect(i: number) {
    this.output.emit(this.menuList[i].children);
  }

  route(route: string) {
    this.router.navigate([route]);
  }

}
