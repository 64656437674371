import { Injectable } from '@angular/core';

import { DataService } from '@services/data.service';
import { Order, OrderResponse } from '@classes/order';
import { Cart } from '@classes/cart';
import { Observable } from 'rxjs';


@Injectable()
export class JvsorderService {

  endpointOrder = '/jeeves/order/{OrderNr}';
  endpointOrders = '/jeeves/orders/{OrderNr}';
  endpointOrdersActive = '/jeeves/ordersdel/active';

  constructor(private api: DataService) {

  }

  convertCart(cart: Cart) {
    const jvsorder: Order = {};

    jvsorder.OrdLevPlats1 = cart.meta.levplats;
    jvsorder.Editext = cart.meta.comment;
    jvsorder.q_wc_custmail = cart.meta.wEmailAddress;

    jvsorder.OrderRader = [];

    for (const item of cart.items) {
      jvsorder.OrderRader.push({
        ArtNr: item.itemnb,
        OrdAntal: item.itemqty,
        OrdBerLevDat: item.deliverydat
      });
    }

    return jvsorder;
  }

  getOrders(ordernr: string) {
    const endpoint = this.endpointOrders.replace('{OrderNr}', ordernr);

    return this.api.getData(endpoint, []);
  }

  getOrder(ordernr: string) {
    const endpoint = this.endpointOrder.replace('{OrderNr}', ordernr);

    return this.api.getData(endpoint, []);
  }

  getActive( ) {
    const endpoint = this.endpointOrdersActive;

    return this.api.getData(endpoint, []);
  }

  postOrder(order: Order): Observable<OrderResponse> {
    let endpoint = this.endpointOrder;

    if (order.OrderNr) {
      endpoint = endpoint.replace('{OrderNr}', order.OrderNr);
    } else {
      endpoint = endpoint.replace('{OrderNr}', '');
    }

    return this.api.postData(endpoint, order, []);
  }


}
