import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TermsComponent } from '@components/orders/terms/terms.component';

import { DataService } from '@services/data.service';
import { MessageService } from '@services/message.service';

import { Registration } from '@classes/user';
import { CustomValidators } from '@modules/utility/utility';

@Component({
  selector: 'app-login-reg',
  templateUrl: './login-reg.component.html',
  styleUrls: ['./login-reg.component.css']
})
export class LoginRegComponent implements OnInit {

  regForm: FormGroup;
  user: Registration = new Registration();
  submitted = false;
  passwordMatch = true;
  CustomValidator = new CustomValidators();

  constructor(private api: DataService, private message: MessageService, private dialog: MatDialog) {

    this.regForm = new FormGroup({
      shop_name: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      terms: new FormControl(false, [Validators.requiredTrue]),

      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        this.CustomValidator.regTest(/[A-Z]/, true, 'upperCase'),
        this.CustomValidator.regTest(/[a-z]/, true, 'lowerCase'),
        this.CustomValidator.regTest(/[0-9]/, true, 'number'),
        // this.CustomValidator.regTest(/[\!\"\#\¤\%\&\/\(\)\=\?\¨\^\'\*\<\>\,\;\.\:\-\_\§\½\@\£\$\€\{\}\[\]]/, true, 'special'),
        this.CustomValidator.regTest(/\W|_/, true, 'special'),
        this.CustomValidator.regTest(/\s/g, false, 'blank')
      ]),
      confirm: new FormControl('', [
        Validators.required
      ])
    });
  }

  ngOnInit() {
    this.regForm.get('confirm').valueChanges.subscribe(confirm => {
      if (this.regForm.get('password').value === confirm) {
        this.passwordMatch = true;
      } else {
        this.passwordMatch = false;
        this.regForm.get('confirm').setErrors({confirm: {confirm: false}});
      }
    });
  }


  submit() {
    if (this.regForm.valid) {
      this.user = this.regForm.value as Registration;

      // If passwords not matching then stop
      if (this.user.password !== this.user.confirm) {
        this.passwordMatch = false;
        return;
      }

      this.regForm.disable();
      this.postData(this.user);
    }
  }

  postData(user: Registration) {
    const endpoint = '/app/user/registration';
    const header: any = [];
    const params: { registration: Registration } = {registration: user};

    this.api.postData(endpoint, params, header).subscribe(
      (data) => this.success(),
      (error) => this.error(error)
    );

  }

  error(error) {

    console.log(error);

    // this.regForm.get('password').getError('capitol');

    // FIX????
    this.regForm.enable();

    this.message.send({
      type: 'snackbar'
      , message: error.message
      , source: 'Registration'
      , duration: 4000
    });
  }

  success() {
    this.submitted = true;
    this.regForm.reset();
    this.regForm.enable();
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(TermsComponent, {
      height: '90%',
      width: '70%',
      data: {template: 'customer'}
    });

    const sub = dialogRef.componentInstance.output.subscribe(res => {
      if (res) {

        this.regForm.patchValue({
          terms: true
        });
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

}
