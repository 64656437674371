export interface ExportData {
    name: string;
    data: any[];
}

export interface ExportStatus {
    count: number;
    data: ExportData[];
}


export class ExportStorage {
    id: string;

    constructor(id: string) {
        this.id = id;
    }

    save(exportList: ExportList) {
        localStorage.setItem(this.id, exportList.toJson());
    }

    get(): ExportList {
        const json = localStorage.getItem(this.id);

        if (!json) {
            return new ExportList();
        }

        const exportData = JSON.parse(json) as ExportList;

        if (exportData.exports === undefined) {
            this.clear();

            return new ExportList();
        }

        const exportList = new ExportList();

        exportData.exports.forEach(exportItem => {
            const item = new ExportItem(exportItem.type, exportItem.data)
            exportList.set(item);
        });

        return exportList;
    }

    clear(): void {
        localStorage.removeItem(this.id);
    }
}

export class ExportItem {
    type: string;
    count: number;
    data: Array<any>;

    constructor(type: string, data: Array<any>) {
        this.type = type;
        this.set(data);
    }

    set(data: Array<any>) {
        this.data = data;
        this.setCount();
    }

    setCount(): void {
        this.count = this.data.length;
    }
}

export class ExportList {
    exports: ExportItem[];

    constructor() {
        this.exports = Array();
    }

    set(exportItem: ExportItem) {
        let hasIndex: number = null;
        this.exports.forEach((exp, index) => {
            if (exportItem.type === exp.type) {
                hasIndex = index;
            }
        });

        if (hasIndex != null) {
            this.exports[hasIndex].set(this.exports[hasIndex].data.concat(exportItem.data));
        } else {
            this.exports.push(exportItem);
        }
    }

    get(type: string): ExportItem {
        const found = this.exports.find(item => item.type === type);

        if (found === undefined) {
            return null;
        }

        return found;
    }

    clear(type: string = null): void {
        if (type == null) {
            this.exports = Array();
        } else {
            let clearIndex = null;

            this.exports.forEach((exportItem, index) => {
                if (exportItem.type === type) {
                    clearIndex = index;
                }
            });

            if (clearIndex != null) {
                this.exports.splice(clearIndex, 1);
            }
        }
    }

    toJson(): string {
        return JSON.stringify(this);
    }

    fromJson(json: string) {
        const objects = JSON.parse(json);
    }
}
