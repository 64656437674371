import { Component, OnInit } from '@angular/core';
import { AppService } from '@services/app.service';
import { JvscustomerService } from '@services/jvscustomer.service';
import { Customer, Contact } from '@classes/customer';
import { JvscontactService } from '@services/jvscontact.service';


@Component({
  selector: 'app-customer-route',
  templateUrl: './customer-route.component.html',
  styleUrls: ['./customer-route.component.css']
})
export class CustomerRouteComponent implements OnInit {
  public customer: Customer;
  public contacts: Contact[];

  public isDone = false;

  constructor(
    private app: AppService, private jvscustomer: JvscustomerService, private jvsContact: JvscontactService) { }

  getCustomer() {

    this.app.start();

    this.jvscustomer.getCustomers().subscribe((data: Customer) => {
        this.customer = data;
        this.getContacts();
        this.processResponse();
      });
  }

  getContacts() {
    this.jvsContact.getContacts(this.customer.FtgNr).subscribe((contacts: Contact[]) => {
      this.contacts = contacts;
    });
  }

  processResponse() {
    this.app.done();
    this.isDone = true;
  }

  ngOnInit() {
    this.getCustomer();
  }


}
