<div class="container" fxLayout="column nowrap">
  <mat-divider></mat-divider>
  <div class="primary" fxLayout="row nowrap" fxLayoutAlign="space-between">
    <div class="left">Total (ex VAT):</div>
    <div class="right">{{cartSubTotal | currency:'SEK':'symbol-narrow'}}</div>
  </div>
  <mat-divider></mat-divider>

  <div class="secondary top-margin" fxLayout="row nowrap" fxLayoutAlign="space-between">
    <div class="left">Quantity:</div>
    <div class="right">{{cartSubQty}}</div>
  </div>

  <div class="secondary" fxLayout="row nowrap" fxLayoutAlign="space-between">
    <div class="left">VAT:</div>
    <div class="right">{{cartSubVat | currency:'SEK':'symbol-narrow'}}</div>
  </div>

  <div class="secondary" fxLayout="row nowrap" fxLayoutAlign="space-between">
    <div class="left">Total (inc VAT):</div>
    <div class="right">{{ (cartSubTotal + cartSubVat) | currency:'SEK':'symbol-narrow'}}</div>
  </div>
  <mat-divider></mat-divider>
</div>