
<mat-tab-group>

  <mat-tab label="Orders">
    <ng-template matTabContent>
      <div class="component-container">
        <app-ordersopen></app-ordersopen>
      </div>
    
      <div class="component-container">
        <app-invoicetable></app-invoicetable>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab label="Details">
    <ng-template matTabContent>
      <ng-container *ngIf="isDone">
      <div class="component-container">

        <div fxLayout="row wrap" fxLayoutAlign="space-between flex-start">

          <div fxFlex="39%" fxFlex.lt-md="100%">
            <app-customer-details [customer]="customer"></app-customer-details>
          </div>

          <div fxFlex="58%" fxFlex.lt-md="100%">
            <mat-card *ngIf="contacts">
              <h3>Contacts</h3>
              <app-contact-list [input]="contacts" [customer]="customer" (output)="getContacts()"></app-contact-list>
              <!-- <app-contact-add [customer]="customer" [contacts]="contacts" (output)="getContacts()"></app-contact-add> -->
            </mat-card>
          </div>

        </div>

      </div>
      </ng-container>
    </ng-template>
  </mat-tab>  
  
</mat-tab-group>
