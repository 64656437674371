import { Component, OnInit, Input } from '@angular/core';
import { Article, ProductImage } from '@classes/article';
import { JvsarticleService } from '@services/jvsarticle.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
import { MatDialog } from '@angular/material/dialog';
import { Images } from '@classes/dialog';
import { ImageDialogComponent } from '@components/dialog/image-dialog/image-dialog.component';

@Component({
  selector: 'app-article-img',
  templateUrl: './article-img.component.html',
  styleUrls: ['./article-img.component.css']
})
export class ArticleImgComponent implements OnInit {
  @Input() article: BehaviorSubject<Article>;

  public env = environment;
  public activeArticle: Article = null;
  public isDone = false;
  public view = null;
  public productImages = Array();



  constructor(private JvsArt: JvsarticleService, private dialog: MatDialog) { }

  ngOnInit() {
    this.article.subscribe(article => {
      if (article) {
        if (
          !this.activeArticle ||
          (article.PVMVariant1 !== this.activeArticle.PVMVariant1 || article.PvmMasterItem !== this.activeArticle.PvmMasterItem)
        ) {

          this.activeArticle = article;
          this.getData(article);
        }
      }
    });
  }

  getData(article: Article) {
    if (this.article) {
      this.isDone = false;
      this.JvsArt.getArImg(article.ArtNr).subscribe(collection => {
        this.productImages = collection;
        this.setView(collection);
      });
    }
  }

  setView(collection: ProductImage[]) {

    if (collection === null) {
      this.view = { imageUrl: 'no-photo.jpg' };
      this.productImages = Array();

    } else {

      let match = false;

      collection.forEach(image => {
        if (image.pdmkat1kod === 1020 && !match) {
          this.view = image;
          match = true;
        }
      });
    }

    this.isDone = true;
  }

  openDialog(view: ProductImage): void {

    const images: Images = {
      images: [],
      active: { id: view.imageUrl, url: view.imageUrl, title: view.PVMVariantDescr + view.ArtBeskr }
    };

    this.productImages.forEach(image => {
      images.images.push({ id: image.imageUrl, url: image.imageUrl, title: image.PVMVariantDescr + view.ArtBeskr });
    });

    const dialogRef = this.dialog.open(ImageDialogComponent, {
      height: '85%',
      width: '85%',
      data: images
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }
}
