import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { DataService } from '@services/data.service';
import { Menu } from '@classes/menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  endpoint = '/jeeves/categories/1';

  menuList$: BehaviorSubject<Menu[]> = new BehaviorSubject(null);
  menuAppend$: BehaviorSubject<Menu[]> = new BehaviorSubject(Array());

  menuAppends: Menu[] = Array(
    {
      MenuDescr: 'customer'
      ,MenuCampain: null
      , children: Array(
        {
          MenuIsLink: 1
          , MenuLink: '/customer'
        }
      )
    },
    {
      MenuDescr: 'extenda'
      ,MenuCampain: null
      , children: Array(
        {
          MenuIsLink: 1
          , MenuLink: 'extenda'
        }
      )
    },
    {
      MenuDescr: 'admin'
      ,MenuCampain: null
      ,children: Array(
        {MenuDescr: 'Users', children: Array(
          {MenuDescr: 'User', MenuLink: 'admin'}
        )},
        {MenuDescr: 'Product', children: Array(
          {MenuDescr: 'Categories', MenuLink: 'admin/prodcat'},
          {MenuDescr: 'Types', MenuLink: 'admin/prodcat/types'}
        )},
        {MenuDescr: 'Content', children: Array(
          {MenuDescr: 'Manage', MenuLink: 'admin/content/manager/contentitems'}
        )}
      )
    },
  );


  constructor(private api: DataService) {
  }

  getMenuList() {
    this.getMenuListReq().subscribe((prodlist: Menu[]) => {
      if (prodlist) {
        this.menuAppend$.subscribe(appends => {
          if (appends) {
            prodlist = prodlist.concat(appends);
          }
        });
      }

      this.menuList$.next(prodlist);
    });
  }

  menuAppend(id: string) {
    const search = this.menuAppends.filter(m => m.MenuDescr === id);
    const menu = this.menuAppend$.getValue();

    if (search && !menu.filter(m => m.MenuDescr === id).length) {
      this.menuAppend$.next(
        menu.concat(search)
      );
    }
  }

  reset() {
    this.menuList$.next(null);
  }

  getMenuListReq() {
    const endpoint = this.endpoint;

    return this.api.getData(endpoint, []) as Observable<Menu[]>;
  }

}
