<a routerLink="/articles/{{product.PvmMasterItem}}" [queryParams]="{PVMVariant1: product.PVMVariant1}">
<mat-card class="product">

  <div *ngIf="isCampaign" style="position:absolute;right:5px;top:0;z-index:10;">
    <button mat-flat-button color="accent" style="height:15px;width:45px;font-size: 0.6em;line-height: 15px;">KAMPANJ!</button>
  </div>

  <mat-card-header>
    <mat-card-title>{{product.ArtBeskr}}</mat-card-title>
    <mat-card-subtitle *ngIf="settings.viewColor">{{product.PVMVariantDescr}}</mat-card-subtitle>
    <mat-card-subtitle>
      <div *ngIf="!isCampaign">
        <ng-container *ngIf="settings.viewPriceCustomer">
          {{product.Pris | currency:'SEK':'symbol-narrow'}}
        </ng-container>
        <ng-container *ngIf="settings.viewPriceCustomer && settings.viewPriceRetail">
            /
        </ng-container>
        <ng-container *ngIf="settings.viewPriceRetail">
          {{product.ArtCirkaPris | currency:'SEK':'symbol-narrow'}}
        </ng-container>
      </div>

      <div *ngIf="isCampaign">
        <div style="text-decoration: line-through;">
          <ng-container *ngIf="settings.viewPriceCustomer">
            {{product.Vb_Pris | currency:'SEK':'symbol-narrow'}}
          </ng-container>
          <ng-container *ngIf="settings.viewPriceCustomer && settings.viewPriceRetail">
              /
          </ng-container>
          <ng-container *ngIf="settings.viewPriceRetail">
            {{product.ArtCirkaPris | currency:'SEK':'symbol-narrow'}}
          </ng-container>
        </div>

        <div style="color: #ff4081">
          <ng-container *ngIf="settings.viewPriceCustomer">
            {{product.Pris | currency:'SEK':'symbol-narrow'}}
          </ng-container>
          <ng-container *ngIf="settings.viewPriceCustomer && settings.viewPriceRetail">
              /
          </ng-container>
          <ng-container *ngIf="settings.viewPriceRetail">
            {{product.ArtCirkaPrisPrl | currency:'SEK':'symbol-narrow'}}
          </ng-container>
        </div>
      </div>
    </mat-card-subtitle>

  </mat-card-header>
  
    <img mat-card-image src="{{env.arImg300 + product.imageUrl}}" alt="Product image">
  
</mat-card>
</a>