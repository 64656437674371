import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Article } from '@classes/article';
import { environment } from '@env/environment.prod';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from '@app/services/app.service';
import { ExportService } from '@app/services/export.service';
import { WorkSheet, WorkBook } from 'xlsx';
import { ExportItem } from '@app/classes/export';

@Component({
  selector: 'app-export-table',
  templateUrl: './export-table.component.html',
  styleUrls: ['./export-table.component.css']
})
export class ExportTableComponent implements OnInit {
  @Input() articles: Article[];
  @Input() listAddEnable = true;
  @ViewChild('TABLE', { static: true }) table: ElementRef;

  env = environment;
  localStorage = 'artExport';
  excelFileName = 'Bagheera_ArticleCodes_';

  filteredArticles: Article[] = Array();
  tableSource: MatTableDataSource<Article> = new MatTableDataSource(Array());

  tableColumns: string[] = Array(
    'ArtNr', 'PvmMasterItem', 'ArtNrEAN', 'ArtStatNr', 'ArtKat', 'VaruGruppKod',
    'ArtBeskr', 'Extra1', 'ArtProdKonto', 'ArtCirkaPris', 'PVMVariant1',
    'PVMVariant2', 'PVMVariantDescr', 'PVMVariantDescr2', 'Edit', 'ArtDisp', 'imageUrl',
    'AssrtContent', 'AssrtQty', 'Pris'
  );

  // Removed unessesary props
  /*
    'isDisp', 'Vb_Pris', 'Rabatt', 'ArtListPris'
  */

  tableColumnsDefault: string[] = Array(
    'ArtNrEAN', 'ArtNr', 'PVMVariant1', 'PVMVariant2', 'ArtBeskr', 'PVMVariantDescr',
    'PVMVariantDescr2', 'ArtCirkaPris', 'AssrtQty', 'AssrtContent'
  );

  tableColumnsDisplay: string[] = null;
  tableColumnsStorage: string[] = null;

  colEdit = false;

  constructor(private app: AppService, private exportService: ExportService) { }

  ngOnInit() {
    this.tableColumnsStorage = this.app.view.getValue('artExport', null).value;
    this.initTable(this.articles);
    this.setExcelFileName(this.articles);
  }

  addExportList() {
    this.exportService.add(new ExportItem('articleExport', [... this.articles]));
  }

  colOrder(columnList: string[], index, direction) {
    const colToMove = columnList[index];
    const colToPush = columnList[index + direction];
    columnList[index] = colToPush;
    columnList[index + direction] = colToMove;
  }

  setExcelFileName(articles: Article[]) {
    if (articles.length > 0 && this.listAddEnable) {
      this.excelFileName += articles[0].PvmMasterItem || articles[0].ArtNr;
    } else {
      const currentDateTime = new Date();

      const yyyy = currentDateTime.getFullYear().toString().substring(2, 4);
      const mm = (currentDateTime.getMonth() + 1) < 10 ?
      '0' + (currentDateTime.getMonth() + 1).toString() : (currentDateTime.getMonth() + 1);

      const dd = (currentDateTime.getDate() < 10) ? '0' + currentDateTime.getDate() : currentDateTime.getDate();

      this.excelFileName += yyyy + mm + dd;
    }
  }

  tableColHidden(activeCols: string[]) {
    const notActive = this.tableColumns.filter(col => col !== activeCols.find(ac => col === ac));

    if (notActive.length > 0) {
      return notActive;
    } else {
      return Array();
    }
  }

  exportAsExcel() {
    import('xlsx').then(XLSX => {
      // Reduce bundle size

      const ws: WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
      const wb: WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'ArticleCodes');

      /* save to file */
      XLSX.writeFile(wb, this.excelFileName + '.xlsx');
    });
  }

  checkColumns(reqColumns: string[]) {
    const valid = reqColumns.map(col =>
      this.tableColumns.find(avalible => avalible === col)
    );

    return valid;
  }

  getDispStorage() {
    let lsDisplay = this.app.view.getValue(this.localStorage, null).value;

    if (!lsDisplay) {
      return null;
    }

    lsDisplay = this.checkColumns(lsDisplay);

    return lsDisplay;
  }

  setDisplayColumns(columns: string[] = null) {
    if (!columns) {
      columns = this.getDispStorage() || this.tableColumnsDefault;
    } else {
      columns = this.checkColumns(columns) || this.tableColumnsDefault;
    }

    return columns;
  }

  removeColumn(column: string) {
    const newCols = this.tableColumnsDisplay.filter(col => col !== column);
    this.tableColumnsDisplay = newCols;

    this.app.view.setValue(this.localStorage, this.tableColumnsDisplay, true);
  }

  appendColumn(column: string) {
    if ( this.checkColumns(Array(column)) && typeof this.tableColumnsDisplay.find(col => column === col) === 'undefined' ) {
      this.tableColumnsDisplay.push(column);
      this.app.view.setValue(this.localStorage, this.tableColumnsDisplay, true);
    }
  }

  colDisplayDefault() {
    this.tableColumnsDisplay = this.tableColumnsDefault;
    this.app.view.setValue(this.localStorage, this.tableColumnsDisplay, true);
  }

  initTable(articles: Article[]) {
    this.tableColumnsDisplay = this.setDisplayColumns();
    this.tableSource.data = articles;
  }
}
