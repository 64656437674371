import { Component, OnInit, Input } from '@angular/core';
import { Article } from '@classes/article';
import { CartItem } from '@classes/cart';
import { CartService } from '@services/cart.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-article-matrix-xs',
  templateUrl: './article-matrix-xs.component.html',
  styleUrls: ['./article-matrix-xs.component.css']
})
export class ArticleMatrixXsComponent implements OnInit {
  @Input() matrix: {colors: Article[], sizes: Article[]};
  @Input() state: BehaviorSubject<Article>;

  public view: Article = null;
  public activeColor: Article = null;
  public order = {};

  constructor(private cart: CartService) { }

  ngOnInit() {

    this.state.subscribe(article => {
      if (article) {
        this.activeColor = article;
      }
    });
  }

  submitToCart() {
    const items: CartItem[] = [];
    let quantity = 0;

    for (const item in this.order) {
      if (this.order[item]) {
        items.push({itemnb: item, itemqty: this.order[item], itemvat: 0, itemmeta: { }});
        quantity += Number(this.order[item]);
        this.order[item] = null;
      }
    }

    this.cart.addItem(items);
  }
}
