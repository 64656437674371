import { Component, OnInit, Input } from '@angular/core';
import { Article } from '@classes/article';
import { Router } from '@angular/router';

import { environment } from '@env/environment';

@Component({
  selector: 'app-article-card-std',
  templateUrl: './article-card-std.component.html',
  styleUrls: ['./article-card-std.component.css']
})
export class ArticleCardStdComponent implements OnInit {

  @Input() product: Article;
  @Input() settings: {viewColor: boolean, viewPriceCustomer: boolean, viewPriceRetail: boolean} = {
    viewColor: true, viewPriceCustomer: true, viewPriceRetail: true
  };

  public env = environment;
  isCampaign = false;

  constructor(private router: Router) {
  }

  ngOnInit() {
    if (this.product.Pris < this.product.Vb_Pris && this.product.ArtCirkaPrisPrl < this.product.ArtCirkaPris) {
      this.isCampaign = true;
    }
  }

  routeArticle(article: string) {
    this.router.navigate(['articles/', article]);
  }
}
