import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MessageService } from '@services/message.service';
import { DataService } from '@services/data.service';

import { App } from '@classes/app';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public view: App = new App();
  public appVersion = environment.appVersion;
  public appSettingsPersistent = Array('jwt_token');

  public isLoading$ = new BehaviorSubject<boolean>(false);
  public loadingTimer;
  public layoutOverflow$ = new BehaviorSubject<boolean>(true);

  constructor(private message: MessageService, private api: DataService) {

    this.versionCeck();

    this.api.requestError.subscribe(error => {
      if (error != null) {
        this.done();

        if (error.status !== 401) {
          this.error();
        }
      }
    });
  }


  versionCeck() {
    const session = sessionStorage.getItem('appVersion');
    const local = localStorage.getItem('appVersion');

    if (session !== this.appVersion) {
      const persist = Array();

      this.appSettingsPersistent.forEach(setting => {
        if (sessionStorage.getItem(setting)) {
          persist.push({name: setting, data: sessionStorage.getItem(setting)});
        }

        sessionStorage.clear();
        sessionStorage.setItem('appVersion', this.appVersion);
        persist.forEach(persistObj => {
          sessionStorage.setItem(persistObj.name, persistObj.data);
        });
      });
    }

    if (local !== this.appVersion) {
      const persist = Array();

      this.appSettingsPersistent.forEach(setting => {
        if (localStorage.getItem(setting)) {
          persist.push({name: setting, data: localStorage.getItem(setting)});
        }

        localStorage.clear();
        localStorage.setItem('appVersion', this.appVersion);
        persist.forEach(persistObj => {
          localStorage.setItem(persistObj.name, persistObj.data);
        });
      });
    }
  }



  loading(state: boolean) {
    this.isLoading$.next(state);
  }

  start() {
    this.isLoading$.next(true);
  }

  done() {
    this.isLoading$.next(false);
  }

  error() {
    this.message.send({
      type: 'snackbar'
      , message: 'Somthing went wrong'
      , source: 'Timeout'
      , duration: 3000
    });
  }

}
