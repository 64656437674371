import { Component, Input, OnInit } from '@angular/core';
import { ApiKey, User } from '@app/classes/user';
import { MessageService } from '@app/services/message.service';
import { UserService } from '@app/services/user.service';

@Component({
  selector: 'app-admin-apikey',
  templateUrl: './admin-apikey.component.html',
  styleUrls: ['./admin-apikey.component.css']
})
export class AdminApikeyComponent implements OnInit {
  @Input() user: User;
  apiKey: ApiKey = {value:''};

  constructor(private userSrv: UserService, private msgService: MessageService) { }

  ngOnInit(): void {
    this.userSrv.getApiKey(this.user.userid).subscribe(apiKey => {
      if(apiKey) {
        this.apiKey = apiKey;
      }
    });
  }

  generateApiKey() {
    this.apiKey.value = '';
    this.userSrv.newApiKey(this.user.userid).subscribe(apiKey => {
      this.apiKey = apiKey;

      this.msgService.send({
        type: 'snackbar'
        , message: 'API Key generated'
        , source: 'User service'
        , duration: 2000
      });
    })
  }
}
