import { Component, OnInit, ViewChild } from '@angular/core';
import { Invoice, InvoiceRows } from '@classes/invoices';
import { DataService } from '@services/data.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-invoicetable',
  templateUrl: './invoicetable.component.html',
  styleUrls: ['./invoicetable.component.css']
})
export class InvoicetableComponent implements OnInit {

  public response: Invoice[] = [];
  public displayedColumns: string[] = [
    'FaktNr', 'OrderNr', 'OrdTypBeskr', 'FaktDat', 'FaktFfDat', 'FaktTotMMoms', 'FaktStBeskr', 'VbFaktRest'];
  public dataSource: MatTableDataSource<Invoice>;
  public viewSource: Invoice[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private api: DataService) { }

  loadData() {
    const endpoint = '/jeeves/invoices/active';
    const headers: any = [];

    this.api.getData(endpoint, headers).subscribe(data => {
      if (data) {
        this.processResponse(data);
      } else {
        this.processResponse();
      }
    });
  }

  processResponse(data: Invoice[] = Array()) {
    this.dataSource = new MatTableDataSource<Invoice>(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.connect().subscribe(res => this.viewSource = res);
  }

  ngOnInit() {
    this.loadData();
  }
}
