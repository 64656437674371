<div fxLayout="column nowrap" fxLayoutAlign="flex-start stretch" fxHide.lt-md>
    <h3>Price Visibility</h3>
    <mat-checkbox [(ngModel)]="checkboxes.viewPriceRetail" (change)="publish('viewPriceRetail', checkboxes.viewPriceRetail)">Retail</mat-checkbox>
    <mat-checkbox [(ngModel)]="checkboxes.viewPriceCustomer" (change)="publish('viewPriceCustomer', checkboxes.viewPriceCustomer)">Customer</mat-checkbox>
</div>

<mat-accordion fxHide fxShow.lt-md>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
        SETTINGS
        </mat-expansion-panel-header>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
            Price Visibility
            </mat-expansion-panel-header>
            <mat-list>
                <mat-list-item>
                    <mat-checkbox [(ngModel)]="checkboxes.viewPriceRetail" (change)="publish('viewPriceRetail', checkboxes.viewPriceRetail)">Retail</mat-checkbox>
                </mat-list-item>
                <mat-list-item>
                    <mat-checkbox [(ngModel)]="checkboxes.viewPriceCustomer" (change)="publish('viewPriceCustomer', checkboxes.viewPriceCustomer)">Customer</mat-checkbox>
                </mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </mat-expansion-panel>
</mat-accordion>