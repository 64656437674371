<mat-card fxLayout="row wrap">
  <div class="row">
    <h3>Change password</h3>
  </div>
  <form [formGroup]="passwordForm" (ngSubmit)="submit()" fxLayout="row wrap">
    <div class="row">
      <mat-form-field class="full">
        <input matInput type="password" placeholder="Old password" formControlName="oldpassword">
        <mat-error *ngIf="passwordForm.get('oldpassword').hasError('minlength') && !passwordForm.get('oldpassword').hasError('required')">
          Minimum 8 characters
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="full">
        <input matInput type="password" placeholder="New password" formControlName="password">
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="full">
        <input matInput type="password" placeholder="Confirm new password" formControlName="confirm">
      </mat-form-field>
    </div>

    <div class="error-container" fxFlex="100%" *ngIf="passwordForm.get('password').value">
      <mat-error *ngIf="passwordForm.get('password').getError('minlength')">
        Password must be at least 8 characters long
      </mat-error>
      <mat-error *ngIf="passwordForm.get('password').getError('upperCase')">
        Password must contain at least 1 large character
      </mat-error>
      <mat-error *ngIf="passwordForm.get('password').getError('lowerCase')">
        Password must contain at least 1 small character
      </mat-error>
      <mat-error *ngIf="passwordForm.get('password').getError('number')">
        Password must contain at least 1 number
      </mat-error>
      <mat-error *ngIf="passwordForm.get('password').getError('special')">
        Password must contain at least 1 special character
      </mat-error>
      <mat-error *ngIf="passwordForm.get('password').getError('blank')">
        Password cannot contain any spaces
      </mat-error>
      <mat-error *ngIf="!passwordMatch">
        Passwords dont match
      </mat-error>
    </div>
    

    <div class="row">
      <button type="submit" mat-raised-button color="primary" [disabled]="!passwordForm.valid">Save</button>
    </div>
  </form>
  
</mat-card>
      