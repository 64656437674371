<div *ngIf="user" fxLayout="row wrap">
    <div *ngIf="customer"  fxFlex="100%" class="row">

        <mat-form-field class="company-nb" floatLabel="always">
            <input matInput placeholder="Customer nb" [value]="customer.FtgNr" disabled>
        </mat-form-field>

        <mat-form-field class="company-name" floatLabel="always">
            <input matInput placeholder="Name" [value]="customer.ftgnamn" disabled>
        </mat-form-field>

    </div>

    <div fxFlex="100%" class="row">
        <button *ngIf="customer" mat-raised-button color="accent" (click)="clearCustomer()">Remove Customer</button>
        <button mat-raised-button color="primary" (click)="outputCustomerSelect.emit($event)">Select Customer</button>
    </div>

    <div *ngIf="contact" fxFlex="100%" class="row">

        <mat-form-field class="company-nb" floatLabel="always">
            <input matInput placeholder="Contact nb" [value]="contact.FtgKontaktNr" disabled>
        </mat-form-field>

        <mat-form-field class="company-name" floatLabel="always">
            <input matInput placeholder="Name" [value]="contact.FtgPerson" disabled>
        </mat-form-field>

    </div>

    <div *ngIf="customer" fxFlex="100%" class="row">

        <button *ngIf="contact" mat-raised-button color="accent" (click)="clearContact()">Remove Contact</button>
        <button mat-raised-button color="primary" (click)="outputContactSelect.emit($event)">Select contact</button>

    </div>
</div>