import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { environment } from '@env/environment';
import { Invoice, InvoiceRows } from '@classes/invoices';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-invoice-rows',
  templateUrl: './invoice-rows.component.html',
  styleUrls: ['./invoice-rows.component.css']
})
export class InvoiceRowsComponent implements OnInit {

  @Input() invoicenb: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public env = environment;
  public response: Invoice;
  public invoice: Invoice;

  // Invoice table variables
  public dataSource: MatTableDataSource<InvoiceRows>;
  public displayedColumns: string[] = [
    'OrdRadnr', 'OrdLevDat', 'PvmMasterItem'
    , 'ArtBeskr', 'PVMVariantDescr', 'PVMVariantDescr2',
     'FaktLevAnt', 'vb_pris', 'FaktRadRab', 'vb_FaktRadSumma'];



  constructor(private api: DataService) { }

  ngOnInit() {
    this.getInvoice(this.invoicenb);
  }

  getInvoice(invoicenb: number): void {
    const endpoint: string = '/jeeves/invoice/' + invoicenb;
    const headers: any = [];

    this.api.getData(endpoint, headers).subscribe(data => {
      this.response = data as Invoice;
      this.processResponse();
    });
  }

  processResponse(): void {
    this.dataSource = new MatTableDataSource<InvoiceRows>(this.response.InvoiceRows);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.invoice = this.response;

  }

}
