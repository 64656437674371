import { AbstractControl, ValidatorFn } from '@angular/forms';

export class CustomValidators {

    regTest(nameRe: RegExp, match: boolean, prop = 'regTest'): ValidatorFn {

        return (control: AbstractControl): {[key: string]: any} | null => {
            const valid = nameRe.test(control.value);
            const error = new Object();

            error[prop] = new Object();
            error[prop][prop] = control.value;

            if (match) {
                return valid ? null : error;
            } else {
                return !valid ? null : error;
            }
        };
    }

    dateMin(datePick?: Date): ValidatorFn {

        return (control: AbstractControl): {[key: string]: any} | null => {

            if (typeof datePick === 'undefined' || !datePick) {
                return null;
            }

            let valid = false;

            // Hack to avoid hour issues. Needs fixing if validator will be used for hour ranges.
            if (control.value && (control.value.getTime() + 7200000) >= datePick.getTime()) {
                valid = true;
            }

            const error = {
                dateMin: {dateMin: control.value},
            };

            return valid ? null : error;
        };
    }

    dateMax(datePick?: Date): ValidatorFn {

        return (control: AbstractControl): {[key: string]: any} | null => {

            if (typeof datePick === 'undefined' || !datePick) {
                return null;
            }

            let valid = false;

            if (control.value && control.value.getTime() <= datePick.getTime()) {
                valid = true;
            }

            const error = {
                dateMax: {dateMax: control.value},
            };

            return valid ? null : error;
        };
    }
}
