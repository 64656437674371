import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { Customer } from '@classes/customer';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-customer-table',
  templateUrl: './customer-table.component.html',
  styleUrls: ['./customer-table.component.css']
})
export class CustomerTableComponent implements OnInit {
  @Input() input: BehaviorSubject<Customer[]>;
  @Output() output: EventEmitter<Customer> = new EventEmitter<Customer>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public tableSource: MatTableDataSource<Customer>;
  public displayedColumns: string[] = ['FtgNr', 'ftgnamn', 'select'];

  constructor(private dialog: MatDialog ) { }

  ngOnInit() {
    this.input.subscribe(res => {
      this.tableSource = new MatTableDataSource<Customer>(res);
      this.tableSource.paginator = this.paginator;
      this.tableSource.sort = this.sort;
    });
   }

   selectCustomer(customer: Customer) {
    this.output.emit(customer);
   }

}
