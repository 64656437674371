<mat-card *ngIf="filterCount" style="margin:10px;">
    <mat-card-header>
        <mat-card-subtitle>
            Active Filters
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content style="padding-left:20px;" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
        <mat-chip-list>          
            <ng-container *ngFor="let filter of filters">
                <ng-container *ngFor="let option of filter.children">
                    <mat-chip class="filter-chip" color="accent" selected *ngIf="option.State" (click)="option.State = false; disable(filter, option)">{{option.ProdTypVrtDescr}}</mat-chip>
                    <!-- <span class="filter-names" *ngIf="option.State" (click)="option.State = false; disable(filter, option)">{{option.ProdTypVrtDescr}}</span> -->
                </ng-container>
            </ng-container>
        </mat-chip-list>
    </mat-card-content>
    <mat-card-actions style="text-align: right;">
        <button mat-raised-button color="primary" (click)="reset()">Reset</button>
    </mat-card-actions>
</mat-card>