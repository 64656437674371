import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactSearchDialogComponent } from '@app/components/dialog/contact-search-dialog/contact-search-dialog.component';
import { CustomerSearchDialogComponent } from '@app/components/dialog/customer-search-dialog/customer-search-dialog.component';
import { Contact, Customer } from '@classes/customer';
import { User } from '@classes/user';

@Component({
  selector: 'app-admin-user-erp',
  templateUrl: './admin-user-erp.component.html',
  styleUrls: ['./admin-user-erp.component.css']
})
export class AdminUserErpComponent implements OnInit {
  @Input() customer: Customer;
  @Input() contact: Contact;
  @Input() user: User;

  @Output() outputUser: EventEmitter<User> = new EventEmitter()
  @Output() outputCustomerSelect = new EventEmitter()
  @Output() outputContactSelect = new EventEmitter()
  
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  clearContact() {
    this.user.jvscontact = null;
    this.contact = null;
    this.outputUser.emit(this.user);
  }

  clearCustomer() {
    this.user.jvscompany = null;
    this.user.jvscontact = null;

    this.customer = null;
    this.contact = null;
    this.outputUser.emit(this.user);
  }
}
