<div *ngIf="compState" class="container" fxLayout="row nowrap" fxLayoutAlign="center flex-start">
  <div fxFlex="40%" fxLayout="row wrap" class="content">

    <div fxFlex="100%" class="brand">
      <img src="assets/img/logo_xsmall_inv.png" alt="Brand Logo">
    </div>

    <div fxFlex="100%" class="header">
      <h3>Your mail address has now been verified!</h3>
    </div>

    <div fxFlex="100%" class="info">
      <p>We will process your application as soon as possible and send you a confirmation mail once your account has been activated.</p>
      <p>Please contact us by mail or phone if you have any questions.</p>
      <p>Best Regards<br>Bagheera AB</p>
    </div>

  </div>
</div>

<div *ngIf="!compState" class="container" fxLayout="row nowrap" fxLayoutAlign="center flex-start">
  <mat-spinner></mat-spinner>
</div>
