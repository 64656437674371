  <form>
    <input type="text" placeholder="Search..." aria-label="Number" [formControl]="searchTerm" [matAutocomplete]="auto" autofocus>
    <div ngClass.gt-sm="input-controls" ngClass.lt-md="input-controls-drawer" fxLayout="row nowrap" fxLayoutAlign="center center">
      <i *ngIf="searchTerm.value && !searching" class="fas fa-times " (click)="reset(); auto.panelOpen=false"></i>
      <ng-container *ngIf="searching">
        <mat-progress-spinner
          mode="indeterminate"
          color="primary"
          diameter="16"
        ></mat-progress-spinner>
      </ng-container>
    </div>

    <mat-autocomplete #auto="matAutocomplete" (closed)="showSearch=false; isOpenAuto=false" (opened)="isOpenAuto=true">

      <mat-option *ngFor="let option of results" [value]="option.ArtNr" (click)="route(option.ArtNr)">

        <div *ngIf="option.ArtBeskr" fxLayout="row nowrap" fxLayoutAlign="flex-start center" class="search-row">

          <img src="{{env.arImg300 + option.imageUrl}}" alt="Product Image">
          <span>{{option.ArtNr}}</span>
          <span fxHide.lt-sm>{{option.VaruGruppBeskr}}</span>
          <span>{{option.ArtBeskr}}</span>
          <span fxHide.lt-sm>{{option.PVMVariantDescr}}</span>
          
        </div>

        <div *ngIf="!option.ArtBeskr">0 results..</div>

      </mat-option>
    </mat-autocomplete>
  </form>