<div fxLayout="column nowrap" fxLayoutAlign="flex-start center" class="container">

  <div class="brand">
    <img src="assets/img/logo_xsmall_inv.png" alt="Brand Logo">
  </div>
  
  <div class="controls">
      <mat-tab-group>

        <mat-tab label="Login">
            <app-login></app-login>
        </mat-tab>

        
        <mat-tab label="Register">
          <app-login-reg></app-login-reg>
        </mat-tab>


        <mat-tab label="Lost password">
          <app-login-recover></app-login-recover>
        </mat-tab>

      </mat-tab-group>
  </div>
</div>
