import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MenuItems } from '@classes/menu';

@Component({
  selector: 'app-menu-submenu',
  templateUrl: './menu-submenu.component.html',
  styleUrls: ['./menu-submenu.component.css']
})
export class MenuSubmenuComponent implements OnInit {

  @Input() menuItems$: BehaviorSubject<MenuItems[]>;
  @Output() output: EventEmitter<MenuItems> = new EventEmitter();

  menuItems: MenuItems[];
  menuOpen = false;

  constructor() { }

  ngOnInit() {
    this.menuItems$.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.menuOpen = true;
    });
  }

  clickLink(item: MenuItems, link: MenuItems = null) {
    const data: MenuItems = {... item};
    data.children = link ? Array(link) : Array(null);
    this.menuOpen = false;

    this.output.emit(data);
  }

  getCatCols(values: any[], count: number): number[] {
    const result: number[] = [];
    const cols = Math.ceil(values.length / count);

    for (let i = 1; i <= cols; i++) {
      result.push(i * count);
    }

    return result;
  }


  test2(PriAttr: number, PriVrt: number, level2Attr: number, level2Vrt: number, level3Attr: number, level3Vrt: number) {
    console.log('Link clicked', PriAttr, PriVrt, level2Attr, level2Vrt, level3Attr, level3Vrt);

    // Design ProductList service which uses above parameters as limiters
    // Look into a breadcrum design with parameters.
  }
}
