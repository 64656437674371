import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  group(list: any[], property: string) {
    const map = new Map();

    list.forEach((item) => {
        const key = item[property];
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
  }

  sortByKey(list: Map<any, any>) {
    const map = new Map(Array.from(list.entries()).sort());

    return map;
  }
}

