import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeSv from '@angular/common/locales/sv';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalModule } from '@modules/global/global.module';
import { GlobalArticleModule } from '@modules/global/global-article.module';
import { GlobalNgmaterialModule } from '@modules/global/global-ngmaterial.module';
import { AppRoutingModule } from '@app/app-routing.module';

import { DataService } from '@services/data.service';
import { AuthService } from '@services/auth.service';
import { JwtService } from '@services/jwt.service';
import { ContentService } from '@modules/content-core/services/content.service';
import { CartService } from '@services/cart.service';
import { UserService } from '@services/user.service';
import { JvsorderService } from '@services/jvsorder.service';
import { JwtInterceptor } from '@interceptors/jwt.interceptor';
import { ResponseInterceptor } from '@interceptors/res.interceptor';
import { AuthGuardService } from '@services/auth-guard.service';
import { MessageService } from '@services/message.service';
import { UtilityService } from '@services/utility.service';
import { JvsarticleService } from '@services/jvsarticle.service';

import { AppComponent } from '@app/app.component';
import { DiagComponent } from '@components/diag/diag.component';
import { CartmainComponent } from '@components/cart/cartmain/cartmain.component';
import { ProgressbarComponent } from '@components/misc/progressbar/progressbar.component';
import { CustomerRouteComponent } from '@components/customer/customer-route/customer-route.component';
import { OrdersopenComponent } from '@components/orders/ordersopen/ordersopen.component';
import { InvoicetableComponent } from '@components/invoices/invoicetable/invoicetable.component';
import { CustomerDetailsComponent } from '@components/customer/customer-details/customer-details.component';
import { ContactListComponent } from '@components/customer/contact-list/contact-list.component';
import { ContactAddComponent } from '@components/customer/contact-add/contact-add.component';
import { OrderComponent } from '@components/orders/order/order.component';
import { OrderrowsComponent } from '@components/orders/orderrows/orderrows.component';
import { OrderheadComponent } from '@components/orders/orderhead/orderhead.component';
import { CarticonComponent } from '@components/cart/carticon/carticon.component';
import { CartitemTableComponent } from '@components/cart/cartitem-table/cartitem-table.component';
import { CartheadTableComponent } from '@components/cart/carthead-table/carthead-table.component';
import { ImageDialogComponent } from '@components/dialog/image-dialog/image-dialog.component';
import { LoginRouteComponent } from '@components/login/login-route/login-route.component';
import { SnackbarNotifyComponent } from '@components/dialog/snackbar-notify/snackbar-notify.component';
import { SearchComponent } from '@components/misc/search/search.component';
import { InvoiceRowsComponent } from '@components/invoices/invoice-rows/invoice-rows.component';
import { InvoiceHeadComponent } from '@components/invoices/invoice-head/invoice-head.component';
import { InvoiceComponent } from '@components/invoices/invoice/invoice.component';
import { LoginRegComponent } from '@components/login/login-reg/login-reg.component';
import { LoginComponent } from '@components/login/login/login.component';
import { LoginRecoverComponent } from '@components/login/login-recover/login-recover.component';
import { AdminUserComponent } from '@components/admin/admin-user/admin-user.component';
import { AdminRouteComponent } from '@components/admin/admin-route/admin-route.component';
import { AdminRegistrationComponent } from '@components/admin/admin-registration/admin-registration.component';
import { CustomerSearchComponent } from '@components/customer/customer-search/customer-search.component';
import { CustomerSearchDialogComponent } from '@components/dialog/customer-search-dialog/customer-search-dialog.component';
import { CustomerTableComponent } from '@components/customer/customer-table/customer-table.component';
import { ContactSearchDialogComponent } from '@components/dialog/contact-search-dialog/contact-search-dialog.component';
import { ContactTableComponent } from '@components/customer/contact-table/contact-table.component';
import { ContactSearchComponent } from '@components/customer/contact-search/contact-search.component';
import { ContactTableCrudComponent } from '@components/customer/contact-table-crud/contact-table-crud.component';
import { UserDropdownComponent } from '@components/user/user-dropdown/user-dropdown.component';
import { HomeRouteComponent } from '@components/site/home-route/home-route.component';
import { UserRouteComponent } from '@components/user/user-route/user-route.component';
import { UserDetailsComponent } from '@components/user/user-details/user-details.component';
import { UserPswComponent } from '@components/user/user-psw/user-psw.component';
import { UserCtrlComponent } from '@components/user/user-ctrl/user-ctrl.component';
import { UserVerifyComponent } from '@components/user/user-verify/user-verify.component';
import { SiteRouteComponent } from '@components/site/site-route/site-route.component';
import { TermsComponent } from '@components/orders/terms/terms.component';
import { CartDoneComponent } from '@components/cart/cart-done/cart-done.component';
import { CartlistTableComponent } from '@components/cart/cartlist-table/cartlist-table.component';
import { CartoperationsComponent } from '@components/cart/cartoperations/cartoperations.component';
import { CartitemEmptyComponent } from '@components/cart/cartitem-empty/cartitem-empty.component';
import { ConfirmDialogComponent } from '@components/dialog/confirm-dialog/confirm-dialog.component';
import { MenuListComponent } from '@components/menu/menu-list/menu-list.component';
import { MenuSubmenuComponent } from '@components/menu/menu-submenu/menu-submenu.component';
import { MenuRouteComponent } from '@components/menu/menu-route/menu-route.component';
import { MenuDrawerComponent } from '@components/menu/menu-drawer/menu-drawer.component';
import { ArticleRouteComponent } from '@components/article/article-route/article-route.component';
import { ArticlesRouteComponent } from '@components/articles/articles-route/articles-route.component';
import { ArticlesFiltersComponent } from '@components/articles/articles-filters/articles-filters.component';
import { CartitemFooterComponent } from '@components/cart/cartitem-footer/cartitem-footer.component';
import { UserRecoverComponent } from '@components/user/user-recover/user-recover.component';
import { ReportDlComponent } from '@components/misc/report-dl/report-dl.component';
import { UserMobileComponent } from '@components/user/user-mobile/user-mobile.component';
import { AdminValidationComponent } from '@components/admin/admin-validation/admin-validation.component';
import { PacsofttrackComponent } from '@components/misc/pacsofttrack/pacsofttrack.component';
import { DefaultDialogComponent } from '@components/dialog/default-dialog/default-dialog.component';
import { SettingsDdiconComponent } from '@components/settings/settings-ddicon/settings-ddicon.component';
import { SettingsListComponent } from '@components/settings/settings-list/settings-list.component';
import { ArticlesFiltersActiveComponent } from '@components/articles/articles-filters-active/articles-filters-active.component';
import { ArticleExportComponent } from '@components/article/article-export/article-export.component';
import { ArticleExportIconComponent } from '@components/article/article-export-icon/article-export-icon.component';
import { ExportRouteComponent } from '@components/export/export-route/export-route.component';
import { ExportTableComponent } from '@components/export/export-table/export-table.component';
import { PorderRdlistComponent } from '@components/porder/porder-rdlist/porder-rdlist.component';
import { OrderDeliveryComponent } from '@components/orders/order-delivery/order-delivery.component';
import { DatepickerComponent } from '@components/misc/datepicker/datepicker.component';
import { ContentCoreModule } from '@modules/content-core/content-core.module';
import { AdminApikeyComponent } from '@components/admin/admin-apikey/admin-apikey.component';
import { AdminUserDataComponent } from '@components/admin/admin-user-data/admin-user-data.component';
import { AdminUserErpComponent } from '@components/admin/admin-user-erp/admin-user-erp.component';



// Registers non-default locale
registerLocaleData(localeSv);

@NgModule({
  declarations: [
    AppComponent,
    DiagComponent,
    CartmainComponent,
    ProgressbarComponent,
    CustomerRouteComponent,
    OrdersopenComponent,
    InvoicetableComponent,
    CustomerDetailsComponent,
    ContactListComponent,
    ContactAddComponent,
    OrderComponent,
    OrderrowsComponent,
    OrderheadComponent,
    CarticonComponent,
    CartitemTableComponent,
    CartheadTableComponent,
    ArticleRouteComponent,
    ImageDialogComponent,
    LoginRouteComponent,
    SnackbarNotifyComponent,
    SearchComponent,
    InvoiceRowsComponent,
    InvoiceHeadComponent,
    InvoiceComponent,
    LoginRegComponent,
    LoginComponent,
    LoginRecoverComponent,
    AdminUserComponent,
    AdminRouteComponent,
    AdminRegistrationComponent,
    CustomerSearchComponent,
    CustomerSearchDialogComponent,
    CustomerTableComponent,
    ContactSearchDialogComponent,
    ContactTableComponent,
    ContactSearchComponent,
    ContactTableCrudComponent,
    UserDropdownComponent,
    HomeRouteComponent,
    UserRouteComponent,
    UserDetailsComponent,
    UserPswComponent,
    UserCtrlComponent,
    UserVerifyComponent,
    SiteRouteComponent,
    TermsComponent,
    CartDoneComponent,
    CartlistTableComponent,
    CartoperationsComponent,
    CartitemEmptyComponent,
    ConfirmDialogComponent,
    MenuListComponent,
    MenuSubmenuComponent,
    MenuRouteComponent,
    MenuDrawerComponent,
    ArticlesRouteComponent,
    ArticlesFiltersComponent,
    CartitemFooterComponent,
    UserRecoverComponent,
    ReportDlComponent,
    UserMobileComponent,
    AdminValidationComponent,
    PacsofttrackComponent,
    DefaultDialogComponent,
    SettingsDdiconComponent,
    SettingsListComponent,
    ArticlesFiltersActiveComponent,
    ArticleExportComponent,
    ArticleExportIconComponent,
    ExportRouteComponent,
    ExportTableComponent,
    PorderRdlistComponent,
    OrderDeliveryComponent,
    DatepickerComponent,
    AdminApikeyComponent,
    AdminUserDataComponent,
    AdminUserErpComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    GlobalModule,
    GlobalNgmaterialModule,
    GlobalArticleModule,
    AppRoutingModule,
    ContentCoreModule,
  ],
  providers: [
    JwtService,
    AuthService,
    AuthGuardService,
    DataService,
    ContentService,
    CartService,
    JvsorderService,
    UserService,
    MessageService,
    UtilityService,
    JvsarticleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'sv-SE' // 'de-DE' for Germany, 'fr-FR' for France ...
    }
  ],
  entryComponents: [
    ImageDialogComponent,
    DefaultDialogComponent,
    CustomerSearchDialogComponent,
    ContactSearchDialogComponent,
    TermsComponent
  ]
  , bootstrap: [AppComponent]
})
export class AppModule { }
